// _block_publications.twig
.container-publications {
	@include gradient;
	border-radius: $default-radius;

	padding-top: 90px;
	padding-bottom: 120px;

	margin-bottom: 120px;

	header {
		text-align: center;
		margin-bottom: 40px;
		max-width: 620px;
		margin-right: auto;
		margin-left: auto;
		@include respond-above(lg) {
			margin-bottom: 80px;
		}
		h3.h1 {
			font-size: 38px;
			line-height: 48px;
			padding-bottom: 15px;
			margin-bottom: 15px;
			border-bottom: 1px dashed $blue;
			@include respond-above(md) {
				padding-bottom: 10px;
				font-size: 60px;
				line-height: 66px;
			}
		}
		p {
			margin-bottom: 0;
		}
	}

	section.container-publications__list {
		@include flexbox();
		@include flex-direction(column);
		width: 100%;
		h6 {
			border-top: 1px solid $black;
			padding-top: 40px;
			padding-bottom: 22px;
			margin-top: 40px;
			@include respond-above(sm) {
				padding-top: 9px;
				padding-bottom: 0;
				font-weight: 500;
				border-top: none;
				margin-top: 0;
			}
		}
		ul {
			list-style: none;
			padding: 0;
			a {
				margin: 10px 0;
				padding: 10px 0;
				@include respond-above(lg) {
					margin: 0;
					padding: 0;
				}
			}
			@include respond-below(sm) {
				margin-left: 30px;
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}
	}
}

#mvb {
	.container-publications {
		@include gradient_two;

		.container {
			max-width: 940px;
		}
		section.container-publications__list {
			h6.h7 {
				border-top: none !important;
				padding: 0px !important;
			}
		}
	}
}
