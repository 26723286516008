.text_block {
    padding: 160px 20px;
    position: relative;
    @include respond-above(md) {
        padding: 160px 30px;
    }

    &--first {
        @include respond-above(md) {
            padding-bottom: 120px;
        }
    }
    &--fourth {
        padding-bottom: 280px;
        @include respond-above(xl) {
            padding-bottom: 230px;
        }
    }

    &--after_grid {
        padding-top: 0;
        @include respond-above(md) {
            padding-bottom: 247px;
        }
    }

    &--sixth {
        padding-bottom: 237px;
        padding-top: 0;
        @include respond-above(xl) {
            padding-bottom: 230px;
        }
    }

    .text_block__wrapper {
        max-width: 940px;
        margin: 0 auto;
        position: relative;

        @include respond-above(md) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include respond-above(lg) {
            padding-left: 80px;
        }

        .text_block__container {
            max-width: 540px;
            @include flexbox();
            @include flex-direction(column);

            .text_block__title {
                margin-bottom: 20px;
                @include respond-above(md) {
                    padding-right: 20%;
                    margin-bottom: 28px;
                }
            }

            .text_block__description {
                margin-bottom: 30px;
                @include respond-above(md) {
                    margin-bottom: 32px;
                }
            }
        }

        .mvb_path_two {
            display: block;
            position: absolute;
            width: 100%;
            max-width: 265px;
            transform: scale(-1, 1);
            margin: 0 auto -160px auto;
            left: 5%;
            right: 5%;
            bottom: 0;
            z-index: 4;
            @include respond-above(md) {
                transform: scale(1);
                margin: 0 auto -140px auto;
            }

            @include respond-above(md) {
                left: 0%;
            }

            svg {
                width: 100%;
                display: block;
            }
        }

        .mvb_path_fourth {
            @include flexbox();
            @include flex-direction(column);
            max-width: 975px;
            width: 100%;
            margin: 50px auto -220px auto;
            z-index: 4;
            position: relative;
            @media (min-width: 500px) {
                margin: 40px auto -220px auto;
            }

            svg {
                max-width: 264px;
                width: 100%;
                transform: scale(-1, 1) translateX(30px) rotate(30deg);
                @media (min-width: 500px) {
                    transform: scale(1, 1) translateX(0px) rotate(180deg);
                    align-self: flex-end;
                    margin-top: -70px;
                    margin-right: 15%;
                }
                @include respond-above(md) {
                    margin-right: 30%;
                }
                @include respond-above(xl) {
                    margin-right: 15%;
                }
            }

            .mvb_second_img {
                display: block;
                width: 150px;
                position: absolute;
                right: 0;
                bottom: -50px;
                @media (min-width: 500px) {
                    bottom: -50px;
                    right: 20%;
                    width: 120px;
                }
                @include respond-above(md) {
                    bottom: 00px;
                    right: 0;
                    width: 180px;
                }
                @include respond-above(xl) {
                    width: 265px;
                    right: -148px;
                }
            }
        }

        .mvb_path_sixth {
            @include flexbox();
            @include flex-direction(column);
            max-width: 975px;
            width: 100%;
            margin: 0px auto -220px auto;
            z-index: 4;
            position: relative;

            svg {
                display: block;
                width: 100%;
                max-width: 260px;
                transform: scale(-1, 1) rotate(360deg);
                align-self: flex-end;
                @media (min-width: 500px) {
                    margin-top: -50px;
                    margin-right: 13%;
                }
            }
        }
    }
}

.section_nav {
    position: fixed;
    top: 40%;
    z-index: 12;
    @include flexbox();
    @include flex-direction(column);
    @include respond-above(lg) {
        display: block;
    }
    transform: translateX(-150px);
    opacity: 0;
    transition: all 0.8s ease-in-out;

    &.section_nav--reveal {
        transform: translateX(-15px);
        opacity: 1;
        transition: all 0.8s ease-in-out;
    }

    .section_nav__prev,
    .section_nav__next {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        transition: transform 0.3s $exponential;
        cursor: pointer;
    }

    .section_nav__prev {
        transform: rotate(-90deg);
        &:hover {
            transform: rotate(-90deg) translateX(5px);
        }
    }
    .section_nav__next {
        transform: rotate(90deg);
        &:hover {
            transform: rotate(90deg) translateX(5px);
        }
    }

    .section_nav__counter {
        @include flexbox();
        @include flex-direction(row);
        margin: 27px 0;

        .section_nav__number {
            position: relative;
            padding: 10px;
            font-size: 34px;
            font-weight: 500;
            display: block;
        }

        .section_nav__total {
            padding: 10px;
            font-size: 20px;
            position: relative;

            //line between the numbers
            &:before {
                content: "";
                height: 54px;
                width: 1px;
                transform: rotate(9deg) translateY(-15px);
                position: absolute;
                left: -5px;
                display: block;
                background: $black;
            }
        }
    }
}
