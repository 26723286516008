.side-link {
	width: 100%;
	height: 100px;
	border-radius: 15px 0 0 15px;

	@include respond-above(md) {
		border-radius: 0 15px 15px 0;
		min-height: 120px;
		&--lg{
			height: 170px;
			
		}
	}


	p {
		line-height: 18px;
	}
	.stretched-link {
		text-decoration: none;
	}
	&:after {
		content: "";
		right: 20px;
		bottom: 10px;
		position: absolute;
		display: inline-block;
		height: 20px;
		width: 20px;
		vertical-align: middle;
		background: url(/assets/img/icons/arrow_sprite.svg) center right no-repeat;
		background-size: 45px 45px;
		transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
		margin-left: 20px;

		@include respond-above(md) {
			height: 20px;
			width: 40px;
			background-size: 70px 70px;
			background-position-x: -30px;
			margin-left: 10px;
		}
	}
	&:hover {
		cursor: pointer;
		&::after {
			background-position-x: -0px;
			@include respond-above(lg) {
				background-position-x: 10px;
			}
		}
	}
	a {
		color: inherit;
	}
}
