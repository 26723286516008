.timeline{
	//basis and all itmes in between first and last item
	&__item{
		.arrow-triangle{
			display: none;
		}
		overflow: hidden;
		&__line {
			&::before{
				content: "";
				background-color: #D9FFF4;
				height: 300%; 
				width: 5px; 
				left: 48%; 
				z-index: 1;
				position: absolute;
				display: block;
				transform: translateY(-50%);
			}
		}
	}
	//last item of 'past' timeline
	&__item:first-child {
		.timeline__item__line {
			&::before{
				transform: translateY(0) !important;
			}
		}
	}
	&__item.past {
		.timeline__item__line {
			&::before{
				background-color: #D9FFF4;
			}
		}
	}
	//last item of 'past' timeline
	&__item.past:last-child,
	&__item.past:has(+ :not(.past)) {
		overflow: hidden;
		.timeline__item__line {
			z-index: 2;
			&::before{
				content: "";
				height: 300%;
				width: 5px; 
				left: 48%; 
				z-index: 1;
				position: absolute;
				display: block;
				transform: translateY(-50%);
			}
		}
		.arrow-triangle {
			top: 100%;
			display: block;
			&::after{
				z-index: 4;
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 25px solid #D9F7FE;
				left: 51%;
				transform: translateX(-50%) translateY(-100%) rotate(180deg);
			}
		}
	}
	//last item of timeline
	&__item:last-child {
		.timeline__item__line {
			&::before{
				content: "";
				background: #D9FFF4;
				background: linear-gradient(0deg, rgba(217,247,254,0) 0%, #D9FFF4 70%);
				height: 300%; 
				width: 5px; 
				left: 48%; 
				z-index: 1;
				position: absolute;
				display: block;
				transform: translateY(-50%);
			}
		}
		&.past {
			.timeline__item__line {
				&::before {
					height: 300%;
					transform: translateY(-50%);
					background: linear-gradient(0deg, rgba(217,247,254,0) 0%, #d9f7fe 70%);
				}
			}
		}
	}

	.modal {
        .modal-dialog {
            background-color: white;
            margin: 30px;
            padding: 60px 30px;
            border-radius: 15px;
            @include respond-above(md) {
                margin: auto;
                top: 50%;
                transform: translateY(-50%);
                padding: 60px;
            }
            .modal-content {
                text-align: left;

                img {
                    &.modal-close {
                        top: -40px;
                        right: 8px;
                        @include respond-above(md) {
                            top: -30px;
                            right: -5px;
                        }
                    }
                }
                h4 {
                    margin-bottom: 20px;
                    font-weight: 600;   
                }
                p {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}