.text_link {
	margin-top: 80px;
	margin-bottom: 80px;
	.text_link__container {
		border-radius: 15px;
		.text_link_text {
			@include flexbox();
			@include flex-direction(column);
			margin: auto;
			padding: 30px 30px 35px 30px;
			@include respond-above(lg) {
				padding: 40px 40px 35px 40px;
				width: 100%;
			}

			h3 {
				@include respond-above(md) {
					margin-bottom: 22px;
				}
			}

			p {
				@include respond-above(md) {
					margin-bottom: 35px;
				}
			}
			.text_link__btn--end {
				align-self: flex-end;
			}
		}
	}
}
