.team_list {
    @include flexbox();
    @include flex-direction(column);
    width: 100%;

    .team_list__wrapper {
        @include flexbox();
        @include flex-direction(column);
        width: 100%;

        .team_list__container {
            @include flexbox();
            @include flex-direction(column);
            width: 100%;

            &--medium {
                margin: 0 auto;

                @include respond-above(lg) {
                    max-width: 940px;
                }
            }

            @include respond-above(lg) {
                padding: 0px !important;
            }

            .team_list__section {
                @include flexbox();
                @include flex-direction(column);
                @include flex-wrap(wrap);
                list-style-type: none;
                margin: 0 0 30px 0;
                padding: 0;

                @include respond-above(sm) {
                    @include flex-direction(row);
                    @include justify-content(flex-start);
                }

                @include respond-above(md) {
                    margin-bottom: 100px;
                }

                .team_list__member {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 20px;
                    @include respond-above(sm) {
                        width: 49%;
                        margin-right: 2%;
                        &:nth-child(2n + 2) {
                            margin-right: 0%;
                        }
                    }
                    @include respond-above(md) {
                        max-width: 300px;
                        margin-right: 2%;
                        margin-bottom: 30px;
                        &:nth-child(2n + 2) {
                            margin-right: 2%;
                        }
                        &:nth-child(3n + 3) {
                            margin-right: 0%;
                        }
                    }
                }
            }
        }
    }
}

.member_wrapper {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    width: 100%;
    height: 100%;
    background-color: $grey-2;
    border-radius: $default-radius;

    position: relative;
    transition: all 0.5s ease-in;
    overflow: hidden;
    @include respond-above(sm) {
        padding-bottom: 48px;
    }

    .member_container {
        @include flexbox();
        @include flex-direction(column);
    }

    picture {
        width: 100%;
        img,
        source {
            width: 100%;
        }
        &:nth-child(1){
            opacity: 1;
            transition: all 1s ease;
        }
        &:nth-child(2){
            opacity: 0;
            top: 0;
            left: 0;
            position: absolute;
            transition: all 1s ease;
        }
    }
    &.reveal {
        .member_container {
            picture {
                &:nth-child(1){
                    opacity: 0;
                }
        
                &:nth-child(2){
                    opacity: 1;
                }
            }
        }
    }

    .member_info {
        padding: 24px 22px;

        p {
            font-size: 16px;
            line-height: 21px;
        }
        .member_info_header {
            display: flex;
        }

        a.linkedin-icon {
            height: 20px;
            width: 20px;
            background-image: url(/assets/img/icons/linkedIn_Icon-square.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .member_link {
        @include flex-direction(flex-end);
        @include align-self(flex-end);
        padding-bottom: 1rem;
        @include respond-above(sm) {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

.member_wrapper_overlay {
    display: flex;
    background-color: #f4eeee;
    position: absolute;
    height: 100%;
    width: 100%;
    flex-direction: column;
    transition: transform 0.4s ease-in-out;
    transform: translate(0%, 100%);
    top: 0%;
    left: 0%;

    .member_info {
        flex-grow: 1;
    }
    .member_link {
        @include flex-direction(flex-end);
        @include align-self(flex-end);
    }
    &.visible {
        background-color: $grey-2;
        height: 100%;
        transition: transform 0.4s ease-in-out;
        transform: translate(0%, 0%);
    }
}
