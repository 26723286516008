.feedback {
    border-radius: $default-radius;
    padding: 30px;
    
    @include respond-above(md) {
        padding: 40px 34px 40px 40px;
    }

    @include gradient_two;

    &--theme-2 {
        @include gradient;
    }

    p {
        margin-bottom: 8px;
    }

    h6 {
        margin-bottom: 30px;
        @include respond-above(md) {
            margin-bottom: 0;
        }
    }
    .icon {
        width: 40px;
        margin-right: 20px;
    }

    .form-group {
        select{
            @include poppins(regular);
            height: 3.125rem;
            border-radius: 0.3125rem;
            border: none;
            option {
                background: $white;
            }
            &.custom-select {
				background: $white url(/assets/img/icons/chevron-blue.svg) no-repeat right 1.5rem center/14px 20px;
			}
            &::placeholder {
                color: $gray-darker;
            }
            &:hover {
                background-color: #f4eeee;
            }
            &:focus {
                background-color: white;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
            }
        }
        margin-bottom: 32px;
        @include respond-above(md) {
            margin-bottom: 0;
            margin-right: 32px;
        }
    }
    .success-message{
        p{
            margin-top: 8px;
        }
        &--negative{

            a{
                margin-top: 32px;
                @include respond-above(md) {
                    margin-top: 0;
                }
            }
        }
    }
}
