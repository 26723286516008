.container-article-content-data-table {
  background-color: $white;
  padding: 30px;
  border-radius: $default-radius;
  margin-top: 30px;
  margin-bottom: 30px;

  .scroll-table {
    @include respond-below(lg) {
      overflow: auto;
      white-space: nowrap;
      -moz-box-shadow: 2px 0px 3px 0px #ccc;
      -webkit-box-shadow: 2px 0px 3px 0px #ccc;
      box-shadow: 2px 0px 3px 0px #ccc;
      position: relative;
    }
  }
  .container-table {
    padding: 0;
  }
}