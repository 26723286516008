p.chart-sub-title {
    font-style: normal;
    font-family: $freighttext-medium;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
}

.container__chart-bar-horizontal {
    @include respond-below(md) {
        height: 70px;
    }
}
