// _springboards.scss
.springboards {
	margin-bottom: 120px;

	.springboards__container {
		@include flexbox();
		@include flex-direction(column);
		justify-content: center;

		.springboards__header {
			@include flexbox();
			@include flex-direction(column);
			margin: 0 auto;
			padding: 0 15px;

			@include respond-above(sm) {
				max-width: 620px;
				margin-bottom: 36px;
			}

			.springboards__title {
				font-size: 48px;
				line-height: 72px;
				padding-bottom: 32px;
				margin-bottom: 32px;
				border-bottom: 1px dashed $gray;
				@include respond-above(lg) {
					font-size: 60px;
					padding-bottom: 14px;
					margin-bottom: 21px;
				}
			}

			p {
				text-align: center;
				margin-bottom: 32px;

				@include respond-above(md) {
					padding: 0 30px;
				}
			}
		}

		.springboards__title {
			text-align: center;
		}

		.springboards__list-wrapper {
			@include flexbox();
			@include flex-direction(column);
			width: 100%;
			max-width: 970px;
			margin: 0 auto;

			.springboards__list {
				margin: 0;
				padding: 0;
				list-style-type: none;

				@include respond-above(lg) {
					@include flex-direction(row);
				}
			}
		}

		.springboard {
			@include flexbox();
			@include flex-direction(column);
			margin: 0;
			cursor: pointer;
			margin-bottom: 20px;
			transform: translateY(0px);
			transition: transform 0.3s $exponential;

			@include respond-above(lg) {
				margin-bottom: 30px;
			}

			&:hover {
				transform: translateY(-4px);
				.springboard__link {
					.springboard__header {
						&:after {
							background-position-x: -0px;
							@include respond-above(lg) {
								background-position-x: 10px;
							}
						}
					}
				}
			}

			.springboard__link {
				@include flexbox();
				@include flex-direction(row);
				width: 100%;
				text-decoration: none;

				@include respond-above(lg) {
					@include flex-direction(column);
				}

				.springboard__header {
					background-color: $blue-light-two;
					border-radius: $default-radius;
					padding: 20px 25px 10px 25px;
					width: 100%;
					position: relative;
					min-height: 70px;

					@include respond-above(sm) {
						min-height: 120px;
					}

					@include respond-above(lg) {
						width: 100%;
						min-height: 230px;
						padding: 50px 25px 20px 25px;
					}

					.art__body--date {
						font-size: 16px;
					}

					h5 {
						font-size: 18px;
						line-height: 24px;
						font-weight: 400;

						@include respond-above(sm) {
							font-size: 21px;
							line-height: 30px;
							font-weight: 400;
						}
					}

					&:after {
						content: "";
						right: 20px;
						bottom: 10px;
						position: absolute;
						display: inline-block;
						height: 20px;
						width: 20px;
						vertical-align: middle;
						background: url(/assets/img/icons/arrow_sprite.svg) center right no-repeat;
						background-size: 45px 45px;
						transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
						margin-left: 20px;

						@include respond-above(md) {
							height: 20px;
							width: 40px;
							background-size: 70px 70px;
							background-position-x: -30px;
							margin-left: 10px;
						}
					}

					h5 {
						color: $black;
						text-decoration: none;
					}

					&--green {
						width: 100%;
						min-height: 122px;
						padding: 20px 20px 40px 20px;
						background-color: $softgreen;
						h5 {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.btn {
		@include align-self(center);
		margin-top: 30px;
		@include respond-above(sm) {
			margin-top: 0;
		}
	}
}
