#helpdesk-detail {
    .container-helpdesk-detail-header {
        border-radius: 0 0 15px 15px;
        padding-bottom: 1px;
        margin-bottom: 100px;
        @include respond-above(sm) {
            border-radius: $default-radius;
            margin-top: 40px;
        }

        &__title-image {
            @include respond-above(md) {
                .image {
                    min-height: 271px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            padding-top: 60px;
            padding-bottom: 0;
            @include respond-above(md) {
                padding-bottom: 0;
                z-index: 2;
                position: relative;
            }
            h1 {
                margin-bottom: 0;
                &:last-of-type {
                    margin-bottom: 20px;
                }
            }

            h5 {
                margin-bottom: 30px;
            }
            p {
                margin-bottom: 60px;
            }
        }
        &__address {
            @include respond-above(md) {
                margin-top: -60px;
                z-index: 1;
            }
        }
    }
    .container-helpdesk-detail-header--springboard {
        border-radius: $default-radius;
        padding-top: 100px;
        padding-bottom: 90px;
        margin-bottom: 80px;
        @include respond-above(md) {
            section.springboards {
                margin: 0;
            }
        }
        @include respond-above(md) {
            margin-bottom: 120px;
        }
    }
    &__buttons {
        a {
            margin-bottom: 10px;
            margin-left: 10px;
        }
    }
}
