.contact-complaints {
    margin-bottom: 60px;

    @include respond-below(lg) {
        flex-direction: column;
        justify-content: center;
    }

    @include respond-above(lg) {
        display: flex;
    }

    .contact-complaints__container {
        &_info {
            border-radius: 15px 0 0 15px;
            @include respond-below(lg) {
                border-radius: 15px 15px 0 0;
            }
            a {
                padding-bottom: 40px;
            }
            p {
                margin-bottom: 1.875rem;
                &:last-child {
                    margin: 0;
                }
            }
        }

        &_complaints {
            border-radius: 0 15px 15px 0;
            @include respond-below(lg) {
                border-radius: 0 0 15px 15px;
            }
        }

        .contact-complaints_text {
            @include flexbox();
            @include flex-direction(column);
            margin: auto;
            padding: 40px 15px 40px 15px;
            @include respond-above(md) {
                padding: 40px 45px 60px 45px;
                width: 100%;
            }

            h4 {
                padding-bottom: 4px;
            }
            h5 {
                margin: 0;
                line-height: 30px;
                padding-bottom: 5px;
            }
        }
        .contact-complaints__link {
            display: flex;
            justify-content: flex-end;
        }
    }
}
