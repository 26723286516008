.notification {
    padding: 20px 30px;
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: -20px;
    border-radius: 15px;
    position: relative;
    max-width: 940px;
    width: auto;
    display: none;
    @include respond-above(md) {
        padding: 40px 60px;
        margin-left: auto;
        margin-right: auto;
    }
    .notification__close {
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 20px;
        @include respond-above(md) {
            top: 30px;
            right: 30px;
        }
    }
}