.publications_header {
	margin-top: 80px;
	margin-bottom: 80px;
	max-width: 960px;

	@include respond-below(sm) {
		margin-top: 20px;
		margin-bottom: 60px;
	}
}
