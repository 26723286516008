.info_block {
    padding: 120px 30px;
    @include respond-above(md) {
        padding: 160px 30px;
    }

    &--stat {
        padding: 30px 20px 240px 20px;

        @include respond-above(md) {
            padding: 30px 50px 120px 50px;
        }

        .info_block__wrapper {
            .info_block__container {
                position: relative;
                margin-bottom: 20px;
                @include respond-above(xl) {
                    transform: translateX(47px);
                }
            }

            .mvb_path_third {
                display: block;
                max-width: 710px;
                width: 100%;
                margin: 0 auto -220px auto;
                z-index: 4;

                @include respond-above(lg) {
                    margin: 0 auto -120px auto;
                }

                svg {
                    display: block;
                    width: 100%;
                    max-width: 247px;
                }
            }
        }
    }

    &--slider {
        padding: 30px 20px 260px 20px;

        @include respond-above(md) {
            padding: 30px 50px 260px 50px;
        }

        .mvb_path_fith {
            display: block;
            position: absolute;
            width: 100%;
            max-width: 265px;
            transform: scale(-1, 1);
            margin: 0 auto -220px auto;
            left: 5%;
            right: 5%;
            bottom: 0;
            z-index: 4;
            @include respond-above(md) {
                transform: scale(1, -1);
                margin: 0 auto -220px auto;
                left: 8%;
                right: auto;
            }

            @include respond-above(lg) {
                left: 10%;
                margin: 0 auto -200px auto;
            }
            @include respond-above(xl) {
                left: 14%;
            }

            svg {
                width: 100%;
                display: block;
            }
        }
        .carousel-indicators {
            z-index: 4;
        }
    }

    &--grid_block {
        padding: 0px 20px 20px 20px;

        @include respond-above(md) {
            padding: 0px 50px 0px 50px;
        }

        .info_block__wrapper {
            .info_block__container {
                position: relative;
                @include respond-above(xl) {
                    transform: translateX(47px);
                }

                h3 {
                    margin-bottom: 20px;
                }
            }
        }

        .mvb_path_seventh {
            display: block;
            max-width: 770px;
            width: 100%;
            margin: 40px auto 0px auto;
            z-index: 4;
            overflow: hidden;
            height: 210px;

            @media (min-width: 1048px) {
                height: 100%;
                overflow: visible;
            }

            svg {
                display: block;
                width: 100%;
                max-width: 227px;
                margin: 0 auto 0px auto;
                margin-top: -150px;
                @media (min-width: 1048px) {
                    margin: -150px 0 0 6%;
                }
                @media (min-width: 1200px) {
                    margin: -150px 0 0 18%;
                }
            }
        }
    }

    &--card_text {
        padding: 30px 20px 260px 20px;

        @include respond-above(md) {
            padding: 0px 50px 260px 50px;
        }

        .mvb_path_fith {
            display: block;
            position: absolute;
            width: 100%;
            max-width: 265px;
            transform: scale(-1, 1);
            margin: 0 auto -220px auto;
            left: 5%;
            right: 5%;
            bottom: 0;
            z-index: 4;
            @include respond-above(md) {
                transform: scale(1, -1);
                margin: 0 auto -220px auto;
                left: 8%;
                right: auto;
            }

            @include respond-above(lg) {
                left: 10%;
                margin: 0 auto -200px auto;
            }
            @include respond-above(xl) {
                left: 14%;
            }

            svg {
                width: 100%;
                display: block;
            }
        }
    }

    &--stats_block {
        padding: 0px 20px 260px 20px;

        @include respond-above(md) {
            padding: 0px 50px 260px 50px;
        }
        @media (min-width: 1160px) {
            padding-bottom: 80px;
        }
        @media (min-width: 1200px) {
            .stats_block {
                transform: translateX(64px);
            }
        }

        .mvb_path_last {
            display: block;
            position: absolute;
            width: 100%;
            margin-bottom: -250px;
            left: 0;
            right: auto;
            bottom: 0;
            z-index: 4;
            height: 235px;
            overflow: hidden;

            @media (min-width: 1160px) {
                left: 0;
                right: auto;
                margin-bottom: 0;
                height: 420px;
                width: 40%;
            }

            svg {
                width: 100%;
                display: block;
                margin: 0 auto;
                margin-top: -200px;
                max-width: 297px;

                @media (min-width: 1160px) {
                    margin: 0;
                    margin-left: 27%;
                }
            }
        }
    }

    .info_block__wrapper {
        max-width: 1075px;
        margin: 0 auto;
        position: relative;
        @include flexbox();
        flex-direction: column;

        &--slider {
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-end;

            @include respond-above(xl) {
                flex-direction: row;
            }
            h3 {
                width: auto;
            }

            .info_block_carousel__indicators {
                max-width: 670px;
                width: 100%;
                align-self: flex-end;
                bottom: -35px;
                right: 0px !important;
                left: auto;
                margin: 0 !important;

                @include respond-above(xl) {
                    transform: translateX(47px);
                }

                li {
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 100px;
                    border: none;
                    opacity: 1;

                    &.active {
                        background-color: $black;
                    }
                }
            }

            .carousel-inner {
                max-width: 670px;

                @include respond-above(xl) {
                    align-self: flex-end;
                    transform: translateX(47px);
                }

                .carousel-item {
                    .stat_block {
                        background-color: $blue-light-two;
                        border: none;
                    }
                }
            }
        }

        &--card_text {
            .info_block__container {
                .card_block {
                    @include respond-above(xl) {
                        transform: translateX(64px);
                    }
                }
            }
        }

        &--stats_block {
            .info_block__container {
                .card_block {
                    @include respond-above(xl) {
                        transform: translateX(64px);
                    }
                }
            }
        }

        .info_block__container,
        .carousel-inner {
            width: 100%;
            max-width: 670px;
            align-self: flex-end;

            .card_block__text {
                @include flexbox();
                @include flex-direction(column);
                width: 100%;
                margin-top: 20px;
                background-color: #d9fff4;
                border-radius: $default-radius;
                padding: 40px 30px;
                border: 1px solid green;

                p {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                @include respond-above(md) {
                    padding: 60px 50px;
                }
            }

            .stat_block {
                border: 1px solid $black;
                border-radius: $default-radius;
                padding: 20px;
                margin-top: 20px;

                @include respond-above(md) {
                    padding: 47px 60px 55px 60px;
                    border: 2px solid $black;
                }

                small {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    border-bottom: 2px solid $black;
                }
                cite {
                    line-height: 32px;
                    @include respond-above(md) {
                        font-size: 36px;
                        line-height: 52px;
                    }
                }
            }

            .stats_block {
                @include flexbox();
                @include flex-direction(column);

                .stats_block__container {
                    border: 1px solid $black;
                    border-radius: $default-radius;
                    padding: 20px;
                    @include flexbox();
                    @include flex-direction(column);

                    @media (min-width: 710px) {
                        padding: 60px 30px 0px 30px;
                        border: 2px solid $black;
                    }

                    .stats_block__list {
                        @include flexbox();
                        @include flex-direction(column);
                        list-style-type: none;
                        margin: 0;
                        padding: 0;

                        @media (min-width: 710px) {
                            @include flex-direction(row);
                            @include justify-content(center);
                            @include flex-wrap(wrap);
                        }

                        .stat {
                            @include flexbox();
                            @include flex-direction(column);
                            @include justify-content(center);
                            @include align-items(center);

                            text-align: center;
                            padding: 15px;
                            position: relative;
                            min-height: 200px;
                            @media (min-width: 710px) {
                                padding: 30px 50px 50px 50px;
                            }

                            &:after {
                                content: "";
                                display: block;
                                width: 125px;
                                height: 125px;
                                background-color: #d9fff4;
                                border-radius: 100%;
                                top: 0;
                                position: absolute;
                                z-index: 0;
                            }

                            .stat__title,
                            .stat__description {
                                z-index: 1;
                            }

                            .stat__title {
                                font-size: 60px;
                                line-height: 60px;
                                margin: 0 0 20px 0;
                            }

                            .stat__description {
                                font-weight: 300;
                                max-width: 200px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .grid_block {
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(equal-spacing);
            @include flex-wrap(wrap);
            margin: 0;
            padding: 0;
            list-style-type: none;
            border: 2px solid $black;
            border-radius: $default-radius;
            overflow: hidden;
            @include respond-above(md) {
                border: 3px solid $black;
            }

            li {
                @include flexbox();
                @include flex-direction(row);
                @include justify-content(center);
                @include align-items(center);
                width: 50%;

                &.red {
                    background-color: #a21942;
                }
                &.mustard {
                    background-color: #cc8c2b;
                }
                &.olive {
                    background-color: #49763d;
                }
                &.navy {
                    background-color: #005489;
                }

                img {
                    display: block;
                    width: 100%;
                    max-width: 207px;
                }
            }
        }
    }
}
