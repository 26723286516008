@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap");

@include font-face("freighttext_prolight", "/assets/font/freigtexprobook-webfont");
@include font-face("freighttext_probook", "/assets/font/freigtexprobook-webfont");
@include font-face("freighttext_probook_italic", "/assets/font/freigtexprobookit-webfont");
@include font-face("freighttext_prosemibold", "/assets/font/freigtexprosem-webfont");
@include font-face("freighttext_promedium", "/assets/font/freigtexpromed-webfont");
@include font-face-no-ttf("korolev_condensedbold", "/assets/font/KorolevCondensedBold");

// Font family names
$poppins: "Poppins";
// FreightTextProSemibold (body)
$freighttext-light: "freighttext_prolight";
$freighttext: "freighttext_probook";
$freighttext-italic: "freighttext_probook_italic";
$freighttext-semibold: "freighttext_prosemibold";
$freighttext-medium: "freighttext_promedium";
$korolev-condensedbold: "korolev_condensedbold";

// Font families
$base-font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
	sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-font-family: $freighttext, $base-font-family;
$heading-font-family: $poppins, $base-font-family;

body {
	font-family: $body-font-family;
	color: $black;
	font-size: 19px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: inherit;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7 {
	color: inherit;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	display: block;
	width: 100%;
	@include poppins(regular);

	//Light
	.light,
	&.light {
		@include poppins(light);
	}

	//Medium
	.medium,
	&.medium {
		@include poppins(medium);
	}

	//Bold

	.semibold,
	&.semibold,
	.bold,
	&.bold {
		@include poppins(semibold);
	}
}

h1,
.h1 {
	font-size: 36px;
	line-height: 48px;

	@include respond-above(sm) {
		font-size: 48px;
		line-height: 64px;
	}
}

h2,
.h2 {
	font-size: 30px;
	line-height: 38px;

	@include respond-above(sm) {
		font-size: 36px;
		line-height: 48px;
	}
}

h3,
.h3 {
	font-size: 24px;
	line-height: 30px;

	@include respond-above(sm) {
		font-size: 30px;
		line-height: 38px;
	}
}

h4,
.h4 {
	font-size: 21px;
	line-height: 30px;

	@include respond-above(sm) {
		font-size: 24px;
		line-height: 30px;
	}
}

h5,
.h5 {
	font-size: 18px;
	line-height: 24px;

	@include respond-above(sm) {
		font-size: 21px;
		line-height: 36px;
	}
}

h6,
.h6 {
	font-size: 15px;
	line-height: 18px;

	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
	}
}

.h7 {
	font-size: 14px;
	line-height: 18px;

	@include respond-above(sm) {
		font-size: 15px;
		line-height: 18px;
	}

	@include poppins(regular);

	//Light
	.light,
	&.light {
		@include poppins(light);
	}

	//Medium
	.medium,
	&.medium {
		@include poppins(medium);
	}

	//Bold

	.semibold,
	&.semibold,
	.bold,
	&.bold {
		@include poppins(semibold);
	}
}

.gen__body,
.art__body {
	font-family: $body-font-family;
	color: $black;
}

.gen__body {
	font-size: 19px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 30px;
	display: block;
	width: 100%;

	//Freight font
	&--fr-bold {
		font-family: $freighttext-semibold, $body-font-family;
		font-size: 19px;
		line-height: 30px;
	}

	&--fr-text,
	&--fr-italic {
		font-size: 19px;
		line-height: 30px;
	}

	&--fr-text {
		font-family: $freighttext, $body-font-family;
	}

	&--fr-italic {
		font-family: $freighttext-italic, $body-font-family;
	}

	&--fr-med-sml {
		font-family: $freighttext-medium, $body-font-family;
		font-size: 16px;
		line-height: 23px;
	}

	&--fr-italic-sml {
		font-family: $freighttext-italic, $body-font-family;
	}

	&--fr-italic-sml {
		font-size: 16px;
		line-height: 21px;
	}

	&--fr-sml {
		font-size: 16px;
		line-height: 21px;
		font-family: $freighttext, $body-font-family;
	}

	//Poppins
	&--pop {
		@include poppins(regular);
	}

	&--pop-semibold,
	&--pop {
		font-size: 16px;
		line-height: 26px;
	}

	&--pop-semibold {
		@include poppins(semibold);
	}

	&--small {
		font-size: 12px;
	}
}

.art__body {
	font-size: 19px;
	font-family: $freighttext;
	letter-spacing: 0;
	line-height: 36px;
	display: block;
	width: 100%;

	&--date {
		font-family: $freighttext-italic;
		font-size: 16px;

		@include respond-above(md) {
			font-size: 21px;
		}
	}

	&--medium {
		font-size: 25px;
		line-height: 40px;
		font-family: $freighttext-medium;
	}

	&--semibold {
		font-size: 22px;
		line-height: 38px;
		font-family: $freighttext-medium;
	}

	&--quote {
		font-style: normal;
		font-family: $freighttext-italic;
		font-size: 21px;
		line-height: 38px;
	}

	&--credit-grey {
		color: $gray-darker;
	}

	&--credit-grey,
	&--credit-black {
		@include poppins(regular);
		font-size: 12px;
		line-height: 23px;
	}
}

a[href^="mailto:"],
a[href^="tel:"],
a.text-black {
	color: $black;
	text-decoration: underline;
}

//styling links in p text
p {
	a {
		color: $black;
		text-decoration: underline;

		&:hover {
			color: $black;
		}
	}
}

.form-label {
	@include poppins(regular);
	font-size: 12px;
	line-height: 23px;
}
.text__red {
	color: $error-red;
}

.korolev {
	font-family: $korolev-condensedbold;
	font-size: 18px;
	line-height: 22px;
}
