.container-helpdesk-header {
    border-radius: 0 0 15px 15px;
    @include respond-above(md) {
        border-radius: $default-radius;
        margin-top: 60px;
    }

    margin-bottom: 100px;
    &__content {
        padding-top: 60px;
        padding-bottom: 60px;

        h1 {
            margin-bottom: 10px;
            @include respond-above(md) {
                margin-bottom: 20px;
            }
        }

        h3 {
            margin-bottom: 20px;
        }

        h5 {
            margin-bottom: 30px;
        }
        p {
            margin-bottom: 60px;
        }
        &__buttons {
            display: flex;
            flex-direction: column;
            @include respond-above(md) {
                display: inline;
            }

            a {
                margin-right: 30px;
                margin-bottom: 40px;
            }
        }
    }
}
