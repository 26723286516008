.container-breadcrumb {
    background-color: $white;
    box-shadow: 0 10px 15px rgba(198, 192, 192, 0.25);
    z-index: 9;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    a {
        white-space: nowrap;
        padding: 10px 0;
        color: $blue-darker;
        width: auto;
        @include respond-below(lg) {
            font-size: 13px;
        }
        &:after {
            content: "";
            display: inline-block;
            height: 7px;
            width: 5px;
            color: $black;
            vertical-align: middle;
            background: url("/assets/img/icons/arrow_front--black.svg") center left no-repeat;
            background-size: contain;
            margin-left: 10px;
            margin-right: 10px;
            @include respond-above(lg) {
                height: 12px;
                width: 7px;
            }
        }
    }
    .breadcrumb-links {
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 7px 0;
    }
    p.breadcrumb-links__current-page-name {
        padding: 10px 0;
        width: auto;
        @include respond-below(lg) {
            font-size: 13px;
        }
    }
}
