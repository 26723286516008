.page_redirect {

	&__image {
		background-size: cover;
		height: 280px;
		width: 100%;
		background-position: center;
		@include respond-above(md) {
			min-height: 315px;
		}
		@include respond-above(lg) {
			height: 100%;
			width: auto;
		}
	}
	&__text {
		p:last-child {
			margin-bottom: 0;
		}
		&:after {
			content: "";
			right: 20px;
			bottom: 20px;
			position: absolute;
			display: inline-block;
			height: 20px;
			width: 20px;
			vertical-align: middle;
			background-image: url(/assets/img/icons/arrow_sprite.svg);
			background-position: center right;
			background-repeat: no-repeat;
			background-size: 45px;
			transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
			margin-left: 20px;

			@include respond-above(md) {
				width: 40px;
				background-size: 70px;
				background-position-x: -30px;
				margin-left: 10px;
			}
		}
	}
	&:hover {
		.page_redirect__text {
			&:after {
				background-position-x: -0px;
				@include respond-above(lg) {
					background-position-x: 10px;
				}
			}
		}
	}

	.page_redirect__ctas {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom:30px;
		gap: 20px;
		.btn {
			&.btn__secondary {
				align-self: auto;
			}
		}
	}
}
