.mvb_link_block {
    &__title {
        margin-bottom: 40px;
        .h3 {
            margin: 0;
        }
    }
    &__wrapper {
        @include gradient;
        border-radius: $default-radius;
        width: 100%;
    }
    &__container {
        max-width: 940px;
        padding-top: 60px;
        padding-bottom: 60px;
        margin: 0 auto;
    }
}
