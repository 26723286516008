a#scroll--top {
	position: fixed;
	bottom: 0;
	z-index: 9999;
	height: 50px;
	width: 80px;
	right: 20px;
	border-radius: 15px 15px 0 0;
	background-color: #f4eeeea3;
	box-shadow: 0px 0px 20px $grey-3;
	transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
	transform: translateY(10px);
	display: none;

	@include respond-below(md) {
		display: none !important;
	}

	&:hover {
		transform: translateY(0);
	}

	&:focus {
		box-shadow: 0px 0px 20px $grey-3;
	}

	&::after {
		content: "";
		display: block;
		position: relative;
		margin: auto;
		left: 0;
		right: 0;
		bottom: -16px;
		border-left: solid 2px black;
		border-top: solid 2px black;
		height: 20px;
		width: 20px;
		transform: rotate(45deg);
	}
}

// base
body,
html {
	min-height: 100% !important;
	scroll-padding-top: 120px;

	&.overflowY {
		overflow-y: hidden;
	}
}

body {
	// needed for the scrollspy
	position: relative;
	&.noscroll {
		overflow: hidden;
	}
}

html {
	scroll-behavior: smooth;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
	outline: none !important;
	box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25) !important; // override bootstrap colors/box-shadow
}

.hidden {
	visibility: hidden;
}
.show {
	visibility: unset;
}

.display-none {
	display: none !important;
}
.radius-8 {
	border-radius: 8px;
}
.radius-15 {
	border-radius: 15px !important;
}
.cursor-pointer {
	cursor: pointer;
}
