.container_contact {
	.publications_header {
		@include respond-below(sm) {
			margin-top: 75px;
		}
	}
	.image_text {
		margin-top: 0;
		.image_text__wrapper {
			margin-bottom: 52px;
			@include respond-above(lg) {
				padding-top: 280px;
				padding-bottom: 140px;
				margin-top: -180px;
				margin-bottom: 100px;
			}

			.image_text__container {
				.image_text__wrapper--padding_btm {
					padding-top: 250px;
					padding-bottom: 0;
					margin-bottom: 0;
					@include respond-below(sm) {
						margin-bottom: 100px;
					}
				}
			}
		}
		& + .image_text_cta {
			margin-top: 400px;
			@include respond-above(md) {
				margin-top: 200px;
			}
		}
	}
	.image_text__wrapper {
		background: $grey-1;
		.image_text__image {
			@include respond-above(sm) {
				order: 2;
			}
		}
	}
}
