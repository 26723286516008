.mvb_content {
    .springboard_section {
        margin-bottom: 90px;
        @include respond-below(md) {
            margin-bottom: 60px;
        }
    }
    .mvb_springboard {
        .springboards {
            margin-bottom: 30px;
            @include respond-below(lg) {
                margin-bottom: 40px;
            }
            .springboards__list {
                padding-left: 5px;
                padding-right: 5px;

                .springboard {
                    padding: 10px;
                    margin: 0;

                    .springboard__link {
                        .springboard__header--green {
                            .h5,
                            h5 {
                                @include poppins(medium);
                            }
                        }
                    }
                }
            }
        }

        &__container {
            max-width: 940px;
            margin: 0 auto;
        }
        .divider {
            width: 100%;
            margin: 20px auto;
            @include respond-below(md) {
                margin: 0px auto 30px auto;
            }
            overflow: hidden;
            text-align: left;
            line-height: 1.2em;
        }
        @include respond-above(md) {
            .divider:after {
                content: "";
                vertical-align: middle;
                display: inline-block;
                width: 100%;
                border-bottom: 1px dashed $blue;
                margin: 0 2% 0 -55%;
            }
            .divider:after {
                margin: 0 -55% 0 2%;
            }
        }
        @include respond-below(md) {
            h4 {
                padding-bottom: 10px;
                margin-bottom: 30px;
                border-bottom: 1px dashed $blue;
            }
        }
    }
}
