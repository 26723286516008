.container-accordion {
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 120px;
}
.accordion {
    padding-top: 40px;
    padding-bottom: 120px;
    h2 {
        margin-bottom: 40px;
    }
    h6 {
        color: $black;
        margin-right: 30px;
        @include respond-below(lg) {
            margin-right: 20px;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .card {
        border: none;
        margin-bottom: 10px;
        border-radius: 10px !important;
        overflow: hidden;
        background-color: transparent;

        .card-header {
            padding: 0;
            margin-bottom: 0 !important;
            border: 1px solid white;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15) !important;
            background-color: $white;
            position: relative;
            z-index: 1;
            .q-anchor {
                margin-top: -70px;
                position: absolute;
            }
        }
        .collapsing {
            margin-top: -20px;
            padding-bottom: 20px;
        }
        .collapse {
            margin-top: -20px;
            padding-bottom: 20px;
            z-index: 0;
        }
        &-body {
            padding: 40px 40px 24px 40px;
            @include respond-above(lg) {
                padding: 40px 60px 24px 60px;
            }
            overflow: hidden;
            background-color: $white;
            border-radius: 10px;
            a {
                color: $black;
                text-decoration: underline;

                &:hover {
                    color: $black;
                }
            }

            .card-ctas {
                margin-top: 40px;
                &__title {
                    margin-bottom: 30px;
                }
                &__downloads {
                    margin-top: 26px;
                    @include respond-above(md) {
                        max-width: 460px;
                    }

                    .btn__file {
                        margin-bottom: 16px;
                    }
                }
                a {
                    text-decoration: none;
                }
            }
            &__fallback-ctas {
                display: flex;
                flex-direction: column;
                @include respond-above(md) {
                    flex-direction: row;
                }

                padding-top: 40px;
                padding-bottom: 7px;
            }
            &__contact {
                padding-left: 40px;
                @include respond-above(md) {
                    width: 50%;
                    padding-left: 80px;
                }
                display: flex;
                flex-direction: column;
            }
            &__contact-links {
                a {
                    padding-left: 0;
                }
            }
            &__thumbs {
                padding-left: 40px;
                margin-top: 40px;
                @include respond-above(md) {
                    margin-top: 0;
                    width: 50%;
                }
            }
        }
        &:hover {
            overflow: visible;
            .card-header {
                background-color: white;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
            }
        }
    }
    .btn-link {
        padding: 20px;
        border: none;
        @include respond-above(lg) {
            padding: 20px 30px 20px 30px;
        }

        box-shadow: 20px $grey-3;
        width: 100%;
        text-align: left;
        text-decoration: none;
        display: flex;
        align-items: center;
        white-space: normal;
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &::after {
            content: "";
            display: inline-block;
            min-width: 20px;
            min-height: 20px;
            background: linear-gradient(#000, #000), linear-gradient(#000, #000), #fff;
            background-position-x: 0%, 0%, 0%;
            background-position-y: 0%, 0%, 0%;
            background-position: center;
            background-size: 100% 2px, 2px 100%;
            background-repeat: no-repeat;
            vertical-align: middle;
            transition: 0.2s cubic-bezier(0.2, 0, 0, 0.2);
        }
        &[aria-expanded="true"] {
            h6 {
                @include poppins(medium);
            }
            &:after {
                background-size: 100% 2px, 0px 100%;
                transform: rotate(180deg);
                transition: all 0.2s $exponential;
            }
        }
    }
}
