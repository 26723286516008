.testimonials {
	@include flexbox();
	@include flex-direction(column);
	width:100%;
	background-color: $grey-1;
	border-radius: 15px;
	padding: 90px 0 120px;

	.block_header {
		@include flexbox();
		@include flex-direction(column);
		 margin: 0 auto;
		padding:0 15px;
		
		@include respond-above(sm) {
			max-width: 620px;
			margin-bottom: 75px;
		}

		.block_header__title {
			text-align:center;
			font-size:48px;
			line-height:72px;
			padding-bottom:32px;
			margin-bottom:32px;
			border-bottom:1px dashed $gray;

			@include respond-above(lg) {
				font-size:60px;
				 line-height:90px;
			}
		}

		.block_header__description {
			text-align:center;
			margin-bottom:32px;
		}

		.btn.btn__primary.btn__primary--sml {
			margin:0 auto;
		}
	}

	.testimonials__container {
		@include flexbox();
		@include flex-direction(column);
		max-width:940px;
		margin:0 auto;

		.testimonials__list {
			@include flexbox();
			@include flex-direction(column);
			max-width: 940px;
			margin:0px;
			padding:0px;

			.testimonial {
				@include flexbox();
				@include flex-direction(column);
				max-width: 780px;
				width:90%;
				@include align-self(center);
				margin-bottom:30px;

				&:first-child {
					@include align-self(flex-start)
				}

				&:last-child {
					@include align-self(flex-end)
				}

				.testimonial__list_item {
					@include flexbox();
					@include flex-direction(row);
					@include flex-wrap(nowrap);
					border: 1px solid $black;
					border-radius: $default-radius;
					position: relative;
					cursor: pointer;
					color: $black;
					text-decoration: none;
					background-color:$white;
					transition:transform 0.3s $exponential;

					@include respond-above(md) {
						border:2px solid $black;
					}

					&:hover {
						transform:translateY(-5px);
						.testimonial__text_wrapper {
							&:after {
								background-position-x: 10px;

								@include respond-above(md) {
									background-position-x: 0;
								}
							}
						}
						
						.testimonial__image_wrapper {
							
							@include respond-above(md) {
								background-size:190%;
							}

							@include respond-above(lg) {
								background-size:180%;
							}
						}
					
					}


					.testimonial__image_wrapper {
						display:block;
						background-color: $grey-2;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						width:30%;
						min-height: 200px;
						border-radius: 15px 0 0 15px;

						@include respond-above(md) {
							background-size:185%;
							transition:background-size 0.3s $exponential;
						}

						@include respond-above(lg) {
							background-size:175%;
							transition:background-size 0.3s $exponential;
						}
					}

					.testimonial__text_wrapper {
						@include flexbox();
						@include flex-direction(column);
						position: relative;
						width:70%;
						padding:25px 25px 25px 20px;

						@include respond-above(md) {
							padding:35px;
						}

						&:after {
							content: "";
							display: inline-block;
							height: 20px;
							width: 30px;
							position: absolute;
							bottom: 10px;
							right: 15px;
							vertical-align: middle;
							background: url("/assets/img/icons/arrow_sprite.svg") center right no-repeat;
							background-size: 50px 50px;
							background-position-x: -20px;
							transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
							margin-left: 10px;

							@include respond-above(md) {
								width:33px;
								height:17px;
								background-position-x: -47px;
								background-size: 80px 80px;
							}
							
						}

						.testimonial__text_title {
							font-size:21px;
							line-height: 30px;

							@include respond-above(md) {
								font-size:30px;
								line-height: 38px;
								margin-bottom:28px;
							}
						}

						.gen__body--fr-italic {
							font-size:16px;
							line-height:21px;
							@include respond-above(md) {
								font-size:21px;
								line-height:28px;
							}
						}
		

					}


				}




			}


		}

	}
}
