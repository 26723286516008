.header-plain-page {
	margin-top: 60px;
	margin-bottom: 44px;
	.art__body--date {
		@include respond-below(sm) {
			margin-bottom: 35px;
		}
	}
}

.plain-text {
	margin-bottom: 60px;
	section {
		margin-bottom: 60px;
		.art__body {
			margin-bottom: 30px;
		}

		h5 {
			margin-bottom: 20px;
			@include respond-below(sm) {
				margin-bottom: 30px;
			}
		}
		ul {
			margin-bottom: 30px;
			li {
				margin-bottom: 10px;
			}
		}
	}
}
