.image_text {
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(space-between);
	width: 100%;
	margin-top: 3rem;

	.image_text__wrapper {
		@include flexbox();
		@include flex-direction(column);
		width: 100%;
		@include gradient;
		border-radius: $default-radius;
		padding-top: 90px;

		&--gradient_two {
			@include gradient_two;
		}

		&--padding_btm {
			padding-bottom: 410px;

			@include respond-above(md) {
				padding-bottom: 250px;
			}
		}

		.image_text__container {
			@include flexbox();
			@include flex-direction(column);
			max-width: 968px;
			margin: 0 auto;

			&.image_text__container--team {
				max-width: 780px;
			}

			@include respond-above(sm) {
				@include flex-direction(row);
				@include flex-wrap(nowrap);
				width: 100%;

				&.flex-wrap {
					@include flex-wrap(wrap);
				}
			}

			.image_text__image {
				@include flexbox();
				@include flex-direction(column);
				margin-bottom: 40px;
				width: 100%;
				max-height: 380px;

				&+.image_text__text {
					@include respond-above(md) {
						width: 60%;
						padding-right: 10%;
					}
				}

				img {
					display: block;
					border-radius: $default-radius;
					border: 1px solid $black;
					width: 100%;
					height: 100%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					@include respond-above(md) {
						border: 3px solid $black;
					}
					&.border-none{
						border: none;
					}
				}

				&.constrain {
					max-height: unset;

					img {
						height: auto;
					}
				}

				.bg__image,
				#video__wrapper {
					display: block;
					border-radius: $default-radius;
					border: 1px solid $black;
					width: 100%;
					height: 100%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					&.border-none{
						border: none;
					}

					@include respond-below(md) {
						min-height: 300px;
					}

					@include respond-above(md) {
						border: 3px solid $black;
						overflow: hidden;
					}

					iframe {
						width: 100%;
						height: 100%;
					}
				}

				#video__wrapper {
					position: relative;
					height: 214px;

					display: flex;
					flex-direction: column;
					justify-content: center;
					background-color: $black;

					&:hover {
						#play_video_button {
							cursor: pointer;
							transform: scale(1.1);
						}
					}

					#play_video_button {
						position: absolute;
						margin: 0 auto;
						left: 0;
						right: 0;
						height: 60px;
						width: 60px;
						background-image: url(/assets/img/icons/play_button.svg);
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						transition: all 0.1s linear;
						pointer-events: none;
					}
				}

				@include respond-above(sm) {
					width: 30%;
					padding-right: 40px;
				}

				@include respond-above(md) {
					width: 47%;
				}
			}

			.image_text__text {
				margin-bottom: 40px;
				@include flexbox();
				@include flex-direction(column);

				@include respond-above(sm) {
					width: 70%;
				}

				@include respond-above(md) {
					width: 100%;
				}

				h4,
				h2 {
					margin-bottom: 25px;

					@include respond-above(md) {
						margin-bottom: 15px;
					}
				}

				p {
					margin-bottom: 30px;

					a {
						color: $black;
					}
				}

				.buttons {
					display: flex;
					flex-direction: column;
					text-align: center;
					align-items: flex-start;

					@include respond-above(sm) {
						flex-direction: row;
						align-items: center;
					}

					.image_text__btn {
						width: fit-content;
						margin-right: 40px;
						margin-bottom: 30px;

						@include respond-above(sm) {
							margin-bottom: 0;
						}
					}

					p {
						margin-bottom: 0;
					}
				}

				.social {
					display: flex;
					flex-direction: row;
					text-align: center;
					align-items: center;

					@include respond-above(md) {
						flex-direction: row;
					}

					a {
						margin-left: 20px;
					}
				}
			}
		}
	}

	&+.image_text_cta {
		margin-top: -400px;

		@include respond-above(md) {
			margin-top: -200px;
		}
	}
}

.image_text_carousel {
	max-width: 780px;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 45px;
	transition: none !important;

	.image_text_carousel__img_wrapper {
		width: 100%;
		transition: none !important;

		&--border {
			border-radius: 15px;
			border: 1px solid $black;

			@include respond-above(md) {
				border: 3px solid $black;
			}
		}

		&.image_text_carousel__img_wrapper {
			transition: none !important;

			.carousel-item {
				.team_slide {
					@include flexbox();
					@include flex-direction(row);
					@include justify-content(center);
					@include flex-wrap(nowrap);
					width: 100%;

					.team_slide__wrapper {
						display: inline-flex;
						@include flex-direction(row);
						@include justify-content(flex-start);
						@include flex-wrap(nowrap);
						border-radius: 15px;
						border: 1px solid $black;
						align-self: flex-start;
						position: relative;
						overflow: hidden;

						@include respond-above(md) {
							border: 3px solid $black;
						}

						.team_member {
							@include flexbox();
							@include flex-direction(column);
							width: 100%;
							height: 180px;
							background-color: $white;

							@media (min-width: 400px) {
								height: 290px;
							}

							@include respond-above(md) {
								width: 240px;
								height: 290px;
							}

							@include respond-above(lg) {
								height: 380px;
								width: 260px;
							}

							.team_member__image {
								width: 100%;
								max-width: 260px;
								height: 60%;
								background-size: cover;
								background-repeat: no-repeat;
								background-position: 70% 100%;

								@media (min-width: 400px) {
									min-height: 73%;
								}

								@include respond-above(lg) {
									height: 300px;
									width: 260px;
								}
							}

							.team_member__text {
								@include flexbox();
								@include flex-direction(column);
								@include justify-content(center);
								@include align-items(center);
								height: 40%;
								align-self: center;
								padding: 10px;
								text-align: center;

								@media (min-width: 400px) {
									min-height: 17%;
								}

								@include respond-above(md) {
									padding: 20px 0 15px 0;
									height: auto;
								}

								.team_member__title {
									font-size: 11px;
									line-height: 14px;
									margin-bottom: 0;

									@media (min-width: 400px) {
										font-size: 13px;
										line-height: 14px;
									}

									@include respond-above(sm) {
										font-size: 15px;
										line-height: 17px;
									}
								}

								.gen__body--fr-italic-sml {
									font-size: 10px;
									line-height: 12px;
									margin-bottom: 0;

									@media (min-width: 400px) {
										font-size: 12px;
										line-height: 14px;
									}

									@include respond-above(sm) {
										font-size: 16px;
										margin-top: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.image_text_carousel__indicators {
		bottom: -45px;
		z-index: 4;

		li {
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 100px;
			border: none;
			opacity: 1;

			&.active {
				background-color: $black;
			}
		}
	}
}

.werkgever {
	.image_text_cta {
		margin-bottom: 80px;
	}
}

.image_text_cta {
	padding-right: 0;
	padding-left: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 3rem;
	position: relative;
	z-index: 3;

	h2 {
		margin-bottom: 38px;
	}

	@include respond-above(sm) {
		margin-right: 0;
		margin-bottom: 3rem;
	}

	@include respond-above(md) {
		margin: 0 auto;
		margin-right: 0;
		margin-bottom: 6rem;
	}

	@include respond-above(lg) {
		margin: 0 auto;
		margin-bottom: 6rem;

		&+.latest_news {
			padding-top: 30px;
		}
	}

	.image_text_cta__wrapper {
		padding: 0;
		margin: 0;
		margin-bottom: 30px;

		@include respond-above(md) {
			padding: 0 30px;
			margin: auto;
		}

		@include respond-above(lg) {
			padding: 0 60px 0 0;
			margin: 0;
		}

		.image_text_cta__container {
			@include flexbox();
			@include flex-direction(column);
			border-radius: 15px;
			padding: 0;
			overflow: hidden;

			@include respond-above(md) {
				@include flex-direction(row);
				border-radius: 15px;
			}

			@include respond-above(lg) {
				padding-right: 5%;
			}

			a {
				color: $black;
			}

			.image_text_cta__image {
				@include flexbox();
				@include flex-direction(column);
				margin-bottom: 40px;
				width: 100%;
				height: 440px;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				border-radius: 15px 15px 0 0;

				@include respond-above(md) {
					border-radius: 15px 0 0 15px;
					max-width: 280px;
					margin-bottom: 0;
					min-height: 380px;
					height: 100%;
				}
			}

			.image_text_cta__text {
				@include flexbox();
				@include flex-direction(column);

				padding: 30px 30px 60px 30px;

				@include respond-above(lg) {
					padding: 40px;
					width: 67%;
				}

				h3 {
					@include respond-above(md) {
						margin-bottom: 22px;
					}
				}

				p {
					@include respond-above(md) {
						margin-bottom: 35px;
					}
				}
			}

			&.constrain {
				overflow: hidden;

				img {
					width: 100%;

					//border-radius: $default-radius 0 0 0;
					@include respond-above(md) {
						display: none;
					}
				}

				.image_text_cta__image {
					display: none;

					@include respond-above(md) {
						display: inline-block;
						min-width: 300px;
						height: auto;
					}
				}
			}
		}
	}
}

.image_text_cta__container-form-results {
	overflow: unset !important;

	a {
		text-decoration: none;
	}

	.image_text_ctas__container {
		position: absolute;
		bottom: -35px;

		@include respond-above(md) {
			width: 100%;
			right: 0;
		}
	}
}