.covid_message {
    margin-bottom: 70px;
    display: flex;

    .covid_message__container {
        border-radius: 15px 15px 15px 15px;

        .covid_message_text {
            @include flexbox();
            @include flex-direction(column);
            margin: auto;
            padding: 30px 30px 35px 30px;
            @include respond-above(lg) {
                padding: 40px 45px 60px 45px;
                width: 100%;
            }
        }
    }
    p:not(:first-of-type) {
        &::before {
            content: "";
            border-bottom: 3px solid #000000;
            width: 100%;
            display: block;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }
}
