.modal-backdrop {
    background-color: $blue-light;
    opacity: 0.8 !important;
    width: 100vw;
    height: 100vh;
}
.modal-dialog {
    max-width: 26.75rem;

    @include respond-above(md) {
        max-width: 38.75rem;
    }
}
.modal-content {
    border: none;
    background-color: transparent;

    .modal-header,
    .modal-body,
    .modal-footer {
        border: none;
    }
    .modal-header {
        background-color: $grey-2;
        border-radius: 0.9375rem 0.9375rem 0 0;
        padding: 2.5rem 2.5rem 1.875rem 2.5rem;
        @include respond-above(md) {
            padding: 2.5rem 10rem 1.25rem 3.75rem;
        }

        .modal-title {
            @include poppins(light);
            line-height: 38px;
        }
    }
    .modal-body {
        background-color: $grey-2;
        border-radius: 0 0 0.9375rem 0.9375rem;
        padding: 0rem 2.5rem 4.6875rem 2.5rem;
        @include respond-above(md) {
            padding: 0rem 3.75rem 5.9375rem 3.75rem;
        }
    }
    .modal-footer {
        background-color: none;
        transform: translate(0%, -50%);
        @include respond-above(md) {
            transform: translate(-7%, -50%);
        }
        .btn {
            @include respond-below(md) {
                scale: 0.8;
            }
        }
    }
}
