.container-our-organisation-green {
	border-radius: 0 0 15px 15px;
	padding-bottom: 370px;

	@include respond-above(md) {
		padding-bottom: 280px;
		border-radius: $default-radius;
		margin-top: 8px;
	}
	&__content {
		padding-top: 90px;
		h2 {
			margin-bottom: 30px;
			&:last-of-type {
				margin-bottom: 20px;
			}
		}
		h5 {
			margin-bottom: 20px;
		}
		p {
			margin-bottom: 40px;
		}
	}

	//placing of the gray "_block_image_text_cta"
	& + .image_text_cta {
		margin-top: -310px;
		margin-bottom: 50px;
		@include respond-above(md) {
			margin-top: -200px;
			margin-bottom: 120px;
		}
	}
}
