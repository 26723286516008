.container-helpdesk_link {
    margin-bottom: 120px;
    .helpdesk_link {
        &__content {
            padding: 30px 30px 80px 30px;
            border-radius: 15px 15px 0 0;
            @include respond-above(md) {
                padding: 40px 40px 65px 60px;
                border-radius: 15px 0 0 15px;
            }
            h3 {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 30px;
                padding-right: 100px;
            }
        }
        &__image {
            background-image: url(/assets/img/Illustration__8_2x.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 0 0 15px 15px;
            height: 290px;
            @include respond-above(md) {
                border-radius: 0 15px 15px 0;
                height: auto;
            }
        }
        &__buttons {
            a {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }
    }
}
