.rsbtn.rsexpanded .rsbtn_exp {
    display: inline !important;
}

.rs_addtools.mega_toggle .rsbtn_tooltoggle, .rsbtn.mega_toggle .rsbtn_tooltoggle {
    display: none !important;
}

.rs_addtools.mega_toggle .rsbtn_left, .rsbtn.mega_toggle .rsbtn_left {
    margin-left: 1em !important;
}

.rsbtn_speed.rsbtn_player_item {
    display: none;
}

.rs-listen-docreader {
    border-style: none;
    margin-left: 0.5rem;
    position: relative;
    top: -2px;
    cursor: pointer;
}