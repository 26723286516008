// ------ Underlay -------------------------------------------------------------
body{
    #CybotCookiebotDialogBodyUnderlay{
        background-color: $blue-dark-30;
    }
}

// ------ Widget ---------------------------------------------------------------
#cookiebot-widget-placement{
    z-index: 9;
    width: 74px;
    height: 62px;
    position: relative;
    padding: 6px 12px;
    margin-bottom: 15px;
    @include respond-above(md) {    
        margin-bottom: 0;
        padding: 6px 0px 12px 6px;
    }
}
#CookiebotWidget {
    margin: 0 auto;
    box-shadow: none;
    position: absolute !important;
    bottom: 6px !important;
    left: 12px !important;
    max-width: calc(100vw - 20px);
    @include respond-below(md) {
        #CookiebotWidget-widgetContent{
            transition: none !important;
        }
    }
    @include respond-above(md) {  
        position: absolute !important;  
        transform: translateY(-100%) !important;
        bottom: auto !important;
        left: auto !important;
        top: 55px !important;
    }
    &:hover{
         box-shadow: none;
    }
    &.CookiebotWidget-open{
        @include respond-below(md) {    
        position: fixed !important;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        top: 20px;
        left: 20px !important;
        z-index: 20;
        #CookiebotWidget-widgetContent{
            width: 100%;
            height: 100%;
        }
    }
    }
}

#CookiebotWidget-widgetContent{
    width: 375px;
    @include respond-above(md) {    
        width: 520px;
    }

    .CookiebotWidget-contents{
        width: 100%;
        .CookiebotWidget-header{
            border-bottom: 0;
            padding: 20px 3px 0px 20px;
            @include respond-above(md) {    
                padding: 20px 17px 14px 32px;
            }
            strong{
            @include poppins(regular);
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
            }
        }
        .CookiebotWidget-body{
            padding: 0px 20px;
            @include respond-above(md) {    
                padding: 0px 32px;
            }
            .CookiebotWidget-body-inner{
                padding: 0;
                .CookiebotWidget-consent-details{
                    .CookiebotWidget-show-consent-details{
                        padding: 0px;
                        @include respond-above(md) {    
                            padding: 0px;
                            margin-bottom: 16px;
                        }
                        @include poppins(regular);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        color: $black;
                    }
                    .CookiebotWidget-consent-details-box{
                        margin-bottom: 32px;
                    }
                }
                .CookiebotWidget-consents-list{
                    strong.CookiebotWidget-state{
                        @include poppins(regular);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        margin-bottom: 16px;
                    }
                    ul{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        li{
                            @include poppins(regular);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            margin-bottom: 8px;
                            div{
                                svg{
                                    fill: $black;
                                }
                            }
                            //Hide the marketing category on the #CookiebotWidget
                            &[data-consent="marketing"] {
                                display: none;
                            }
                        }
                    }
                    
                }
            }
        .CookiebotWidget-main-logo{
            display: none;
        }  
        }
        #CookiebotWidget-buttons{
            padding: 16px;
            display: flex;
            flex-direction: column;
            @include respond-above(md) {    
                padding: 16px 32px;
                flex-direction: row;
                justify-content: space-between;
            }
            border-top: 0;
            @include gradient_radial;
            button{
                @include poppins(regular);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                border: none;
                width: auto;
                height: auto;
                padding: 16px 18px;
                color: $black;
                display: flex;
                justify-content: center;
                &:hover{
                    opacity: 100;
                }
            }
            #CookiebotWidget-btn-withdraw{
                background-color: transparent;
                flex-direction: row;
                padding-left: 0;
                margin: 0;
                justify-content: start;
                font-size: 13px;
                font-weight: 400;
                @include respond-above(md) {    
                    padding-right: 0;
                    justify-content: center;
                }
                &::before{
                    content: "";
                    margin-right: 10px;
                    width: 18px;
                    height: 18px;
                    display: block;
                    background-image: url('/assets/img/icons/close_big.svg'); 
                    background-size: cover; 
                    background-position: center; 
                    background-repeat: no-repeat; 
                    margin-top: -1px;
                }
            }   
            #CookiebotWidget-btn-change{
                color: $black;
                background-color: transparent;
                flex-direction: row;
                padding-left: 0;
                margin-bottom: 0;
                font-size: 13px;
                font-weight: 400;
                padding-right: 0;
                justify-content: start;
                &::after{
                    content: "";
                    margin-right: 10px;
                    width: 24px;
                    height: 12px;
                    display: block;
                    background: url(/assets/img/icons/arrow.svg);
                    background-size: cover; 
                    background-position: center; 
                    background-repeat: no-repeat; 
                    margin-top: 2px;
                    margin-left: 10px
                }
            }     
        }
    }

    
}

// ------ Dialog ----------------------------------------------------------------
#CybotCookiebotDialog {    
  
    .CybotCookiebotDialogContentWrapper{
        #CybotCookiebotDialogHeader{
            display: none;
        }
        #CybotCookiebotDialogNav{
            border: none;
            .CybotCookiebotDialogNavItemLink{
                @include poppins(regular);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                background-color:$grey-2;
                color: $black;
                &.CybotCookiebotDialogActive{
                    background-color: white;
                    color: $black;
                    border: none;
                    font-weight: 600;
                }
            }
    }
    #CybotCookiebotDialogBodyLevelButtonsSelectPane{
        padding-bottom: 0;
        border-bottom: none;
        border-right: none;
        padding-left: 10px;
        padding-right: 10px;
        @include respond-above(md) {    
            padding-left: 50px;
            padding-right: 50px;
        }
        #CybotCookiebotDialogBodyFieldsetInnerContainer{
            flex-direction: column;
            @include respond-above(md) {    
                flex-direction: row;
            }
        }
        .CybotCookiebotDialogBodyLevelButtonWrapper{
            flex-direction: row;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 0;
            padding-bottom: 20px;
            width: 100%;
            justify-content: space-between;
            @include respond-above(md) {    
            width: 50%;
            justify-content: left;
            }
            
           //Hides the marketing category for the #CybotCookiebotDialog
            &:has(#CybotCookiebotDialogBodyLevelButtonMarketing) {
                display: none;
            }

            .CybotCookiebotDialogBodyLevelButtonLabel{
                @include poppins(regular);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                strong{
                    @include poppins(regular);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    &:hover{
                        color: $black;
                    }
                }
                margin: 0;
                width: 120px;
                margin-right: 17px;
                text-align: left;
            }            
        }
        .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type){
            border-right: none;
        }
    }
    .CybotCookiebotScrollContainer{
        border-bottom: none;
        padding: 40px 20px 10px 20px;
        @include respond-above(md) { 
            padding: 24px;
            }
        .CybotCookiebotScrollArea{
            padding: 0;
            @include respond-above(md) {    
                padding: 36px 16px 36px 36px;
            }
            
        }
        h2{
            @include poppins(regular);
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
        }
        #CybotCookiebotDialogBodyContentText{
            font-family: $freighttext-light;
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
        }
        .CybotCookiebotDialogTabPanel{
            font-family: $freighttext-light;
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            padding: 0;
            @include respond-above(md) {    
                padding: 30px;
            }
        }
    }
    .CybotCookiebotDialogBodyBottomWrapper{
        padding-bottom: 0px;
        @include respond-above(md) {    
            padding-bottom: 40px;
        }
    }
    #CybotCookiebotDialogFooter{
        @include gradient_radial;
        padding: 20px 20px 0px 20px;

        @media (min-width: 601px) {
            padding: 40px 0px 40px 20px;
        }
        @include respond-above(md) {    
            padding: 40px 40px 40px 60px;
        }
    }
    }
    #CybotCookiebotDialog .CookieCard{
        border-bottom: none;
    }
    #CybotCookiebotDialogDetailBody{
        
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes{
        
    .CookieCard{
        //Hides the marketing category for the #CybotCookiebotDialogTabContent
        &:has(#CybotCookiebotDialogDetailBodyContentCookieContainerAdvertisingCard) {
            display: none;
        }                   
        border: none;
        padding-top: 20px;
        padding-bottom: 20px;
        /* Remove padding-top for the first .CookieCard */
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
 
            .CybotCookiebotDialogDetailBodyContentCookieContainerHeader{
                margin-bottom: 20px;
            }
        .CybotCookiebotDialogDetailBodyContentCookieContainerButton{
            @include poppins(regular);
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1px;
            color: $black;

            &:hover{
                label, &::before, span{
                    color: $black;
                    border-color: $black;
                }
            }
            &::before{
                margin-right: 16px;
            }

            .CybotCookiebotDialogDetailBulkConsentCount{
                background-color: $grey-2;
                margin: 0;
                margin-left: 14px;
            }
        }
        .CybotCookiebotDialogDetailBodyContentCookieTypeIntro{
            font-family: $freighttext;
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
         padding: 0;
        }
        .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer{
            margin-left: 0;
        }
    }
    }
    
    .CybotCookiebotDialogDetailBodyContentCookieTabContent {
        @include poppins(regular);
        .CybotCookiebotDialogDetailBodyEmptyCategoryMessage{
            border-color: $grey-2;
            font-weight: 400;
            font-size: 13px;
        }
        .CybotCookiebotDialogDetailBodyContentCookieTypeIntro{
            font-family: $freighttext;
            padding-top: 20px;
        }
        .CybotCookiebotDialogDetailBodyContentCookieGroup {
          background-color: $grey-2;
          border: none;
          padding: 20px;
          
        .CybotCookiebotDialogDetailBodyContentCookieInfoWrapper{
            margin-top: 24px;
            &::before{
                display: none;
            }
            
            .CybotCookiebotDialogDetailBodyContentCookieProviderDescription{
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
            .CybotCookiebotDialogDetailBodyContentCookieInfoTitle{
                margin-bottom: 4px;
            }
            .CybotCookiebotDialogDetailBodyContentCookieInfo{
                background-color: $white;
                border: none;
                padding: 16px;

                .CybotCookiebotDialogDetailBodyContentCookieInfoDescription{
                    @include poppins(regular);
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }
                .CybotCookiebotDialogDetailBodyContentCookieInfoFooter{
                    border-top: none;
                    padding-top: 0;
                    .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent{
                        @include poppins(regular);
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        b{
                            @include poppins(regular);
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                        }
                        width: 100%;
                        margin: 0;
                        margin-bottom: 8px;
                        @include respond-above(md) {
                            width: 50%;
                        }
                    }
                }
            }
        }  

        .CybotCookiebotDialogDetailBodyContentCookieLink{
            color: $black;
            padding-top: 10px;
            @include poppins(regular);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            &:hover{
                color: $black;
            }   
            .CybotExternalLinkArrow{
                width: 16px;
                height: 16px;
            }     
        }
          .CybotCookiebotDialogDetailBodyContentCookieProvider {
            color: $black;
            @include poppins(regular);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            &:hover{
                color: $black;
                &::after{
                    border-color: $black;
                }
            }
            .CybotCookiebotDialogDetailBodyContentCookieInfoCount{
                background-color: $white;
            }
          }
        }
    }
    #CybotCookiebotDialogDetailFooter{
        @include poppins(regular);
        margin-top: 40px;
        padding-left: 9px;
        font-size: 13px;
        font-weight: 200;
        color: $black;
        a{
            @include poppins(regular);
            font-size: 13px;
            font-weight: 300;
            color: $black;
        }
    }
    }
}

#CybotCookiebotDialog{
#CybotCookiebotDialogFooter{

#CybotCookiebotDialogBodyButtonsWrapper{
        display: flex;
        justify-content: space-between;
        &:hover{
            cursor: pointer;
        }
#CybotCookiebotDialogBodyButtonDecline,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll{
    @include poppins(regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border: none;
    width: auto;
    height: auto;
    padding: 16px 18px;
    color: $black;
    display: flex;
    justify-content: center;
    &:hover{
        opacity: 100;
    }
    }
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll{
    border-radius: 15px;
    background-color: $white;
    order: 3;
    @media (min-width: 601px) {
        order: 1;
        margin-right: 32px;
    }
    line-height: 16px;
    flex-direction: column;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);
    width: fit-content !important;
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{
    background-color: transparent;
    order: 1;
    flex-direction: row;
    margin-right: 0;
    padding-left: 0;
    font-size: 13px;
    font-weight: 400;
    width: fit-content !important;
    margin-bottom: 1rem;
    @media (min-width: 601px) {
        order: 3;
        padding-left: 18px;
        margin-left: auto;
        margin-bottom: 0;
    }
    &::after{
        content: "";
        margin-right: 10px;
        width: 24px;
        height: 12px;
        display: block;
        background: url(/assets/img/icons/arrow.svg);
        background-size: cover; 
        background-position: center; 
        background-repeat: no-repeat; 
        margin-top: 2px;
        margin-left: 10px
    }
}

#CybotCookiebotDialogBodyButtonDecline{
    border-radius: 15px;
    background-color: $white;
    order: 2;
    line-height: 16px;
    flex-direction: column;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);
    width: fit-content !important;
    @media (min-width: 601px) {
        order: 2;
        padding-right: 0;
        padding-left: 18px;
        margin-right: 0;
        justify-content: center;
    }
}
}
}


//Styling for the toggles on the "Toestemming" tab and the "Details" tab
#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper .CybotCookiebotDialogBodyLevelButtonSliderWrapper,
    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerHeader .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    width: 44px;
    height: 24px;
    .CybotCookiebotDialogBodyLevelButtonSlider{
        background-color: $grey-3;
        box-sizing: border-box;
        &::before{
            bottom: 1px;
            height: 22px;
            left: 1px;
            top: 1px;
            width: 22px;
            overflow: hidden;
        }

    }
    // Style for checked inputs
    input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: $blue-middle;
        &::before{
            transform: translateX(19px);
        }
    }
    input.CybotCookiebotDialogBodyLevelButtonSlider {
        &:focus{
            border: 3px solid;
        }
    }
    // Style for disabled and checked inputs
    input[type="checkbox"][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: $black;
        pointer-events: none;
    }
}


