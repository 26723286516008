.article_content {
	@include flexbox();
	@include flex-direction(column);
	padding-top: 35%;
	margin-bottom: 120px;

	@include respond-below(md) {
		margin-bottom: 60px;
	}

	@include respond-above(md) {
		padding-top: 20%;
	}
	@media (min-width: 1000px) {
		padding-top: 240px;
	}

	& + .image_text_cta {
		margin-top: 0;
	}

	.article_content__body {
		@include flexbox();
		@include flex-direction(column);
		width: 100%;

		align-self: center;

		padding: 1rem;
		padding-bottom: 40px;

		@include respond-above(md) {
			padding-left: 180px !important;
			max-width: 940px;
		}
		@include respond-above(xl) {
			padding-left: 167px !important;
		}

		.article__lead {
			display: block;
			width: 100%;

			p {
				font-size: 22px;
				line-height: 38px;
				@include respond-above(md) {
					font-size: 25px;
					line-height: 40px;
					margin-bottom: 40px;
				}
			}
		}

		p {
			margin-bottom: 40px;
			@include respond-above(md) {
				font-size: 19px;
				line-height: 36px;
			}
		}

		img {
			display: block;
			width: 100%;
			border-radius: $default-radius;
		}

		figcaption {
			@include poppins(regular);
			font-size: 12px;
			line-height: 23px;
			color: $gray-darker;
			margin: 15px 0 40px 0;
		}

		blockquote {
			@include gradient_four;
			padding: 40px 50px;
			border-radius: $default-radius;
			width: 100%;
			max-width: 545px;
			font-family: $freighttext-italic;
			font-size: 21px;
			line-height: 38px;
			margin: 30px 0 70px 0;

			@include respond-above(lg) {
				padding: 40px 60px;
			}

			strong {
				@include poppins(regular);
				font-weight: 400;
				font-size: 13px;
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 3px solid $black;
				width: 100%;
				display: block;
			}
		}
	}

	.article_content__footer {
		@include flexbox();
		@include flex-direction(column);
		width: 100%;
		padding-top: 40px;
		border-top: 2px dashed $grey-2;

		@include respond-above(md) {
			margin-bottom: 50px;
		}

		p {
			line-height: normal;
		}

		a {
			text-decoration: none;
			@include poppins(regular);
			font-size: 12px;
			line-height: 12px;
			color: $gray-darker;
			margin: 0px 0 40px 0;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}
}
