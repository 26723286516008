main.search--results {
    .container-fluid {
        padding: 0;
        margin-top: -12px;
    }
    .container {
        @include respond-above(lg) {
            margin-top: 28px;
        }
    }
    .search--results--empty {
        max-width: 678px;
        margin-left: 0;
        h3 {
            margin-bottom: 33px;
            @include respond-above(lg) {
                margin: 0;
            }
        }
        @include respond-above(lg) {
            padding: 0;
        }
    }
    .search--results--wrapper {
        max-width: 940px;

        .search--form--wrapper2 {
            @include flexbox;
            @include flex-direction(column);

            background-color: $grey-2;
            top: 84px;
            width: 100%;
            padding: 40px 30px 35px 30px;
            margin-bottom: 30px;
            @include respond-above(lg) {
                display: flex;
                flex-direction: row;
                align-items: center;
                top: 90px;
                right: 166px;
                height: auto;
                padding: 34px 24px 34px 80px;
                margin-bottom: 40px;
                border-radius: 15px;
            }
            h3 {
                margin-bottom: 33px;
                @include respond-above(lg) {
                    margin: 0;
                    width: 50%;
                }
            }
            &.show {
                display: flex;
                transform: translateX(0px);
                transition: transform 0.2s $exponential;

                @include respond-above(lg) {
                    opacity: 1 !important;
                    transform: translateY(0px) !important;
                    transition: opacity 0.2s $exponential;
                }
            }

            form {
                @include respond-above(lg) {
                    width: 460px;
                }
                input[type="search"] {
                    width: 100%;
                    padding: 10px 25px;
                    border: none;
                    border-radius: 4px 0px 0px 4px;
                    background-color: $white;
                    @include poppins(regular);
                }

                button[type="submit"],
                button[type="reset"] {
                    border-radius: 0px 4px 4px 0;
                }
            }
        }

        .results {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(center);

            @include respond-above(lg) {
                @include justify-content(flex-start);
            }
            &.container {
                @include respond-above(lg) {
                    padding: 0;
                }
            }

            .result--wrapper {
                @include flexbox();
                @include flex-direction(column);
                width: 100%;
                text-decoration: none;
                background-color: $grey-2;
                border-radius: 15px;
                padding: 30px;
                position: relative;
                margin-bottom: 10px;
                color: $black;
                transform: translateY(0px);
                transition: transform 0.3s $exponential;

                h4 {
                    overflow-wrap: break-word;
                }

                p {
                    margin-bottom: 0;
                }

                @include respond-above(lg) {
                    margin-bottom: 20px;
                    width: 300px;
                    min-height: 300px;
                    margin-right: 20px;

                    &:nth-child(3n + 3) {
                        margin-right: 0;
                    }
                }

                &:after {
                    content: "";
                    right: 20px;
                    bottom: 10px;
                    position: absolute;
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    vertical-align: middle;
                    background: url(/assets/img/icons/arrow_sprite.svg) center right no-repeat;
                    background-size: 45px 45px;
                    transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
                    margin-left: 20px;

                    @include respond-above(md) {
                        height: 20px;
                        width: 40px;
                        background-size: 70px 70px;
                        background-position-x: -30px;
                        margin-left: 10px;
                    }
                }

                &:hover {
                    transform: translateY(-4px);

                    .results .result--wrapper::after {
                        background-position-x: -0px;

                        @include respond-above(lg) {
                            background-position-x: 10px;
                        }
                    }
                }
            }
        }

        .pagination--wrapper {
            margin: 20px 0 120px;

            .pagination--links {
                list-style: none;
                @include flexbox;
                @include justify-content(center);
                @include align-items(center);

                .prev--page--link,
                .next--page--link {
                    transform: translateY(0px);
                    transition: transform 0.3s $exponential;

                    &:hover {
                        transform: translateY(-4px);
                    }
                }

                .prev--page--link {
                    margin-right: 25px;
                    margin-left: 0;
                    background-image: url(/assets/img/icons/arrow_left.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 30px;
                    display: block;
                }

                .next--page--link {
                    margin-left: 25px;
                    margin-right: 0;
                    background-image: url(/assets/img/icons/arrow.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 30px;
                    display: block;
                }

                li {
                    margin: 0 10px;
                    font-size: 18px;
                    line-height: 24px;
                    @include poppins(regular);

                    &.active-page {
                        font-size: 28px;
                        line-height: 36px;
                        @include poppins(semibold);
                    }

                    a {
                        color: $black;
                    }
                }
            }
        }
    }
}
