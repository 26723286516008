// form
#form {
	background-color: $white;

	.container-progress {
		// border: 1px solid pink;
		-webkit-box-shadow: 0px 0px 12px 7px rgba(0, 0, 0, 0.48);
		box-shadow: 0px 0px 12px 7px rgba(0, 0, 0, 0.48);

		margin-bottom: 50px;
	}

	hr {
		border-bottom: 2px solid $black;
	}

	.container-blue-gradient {
		color: $black;
		position: relative;
		margin-bottom: 100px;
		.fill {
			border-top-left-radius: $default-radius;
			border-top-right-radius: $default-radius;
			//
			padding-top: 40px;
			padding-bottom: 25px;
			padding-left: 25px;
			padding-right: 25px;
			@include respond-above(lg) {
				padding-left: 60px;
				padding-right: 60px;
			}

			// bg color
			background-color: #fbfbfc;
			.h2 {
				@include poppins(medium);
				font-size: 36px;
				letter-spacing: 0;
				line-height: 48px;
			}
		}
		.gradient {
			border-bottom-right-radius: $default-radius;
			border-bottom-left-radius: $default-radius;
			//
			padding-top: 25px;
			padding-bottom: 95px;
			padding-left: 25px;
			padding-right: 25px;
			@include respond-above(lg) {
				padding-left: 60px;
				padding-right: 60px;
			}

			// bg color
			background: rgb(178, 255, 233);
			background: radial-gradient(
				circle at top right,
				rgba(178, 255, 233, 1) 0%,
				rgba(178, 255, 233, 1) 50%,
				rgba(179, 239, 254, 1) 100%
			);

			.btn-bpfd-big {
				width: 100%;
				margin-top: 10%;
				@include respond-above(lg) {
					margin-top: initial;
					width: initial;
					position: absolute;
					bottom: -35px;
					right: 60px;
				}
			}
			.btn-bpfd-small {
				width: 80%;
				margin-left: 10%;
				margin-top: 10%;
				@include respond-above(lg) {
					margin-left: initial;
					margin-top: initial;
					width: initial;
					position: absolute;
					bottom: -25px;
					right: 40%;
				}
			}
		}
	}
}

[data-freeform] {
	position: relative;

	.ff-form-errors {
		display: none;
	}

	.invalid-feedback {
		display: block !important;
	}
}

// Styling for https://fonk.ams.gitlab.host/bpfd/website/-/issues/1466
.modal {
	&.disabled {
		.intro {
			opacity: .7;
			@include respond-above(lg) {
				opacity: 1;
			}
			h5 {
				opacity: .7;
				@include respond-above(lg) {
					opacity: 1;
				}
			}
		}
		.form {
			form {
				.form-container {
					opacity: .7;
					pointer-events: none;
				}
			}
		}
		.modal-dialog {
			&.modal-dialog-centered {
				@include respond-above(lg) {
					max-width: 860px;
				}
			}
		}
		img.modal-close {
			@include respond-above(lg) {
				right: 250px;
			}
		}

		#aside-not-working {
			.content {
				position: fixed;
				top:50%;
				left: 30px;
				right: 30px;
				transform: translateY(-50%);
				min-height: 453px;
				border-radius: 15px;
				overflow: hidden;
				.img {
					padding: 10px 0;
					@include respond-above(lg) {
						padding: 3px 0 0 0;
					}
				}
				.body {
					padding: 30px;
					background-color: $pastel-red;
					@include respond-above(lg) {
						padding: 20px;
					}
					h3 {
						font-weight: 500;
						margin-bottom: 20px;
						@include respond-above(lg) {
							font-size: 15px;
							line-height: 18px;
							font-weight: 600;
							margin-bottom: 10px;
						}
					}
					p {
						&:last-child {
							margin-bottom: 0;
						}
						@include respond-above(lg) {
							font-size: 16px;
							line-height: 21px;
						}
					}
				}
				@include respond-above(lg) {
					position: sticky;
					top:0;
					transform: none;
					min-height: inherit;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}

.form-buttons-separator {
	margin-top: 30px;
	margin-bottom: 45px;
}

.form-buttons-separator-disabled {
	.form-buttons-separator-container {
		display: none !important;
	}
}

.form-pagination-disabled {
	.section-progress {
		display: none !important;
	}
}