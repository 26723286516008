// _block_left_aligned_image_text.scss
.left_image_text {
	@include flexbox();
	@include flex-direction(column);
	width:100%;
	margin-bottom:60px;

	.left_image_text__container {
		max-width:940px;
		margin:0 auto;

		@include respond-above(md) {
			padding:0px !important;
		}

		.left_image_text__wrapper {
			@include flexbox();
			@include flex-direction(column);
			width:100%;
			border:1px solid $black;
			border-radius:$default-radius;
			position: relative;
			overflow: hidden;

			@include respond-above(md) {
				@include flex-direction(row);
				border:2px solid $black;
				margin-bottom: 31px;
			}
	
			.left_image_text__image {
				min-height:282px;
				width:100%;
				background-size:cover;
				background-repeat: no-repeat;
				background-position: center center;
				border-radius:15px 15px 0 0;

				@include respond-above(md) {
					min-height:358px;
					max-height:100%;
					max-width:300px;
					border-radius:15px 0 0 15px;
				}
			}
	
			.left_image_text__header {
				@include flexbox();
				@include flex-direction(column);
				width:100%;
				padding:30px;
				position: relative;

				@include respond-above(md) {
					padding:45px 45px 40px 45px;
				}
	
				.left_image_text__title {
					margin-bottom:10px;
					@include respond-above(md) {
						margin-bottom:15px;
					}
				}

				.left_image_text__body {
					padding-bottom:0px;

					&:first-of-type {
						@include respond-above(md) {
							margin-bottom:25px;
						}
					}

					.btn__secondary {
						text-align: left;
						white-space: normal;

						@include respond-above(md) {
							white-space: nowrap;
						}
					}
					
					
					@include respond-above(md) {
						
						margin-bottom:8px;
					}
				}
			}
	
			&.linked--card {
				cursor: pointer;
			
				&:after {
					content: "";
					display: inline-block;
					width:33px;
					height:17px;
					position: absolute;
					bottom: 22px;
					right: 20px;
					vertical-align: middle;
					background: url("/assets/img/icons/arrow_sprite_bold.svg") center right no-repeat;
					background-size: 80px;
					background-position-x: -47px;
					transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
					margin-left: 10px;
	
					@include respond-above(md) {
						width:33px;
						height:17px;
						background-position-x: -47px;
					}
					
				}
	
				&:hover {
					&:after {
						background-position-x: 0;
					}
				}
			}
		
	
		}
	

	}

	&+ .image_text_cta {
		
		@include respond-above(lg) {
			margin-top:160px;
		}
		
	}
}


