#helpdesk-detail {
	.helpdesk-audience-phone {
		margin-bottom: 0;
		margin-top: 0;
		@include respond-above(md) {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
	.springboards {
		margin-bottom: 60px;
		@include respond-above(md) {
			margin-bottom: 120px;
		}
		.springboard__link {
			flex-grow: 1;
		}
		.springboard__header {
			background-color: $white;
			min-height: 120px;
			padding: 20px;
			h5 {
				font-size: 18px;
				line-height: 24px;
				@include poppins(regular);
			}
		}
	}

	@include respond-above(lg) {
		.hero_video_cta__container,
		.back-button-header {
			max-width: 1140px;
		}

		.hero_video_cta__container {
			padding-right: 15px !important;
			padding-left: 15px !important;
		}
	}
}
