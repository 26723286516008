// _block_content_with_nav_feedbak.twig
.feedback-thumbs-element {
	.hide {
		display: none;
	}
	.content-feedback {
		background-color: transparent;
		border-radius: 0;
		h5 {
			margin-bottom: 20px;
		}

		.content-feedback__buttons {
			display: flex;
			justify-content: center;
			padding-bottom: 30px;

			.btn__feedback {
				margin: 0px 13px 0 13px;
			}
		}

		&__cta {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-self: center;
			padding-bottom: 20px;
			a {
				display: inline-flex;
				align-self: center;
				margin-right: 30px;
			}
		}
		.form-group {
			margin-top: 20px;
			margin-bottom: 0;
		}
	}
}
