.intro-form,
#intro-contact-form {
	background-color: $grey-1;
	border-radius: $default-radius $default-radius 0 0;
	padding: 2.5rem 1.875rem 2.5rem 1.875rem;
	@include respond-above(md) {
		padding: 2.5rem 3.75rem 2.5rem 3.75rem;
	}
	h5 {
		font-size: 21px;
		line-height: 30px;
		padding-bottom: 1.375rem;
	}
	p {
		margin-bottom: 0;
	}
}

.section-form,
#section-contact-form {
	.form-label.set-form-body {
		margin-bottom: 40px;
	}
	//styling of the form itself
	@include gradient_radial;
	border-radius: 0 0 $default-radius $default-radius;
	padding: 2.5rem 1.875rem 2.5rem 1.875rem;
	@include respond-above(md) {
		padding: 3.75rem 3.75rem 3.75rem 3.75rem;
	}

	//stying for the input fields including placeholders and states
	input {
		@include poppins(regular);
		padding: 25px;

		&:not(#akkoord) {
			height: 3.125rem;
		}

		&::placeholder {
			color: $gray-darker;
		}

		&:hover {
			background: #f4eeee;
		}
		&.bg__white {
			&:hover {
				background: white;
			}
		}

		&:focus {
			background-color: white;
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
		}

		border-radius: 0.3125rem;
		border: none;
	}

	//form groups contain the label, input field and error message
	.form-group {
		margin-bottom: 2.8125rem;

		//general styling for the input fields
		.form-control {
			color: $black;
			font-size: 21px;
		}

		//max widths of the input fields and error message underneath
		&-firstname,
		&-lastname,
		&-phonenumber,
		&-companyname {
			max-width: 20rem;
		}
		&-email {
			max-width: 25rem;
		}
		&-pensionnumber,
		&-relatienummer {
			input {
				max-width: 9.625rem;
			}
		}

		&-postalcode,
		&-housenumber,
		&-housenumber-addition,
		&-inline {
			max-width: 7.5rem;
			white-space: pre-wrap;
			//placing smaller input fields next to each other
			flex-direction: column;
			display: flex;
			@include respond-above(md) {
				display: inline-flex;
			}
			input {
				text-align: center;
				padding: 0;
			}
		}
		&-inline {
			select {
				width: 120%;
			}
			& + .form-group-inline {
				margin-left: 1rem;
				@include respond-below(md) {
					margin-left: 0;
				}
				@include respond-above(md) {
					margin-left: 2.5rem;
				}
			}
		}
		&-plaats {
			input {
				text-align: left;
				padding-left: 25px;
				width: 125%;
			}
		}

		// Adding margin for spacing between the smaller input fields
		&-housenumber-addition {
			@include respond-below(md) {
				display: inline-flex;
			}
			margin-left: 1rem;
			@media (max-width: 370px) {
				margin-left: 0;
			}
			@include respond-above(md) {
				margin-left: 2.5rem;
			}
		}

		&-housenumber {
			@include respond-below(md) {
				display: inline-flex;
			}
			@include respond-above(md) {
				margin-left: 2.5rem;
			}
		}

		//styling for the dropdown field
		&.form-group-select {
			.custom-select {
				background: $white url(/assets/img/icons/chevron-blue.svg) no-repeat right 1.5rem center/14px 20px;
			}
			margin-bottom: 1.875rem;
			select {
				&:hover {
					background-color: #f4eeee;
				}
				option {
					background: $white;
				}
			}
			& #subject {
				font-size: 18px;
				color: $gray-darker;
				& option {
					color: $black;
				}
			}
		}

		//styling for the radio buttons
		&.form-group-usertype {
			margin-bottom: 3.75rem;
			.form-label {
				margin-bottom: 1.25rem;
			}
		}

		//Different margin for underneath the checkbox and the question field
		&.form-group-checkbox,
		&.form-group-message {
			margin-bottom: 2.5rem;
		}

		&.form-group-checkbox {
			.form-label {
				@include poppins(regular);
			}
		}

		label,
		textarea,
		select {
			border-radius: 0.3125rem;
			border: none;
		}

		textarea,
		select {
			@include poppins(regular);
		}

		label {
			@include poppins(light);
			#privacy-checkbox {
				display: inline;
			}
		}

		select {
			height: 3.125rem;
			padding-left: 25px;
			&:focus {
				background-color: white;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
			}
		}
		textarea {
			resize: none;
			padding: 25px;

			@include respond-below(lg) {
				height: 280px;
			}
			&::placeholder {
				color: $gray-darker;
			}
			&:hover {
				background: #f4eeee;
			}
			&:focus {
				background-color: white;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
			}
		}

		//placing the labels on top of the input fields
		&#postalcode,
		&#housenumber,
		&#housenumber-addition {
			display: inline-block;
			&label,
			&input {
				display: block;
			}
		}

		//when a field is invalid, add the is-invalid class to add a red border arround it
		.is-invalid {
			border: 2px solid $error-red;
			background-image: none;
		}

		//when a field is invalid, add error-msg to the class to show the error message in red
		.error-msg {
			color: $error-red;
			font-size: 12px;
			line-height: 23px;
			text-align: right;
			padding-top: 0.3125rem;
			//when error message is hidden, display none so it doesn't take up any height
			&.hidden {
				display: none;
			}
		}
	}

	//styling of the information on the required fields including the black line
	.required-info {
		@include poppins(light);
		text-align: right;
		&::before {
			content: "";
			border-bottom: 2px solid #000000;
			width: 100%;
			display: block;
			margin-bottom: 1.25rem;
		}
	}

	//send form button placing on the bottom and right of the form
	.send-form {
		transform: translateY(70px);
		text-align: right;
	}

	.send-freeform {
		transform: translateY(20px);
		text-align: right;
	}

	//checkbox custom styling
	.checkbox {
		& input[type="checkbox"] {
			opacity: 0;
		}

		& label {
			font-size: 13px;
			position: relative;
			display: inline-block;
			border: none;

			/*16px width of fake checkbox + 6px distance between fake checkbox and text*/
			padding-left: 47px;
			@include respond-above(sm) {
				padding-left: 22px;
			}
			cursor: pointer;

			& a {
				text-decoration: underline;
				color: $black;
			}
		}

		& label::before,
		& label::after {
			position: absolute;
			content: "";

			/*Needed for the line-height to take effect*/
			display: inline-block;
			@include shadow($shallow);
		}

		/*Outer box of the fake checkbox*/
		& label::before {
			height: 30px;
			width: 30px;
			border: none;
			top: -3px;
			background: $white;
			border-radius: 4px;
			left: 0;
			@include respond-above(sm) {
				left: -17px;
			}
		}

		/*Checkmark of the fake checkbox*/
		& label::after {
			background: url(/assets/img/icons/checkmark-white.svg) center no-repeat $blue;
			height: 30px;
			width: 30px;
			border: none;
			top: -3px;
			border-radius: 4px;
			left: 0;
			@include respond-above(sm) {
				left: -17px;
			}
		}

		/*Hide the checkmark by default*/
		& input[type="checkbox"] + label::after {
			content: none;
		}

		/*Unhide on the checked state*/
		& input[type="checkbox"]:checked + label::after {
			content: "";
		}

		/*Adding focus styles on the outer-box of the fake checkbox*/
		& input[type="checkbox"]:focus + label::before {
			outline: $white auto 5px;
		}

		//styling for error state/class
		&.is-invalid {
			border: none;
			label {
				color: $error-red;
				& a {
					text-decoration: underline;
					color: $error-red;
				}
			}
			label::before {
				border: 2px solid $error-red;
				border-radius: 4px;
			}
		}
	}
	.form-buttons,
	.contact-form-buttons {
		a {
			margin-right: 20px;
			margin-bottom: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
	}

	#contact-form- {
		&deelnemer,
		&werkgever {
			display: none;
		}
	}
}

.radio-style {
	@include poppins(medium);

	&.is-invalid {
		border: none;
		.radio-style {
			.radio-style__radio-button {
				border: 2px solid $error-red;
				background-image: none;
			}
		}
	}

	&__radio-group {
		margin-bottom: 1.375rem;
	}
	&__radio-input {
		display: none;
	}

	&__radio-label {
		cursor: pointer;
		position: relative;
		padding-left: 3.125rem;
		line-height: 32px;
		transition: all 0.2s;
		margin-bottom: 0;
	}

	&__radio-button {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
		height: 1.875rem;
		width: 1.875rem;
		border-radius: 0.9375rem;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 6px;
		@include respond-above(md) {
			top: 1px;
		}
		background-color: white;

		&::after {
			content: "";
			display: block;
			height: 1.25rem;
			width: 1.25rem;
			border-radius: 0.9375rem;
			background-color: $blue;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: opacity 0.2s;
		}
	}

	&__radio-input:checked ~ &__radio-label &__radio-button::after {
		opacity: 1;
	}
}

input[name="hpwebsite"] {
	display: none;
}

.grecaptcha-badge {
	visibility: hidden;
}

img.modal-close {
	cursor: pointer;
	position: absolute;
	top: 30px;
	right: 45px;
	z-index: 30;
}

.form-buttons-separator {
	height: 2px;
	background: black;
	width: 100%;
}