.container-date {
    overflow: hidden;

    .date_link {
        .date_arrow {
            height: 20px;
            width: 30px;
            display: inline-block;
            position: relative;
            &:after {
                content: "";
                right: 0;
                position: absolute;
                display: inline-block;
                height: 20px;
                width: 20px;
                vertical-align: middle;
                background-image: url(/assets/img/icons/arrow_sprite.svg);
                background-position: center right;
                background-repeat: no-repeat;
                background-size: 45px;
                transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
                margin-left: 20px;

                @include respond-above(md) {
                    width: 30px;
                    background-size: 55px;
                    background-position-x: -27px;
                }
            }

            @include respond-above(md) {
                &:hover {
                    text-decoration: none;
                    color: inherit;

                    &:after {
                        background-position-x: -0px;

                        @include respond-above(lg) {
                            background-position-x: 5px;
                        }
                    }
                }
            }
        }
    }

    .date_image {
        @include respond-above(lg) {
            transform: translateY(40px);
        }
    }
}
