.image_text__image {
	display: inline-block;
	position: relative;
}

#videoModal {
	.modal-header {
		padding: 1rem 2rem 0.5rem 2rem;
	}
	.modal-body {
		padding: 0.5rem;
	}
	#video {
		width: 100%;
		height: 340px;
		@include respond-below(md) {
			height: 210px;
		}
	}
}
