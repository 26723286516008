.info_button_welke {
    position: relative;

    &__button {

        .letop_more_info {
            height: 30px;
            margin-bottom: 40px;   
            margin-top: -20px;    
            font-weight: 400;         
            p {
                color: $black;
                text-decoration: underline;
            }
        }

        &__tooltip_big,
        &__tooltip_letop {
            opacity: 1 !important;
            .tooltip-inner {
                max-width: unset;
                padding: unset;
                color: unset;
                text-align: unset;
                background-color: unset;
                border-radius: unset;
            }
            .arrow {
                width: 40px;
                height: 20px;
            }
            &.bs-tooltip-auto[x-placement^=top] .arrow,
            &.bs-tooltip-top .arrow {
                bottom: -20px;
                &:before {
                    border-top-color: $grey-2;
                    border-width: 20px 20px 0;
                }
            }
            &.bs-tooltip-auto[x-placement^=bottom] .arrow,
            &.bs-tooltip-bottom .arrow {
                top: -20px;
                &:before {
                    border-bottom-color: $grey-2;
                    border-width: 0 20px 20px;
                }
            }
            &.bs-tooltip-auto[x-placement^=left] .arrow,
            &.bs-tooltip-left .arrow {
                right: -35px;
                width: 20px;
                height: 40px;
                &:before {
                    border-left-color: $grey-2;
                    border-width: 20px 0 20px 20px;
                }
            }
            &.bs-tooltip-auto[x-placement^=right] .arrow,
            &.bs-tooltip-right .arrow {
                left: -35px;
                width: 20px;
                height: 40px;
                &:before {
                    border-right-color: $grey-2;
                    border-width: 20px 20px 20px 0;
                }
            }

        }
        &__tooltip_letop {
            &.bs-tooltip-auto[x-placement^=top] .arrow,
            &.bs-tooltip-top .arrow {
                &:before {
                    border-top-color: $yellow;
                }
            }
            &.bs-tooltip-auto[x-placement^=bottom] .arrow,
            &.bs-tooltip-bottom .arrow {
                &:before {
                    border-bottom-color: $yellow;
                }
            }
            &.bs-tooltip-auto[x-placement^=left] .arrow,
            &.bs-tooltip-left .arrow {
                &:before {
                    border-left-color: $yellow;
                }
            }
            &.bs-tooltip-auto[x-placement^=right] .arrow,
            &.bs-tooltip-right .arrow {
                &:before {
                    border-right-color: $yellow;
                }
            }
        }


        &__tooltip_big {
            position: absolute;
			background-color: $grey-2;
            padding: 0;
            border-radius: 15px;
            box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            z-index: 5;
            margin-left: -15px;
            width: 80vw;
            @include respond-above(md) {
                width: 780px;
                margin-left: unset;
            }

            > .row {
                border-radius: 15px;
                position: relative;
                z-index: 10;
                background-color: $grey-2;
            }

            .tooltip__pointer {
                left: calc(82% - 20px);
                background: $gray-1;
                margin: -14px auto 0;
                margin-left: 9px;

                @include respond-above(md) {
                    background: $gray-2;
                    left: calc(43% - 20px);
                }
            }

            .tooltip_big {
                background: url(/assets/img/image.png), $gray-1;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                border-radius: 0 15px 15px 0;
                height: 218px;
                @include respond-above(md) {
                    border-radius: 15px 0 0 15px;
                    height: 100%;
                }
            }

            .tooltip_big__close {
                top: 40px;
                right: 55px;
                position: absolute;
                cursor: pointer;
            }
        }

        &__tooltip_letop {
            position: absolute;
            margin-top: 10px;
            padding: 0;
            border-radius: 15px;
            box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.2);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            z-index: 5;
            margin-left: -15px;
            width: 80vw;
            @include respond-above(md) {
                width: 100%;
                margin-left: unset;
            }

            > .row {
                border-radius: 15px;
                position: relative;
                z-index: 10;
                background-color: $yellow;
            }

            .tooltip__pointer {
                top: 1%;
                left: calc(12% - 20px);
                background: $navaro-white;
                margin: -16px auto 0;

                @include respond-above(md) {
                    top: initial;
                    bottom: -18px;
                    left: calc(39% - 20px);
                    background: $yellow;
                }
            }

            @include respond-above(md) {
                width: 780px;
            }

            .tooltip_letop {
                background: url(/assets/img/letop_image.png), $navaro-white;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                border-radius: 15px 15px 0 0;
                height: 203px;
                @include respond-above(md) {
                    border-radius: 15px 0 0 15px;
                    height: 100%;
                }
            }

             .letop__close {
                top: 30px;
                right: 30px;
                position: absolute;
                cursor: pointer;
                 @include respond-above(md) {
                     top: 40px;
                     right: 40px;
                 }
            }
        }

        span {
            position: absolute;
            top: 60%;
            right: 0%;
            margin-top: 14px;
            padding: 10px 20px;
            width: 280px;
            border-radius: 15px;
            box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);
            pointer-events: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            opacity: 0;
            z-index: 10;
        }
        @include respond-above(md) {
            &:hover {
                span {
                    top: 100%;
                    opacity: 1;
                }
            }

            &:hover {
                span {
                    top: 100%;
                    opacity: 1;
                }
            }
        }
    }

}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(179, 239, 254, 0.50);
}