.tab-link-block {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@media (min-width: 992px) {
		z-index: 5;
		position: absolute;
		top: 0;
		right: 0;
	}
}
.tab-link {
	height: auto;
	border-radius: 15px 0px 0px 15px;
	width: 18rem;
	transform: translateY(-60px);
	margin-bottom: 60px;

	@include respond-above(sm) {
		width: 60vw;
	}
	@media (min-width: 992px) {
		transform: translateY(0px);
		width: 13.75rem;
		margin-bottom: 0;
	}
	@include respond-above(xxl) {
		width: 15.625rem;
	}

	&__image {
		height: 160px;
		width: 100%;
		span {
			background-size: cover;
			background-position: center center;
			background-repeat: none;
		}
	}
	a {
		color: inherit;
		.tab-link__title {
			font-size: 20px;
			line-height: 26px;
		}
		&:hover {
			text-decoration: none;
			cursor: pointer;
			.tab-link__caption {
				text-decoration: none !important;
			}
			.tab-link__title {
				text-decoration: underline;
			}
		}
	}
}
