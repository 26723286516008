.fontsize-widget,
.readspeaker-widget {
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 20;
  width: 50%;
  @include respond-above(lg) {
    top: 258px;
    left: 0;
    z-index: 9;
    width: auto;
  }
}

.readspeaker-widget {
  button {
    width: 100%;
    height: 40px;
    border: none;
    outline: inherit;
    padding: 0;
    @include respond-above(lg) {
      width: 80px;
      height: 84px;
      border-radius: 0px 10px 10px 0px !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  span {
    font-size: 0.8125rem;
    line-height: 1.4375rem;
  }
}

.fontsize-widget {
  height: 40px;
  position: fixed;
  right: 0;
  .fs-start {
    display: flex;
    @include respond-below(lg) {
      span {
        line-height: 40px;
      }
    }
  }
  @include respond-above(lg) {
    top: 348px;
    right: auto;
    height: 80px;
    width: 80px;
    .fs-start,
    .fs-full {
      border-radius: 0px 10px 10px 0px !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 84px;
    }
    .fs-start {

    }
    .fs-full {
      width: 180px;
    }
  }
  .position-absolute {
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
  span.fs-init {
    top: auto;
    bottom: -70px;
    left: 25px;
    pointer-events: none;
  }
  .fs-toggle-ui:not(.fs-init) {
    right: 50%;
  }
  > *:not(.fs-init) {
    display: none;
  }
  .fs-full {
    .fs-toggle-ui {
      top: 4px;
      padding: 27px;
    }
    .fs-toggle-size {
      right: 0;
      left: 49%;
      top: 0;
      padding-top: 10px;
      user-select: none;

      & > * {
        display: inline-block;
        vertical-align: bottom;

        &:nth-child(2) {
          height: 20px;
        }

        &:nth-child(3) {
          height: 24px;
        }

        &:not(.selected) {
          opacity: 0.3;
        }
      }
    }
    span {
      pointer-events: none;
      right: 37px;
      bottom: 12px;
      display: block;
      top: auto;
      width: auto;
      left: auto;
    }
    @include respond-below(lg) {
      height: 160px;
      right: 0;
      border-radius: 0 0 10px 10px !important;
      .fs-toggle-ui {
        padding: 11px calc(50% - 9px);
        top: 0;
      }
      .fs-toggle-size {
        left: 0;
        text-align: center;
        top: 40px;
        padding-top: 15px;
        & > * {
          padding: 0 4%;
        }
      }
      span {
        bottom: 29px;
        right: 0;
        left: 0;
        text-align: center;
      }
    }
  }
}
