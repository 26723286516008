.dekkinsgraad_text {
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(space-between);
	width: 100%;
	margin-top: 3rem;
	padding-bottom: 60px;

	@include respond-above(md) {
		padding-bottom: 0;
	}

	&__wrapper {
		@include flexbox();
		@include flex-direction(column);
		width: 100%;
		border-radius: $default-radius;
		padding-top: 90px;
		padding-bottom: 228px;
		&--gray {
			background: $gray-1;
		}
	}
	&__text {
		max-width: 700px;
		@include respond-above(lg) {
			width: 100%;
			margin-left: 15%;
		}
		h2 {
			padding-bottom: 30px;
		}
		h3 {
			padding-bottom: 20px;
		}
	}
}
