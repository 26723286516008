.main_nav {
    position: fixed;
    z-index: 10;
    background-color: $white;
    width: 100%;
    top: 0;
    left: 0;
    @include flexbox();
    @include flex-direction(column);

    border-bottom: 1px dashed $gray;

    transition: box-shadow 0.3s ease-in-out;

    //this is a separate class because the class will get removed with JS
    //in the case of the page containing a breadcrumb bar
    &.box-shadow {
        @include shadow($shallow);
    }
}

//navbar
.navbar {
    padding: 19px 30px;
    background-color: $white;
    z-index: 6;
    @include shadow($shallow);
    height: 100%;

    &.navbar--bg__grey--1 {
        background-color: $grey-1;
    }

    &.navbar--bg__grey--2 {
        background-color: $grey-2;
    }
    @include respond-above(lg) {
        padding: 0 0 0 20px;
        margin-right: 166px;
        @include justify-content(space-between);
        box-shadow: none;
    }

    @include respond-above(xl) {
        padding: 0 0 0 15px;
        max-width: 1320px;
        width: 100%;
        margin: 0 auto;
    }

    //small tweaks for alignment logo and breadrcumbs
    @media (min-width: 1200px) {
        padding-left: 15px;
    }
    @media (min-width: 1350px) {
        padding-left: 0;
    }

    @include respond-above(xxl) {
        padding: 0;
    }

    //brand
    .navbar-brand {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);

        @include respond-above(lg) {
            height: 80px;
        }

        img {
            width: 160px;
            height: 40px;

            @include respond-above(lg) {
                width: 130px;
                height: auto;
                transition: transform 0.3s $exponential;

                &:hover {
                    transform: translateY(-2px);
                }
            }
            @include respond-above(xl) {
                width: 160px;
                height: 40px;
            }
        }
    }

    .navbar-toggler-menu {
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        gap: 28px;

        

        .aside_menu__nav-item--search {
            display: none;
            @include flex-grow(1);
            @include justify-content(center);
            @include align-items(center);
            @include poppins(regular);
            text-decoration: none;
            width: 100%;
            user-select: none;

            @media (max-width: 990px) {
                @include flexbox();
            }

            .aside_menu__text,
            .aside_menu__icon {
                opacity: 1;
                transform: translateY(0px);
                transition: all 0.2s $exponential;
            }

            .aside_menu__text {
                color: $black;
                font-size: 13px;
                margin-right: 11px;

                @include respond-above(lg) {
                    margin-right: 0;
                }
            }

            .aside_menu__icon {
                display: block;
            }

            //close icon {
            .aside_menu__close {
                display: block;
                top: 33px;
                align-self: center;
                position: absolute;
                opacity: 0;
                transform: translateY(30px);
                transition: opacity 0.5s $exponential, transform 0.3s $exponential;
            }

            &.aside_menu__nav-item--close {
                .aside_menu__text,
                .aside_menu__icon {
                    transform: translateY(60px);
                    opacity: 0;
                    visibility: hidden;
                }

                .aside_menu__close {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
   
    }
    //navbar toggle
    .navbar-toggler {
        padding: 0;
        width: 20px;
        height: 18px;
        border-radius: 0;
        border: none;
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(center);

        @include respond-above(lg) {
            display: none;
        }

        &[aria-expanded="true"] {
            .bar--1 {
                transform: rotate(45deg) translateY(7px) translateX(5px);
                width: 24px;
            }

            .bar--2 {
                transform: rotate(405deg);
                opacity: 0;
            }

            .bar--3 {
                transform: rotate(-45deg) translateY(-6px) translateX(4px);
                width: 24px;
            }
        }

        .bar {
            display: block;
            width: 20px;
            height: 2px;
            background-color: $blue-middle;
            transition: transform 0.3s $exponential, opacity 0.3s $exponential;
        }
    }

    //navbar
    .navbar-collapse {
        transition: all 0.2s $exponential;

        @include respond-above(lg) {
            @include justify-content(start);
        }

        &.show {
            min-height: 100vh;

            @media (min-width: 990px) {
                min-height: auto;
            }
        }

        .navbar-nav {
            @include respond-above(lg) {
                @include flex-direction(row);
                @include flex-wrap(wrap);
                margin-left: 0%;
            }

            @include respond-above(xl) {
                @include flex-wrap(nowrap);
                @include justify-content(center);
            }

            @media (min-width: 1489px) {
                margin-left: 7%;
            }

            .nav-item {
                position: relative;
                z-index: 2;
                width: auto;

                @include respond-above(xl) {
                    width: 34%;
                }
                @include respond-above(xl) {
                    width: 34%;
                    text-align: center;
                    margin: 0 0rem;
                }
                @include respond-above(xxl) {
                    margin: 0 1rem;
                }

                .nav-link-last {
                    color: $blue-darker !important;
                }

                .nav-link {
                    font-size: 24px;
                    padding: 20px 0;
                    border-bottom: none;
                    @include poppins(medium);
                    color: $black;

                    @include respond-above(lg) {
                        font-size: 13px;
                        border-bottom: none;
                        color: $blue-darker;
                        padding: 8px 0;
                        white-space: nowrap;
                        margin-right: 3px;
                        border-radius: 15px;
                        transition: color 0.3s $exponential, background-color 0.3s $exponential,
                            transform 0.1s $exponential;
                    }

                    @include respond-above(xl) {
                        font-size: 16px;
                        padding: 8px 15px;
                        margin-right: 4px;
                    }

                    @media (min-width: 992px) {
                        padding: 3px 5px;
                        border-radius: 12px;
                        width: fit-content;
                    }

                    @media (min-width: 1350px) {
                        padding: 8px 15px;
                    }

                    &.active,
                    &:hover,
                    &:focus {
                        @include respond-above(lg) {
                            background-color: $grey-2;
                            color: $black;
                            transform: translateY(-2px);
                        }
                    }

                    &--secondary {
                        position: absolute;
                        border-radius: 12px;
                        background-color: $blue-light-two;
                        color: $black;
                        padding: 10px 20px;
                        border: 0;
                        margin-top: 20px;

                        @include respond-above(lg) {
                            margin-top: 0;
                            background-color: transparent;
                            border-radius: 12px;
                        }
                        @media (min-width: 992px) {
                            padding: 3px 5px;
                            border-radius: 12px;
                            width: fit-content;
                        }

                        @media (min-width: 1350px) {
                            padding: 8px 15px;
                        }

                        &.active,
                        &:hover,
                        &:focus {
                            @include respond-above(lg) {
                                background-color: $blue-light-two;
                            }
                        }
                    }

                    &--none {
                        display: block;

                        @media (min-width: 990px) {
                            display: none;
                        }
                    }

                    &--block {
                        display: none;

                        @media (min-width: 990px) {
                            display: block;
                        }
                    }
                }

                .menu_list__wrapper {
                    @include respond-above(lg) {
                        display: none;
                    }

                    .menu_list {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;

                        .menu_list__item {
                            .btn {
                                padding-top: 15px;
                                padding-right: 0;
                                padding-bottom: 15px;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
            .dropdown {
                &:hover > .dropdown-menu {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }

        .aside_menu__nav-item--search {
            display: none;
            position: absolute;
            bottom: 148px;
            left: 0;
            right: 0;
            background-color: $grey-2;
            @include flex-grow(1);
            @include justify-content(center);
            @include align-items(center);
            @include poppins(regular);
            padding: 15px;
            text-decoration: none;
            width: 100%;
            user-select: none;

            @media (max-width: 990px) {
                @include flexbox();
            }

            .aside_menu__text,
            .aside_menu__icon {
                opacity: 1;
                transform: translateY(0px);
                transition: all 0.2s $exponential;
            }

            .aside_menu__text {
                color: $black;
                font-size: 13px;
                margin-right: 11px;

                @include respond-above(lg) {
                    margin-right: 0;
                }
            }

            .aside_menu__icon {
                display: block;
            }

            //close icon {
            .aside_menu__close {
                display: block;
                align-self: center;
                position: absolute;
                opacity: 0;
                transform: translateY(30px);
                transition: opacity 0.5s $exponential, transform 0.3s $exponential;
            }

            &.aside_menu__nav-item--close {
                .aside_menu__text,
                .aside_menu__icon {
                    transform: translateY(60px);
                    opacity: 0;
                    visibility: hidden;
                }

                .aside_menu__close {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
    }

    //aside
    .aside_menu {
        @include flexbox();
        position: fixed;
        z-index: 5;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;

        @media (min-width: 992px) {
            position: fixed;
            z-index: 9;
            left: auto;
            right: 0;
            height: 80px;
            top: 0;
            width: auto;
        }

        .aside_menu__nav {
            @include flexbox();
            width: 100%;

            @media (max-width: 990px) {
                @include flex-direction(column);
            }

            .mobile--row {
                @include flexbox();
                width: 100%;
                z-index: 20;
            }

            .aside_menu__nav-item {
                min-width: 80px;

                cursor: pointer;
                width: 50%;
                position: relative;
                user-select: none;

                @include respond-above(lg) {
                    min-width: 70px;
                    max-width: 70px;
                }
                @include respond-above(xl) {
                    min-width: 80px;
                    max-width: 80px;
                }
                a {
                    @include flexbox();
                    @include flex-grow(1);
                    @include justify-content(center);
                    @include align-items(center);
                    @include poppins(regular);
                    padding: 15px;

                    text-decoration: none;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    &:hover {
                        text-decoration: none;
                    }
                    @include respond-above(lg) {
                        @include flex-direction(column-reverse);
                        padding: 20px 15px 10px 15px;
                        &:hover {
                            cursor: pointer;
                            .aside_menu__icon {
                                transform: translateY(-3px);
                            }
                        }
                    }
                }

                &--search,
                &--contact,
                &--login,
                &--language {
                    @include flexbox();

                    &.aside_menu__nav-item--close {
                        .aside_menu__text,
                        .aside_menu__icon {
                            transform: translateY(60px);
                            opacity: 0;
                            visibility: hidden;
                        }

                        .aside_menu__close {
                            opacity: 1;
                            transform: translateY(0px);
                        }
                    }
                }

                &--search,
                &--language {
                    transition: all 0.2s $exponential;

                    @media (max-width: 990px) {
                        width: 50%;
                        transform: translateY(60px);

                        &.show {
                            transform: translateY(0px);
                        }
                    }

                    @include respond-below(md) { 
                        display: none;
                    }
                }
                &--search {
                    background-color: $grey-2;
                    @include respond-below(lg) {
                        //place search button on the left side of the language button on mobile
                        order: 0;
                    }
                }

                &--language {
                    background-color: $grey-1;
                    @include respond-below(lg) {
                        //place language button on the right side of the search button on mobile
                        order: 1;
                    }
                }

                &--contact {
                    background-color: $yellow;
                }

                &--login {
                    background-color: $blue-light;
                }

                .aside_menu__text,
                .aside_menu__icon {
                    opacity: 1;
                    transform: translateY(0px);
                    transition: all 0.2s $exponential;
                }

                .aside_menu__text {
                    color: $black;
                    font-size: 13px;
                    line-height: 23px;
                    margin-right: 11px;

                    @include respond-above(lg) {
                        margin-right: 0;
                    }
                }

                .aside_menu__icon {
                    display: block;
                    max-height: 20px;
                }

                //close icon {
                .aside_menu__close {
                    display: block;
                    align-self: center;
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    opacity: 0;
                    transform: translateY(30px);
                    transition: opacity 0.5s $exponential, transform 0.3s $exponential;
                }
            }
        }
    }
}

//Custom dropdown menu
#custom-dropdown-menu {
    position: absolute;
    width: 100%;
    z-index: 2;
    transform: translateY(-400px);
    transition: all 0.6s ease-out;
    line-height: 23px;
    &.show {
        transform: translateY(0px);
    }

    padding: 60px 0 60px 0;
    background-color: $grey-1;
    border-bottom: 1px dashed $gray;

    @include shadow($shallow-down);

    @include respond-below(lg) {
        display: none;
    }

    .custom-dropdown-menu__container {
        max-width: 968px;
        margin: 0 auto;
    }

    .custom-dropdown-menu__title {
        margin-bottom: 30px;
    }
    .regular-items-container {
        display: flex;
        flex-wrap: wrap;
        @include respond-below(xl) {
            flex: 0 0 50%;
        }

        .dropdown-item {
            flex: 0 0 50%;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 3px;
            padding-top: 3px;
        }
    }
    .green-items-container {
        background-color: $mint;
        border-radius: $default-radius;
        padding-top: 16px;
        padding-bottom: 16px;

        .dropdown-item {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 3px;
            padding-bottom: 3px;
            &:first-of-type {
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
    }
}

#triggerZone {
    display: none;
    height: 100vh;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 1;
}
//aside menu
.aside_menu__search,
.aside_menu__login,
.aside_menu__contact,
.aside_menu__language {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    display: block;
    padding: 110px 20px 20px 20px;
    transform: translateX(-1000px);
    transition: transform 0.3s $exponential;

    @include respond-above(lg) {
        transform: translateX(0px);
        left: auto;
    }

    &.is-resizing {
        transition: none !important;
    }
}

.aside_menu__search,
.aside_menu__contact,
.aside_menu__login {
    @include flexbox;
    @include flex-direction(column);

    &.show {
        display: block;
        transform: translateX(0px);
        transition: transform 0.2s $exponential;

        @include respond-above(lg) {
            opacity: 1 !important;
            transform: translateY(0px) !important;
            transition: opacity 0.2s $exponential;
        }
    }

    @include respond-above(lg) {
        max-width: 862px;
        top: 80px;
        z-index: 10;
        position: fixed;
        height: auto;
        padding: 50px;
        border-radius: 0 0 15px 15px;
        @include shadow($deep_med);
    }
}

.aside_menu__contact {
    background-color: $yellow;
    @include respond-above(lg) {
        right: 83px;
        opacity: 0;
        transform: translateX(0px) translateY(-500px);
        transition: opacity 0.2s $exponential;
    }

    .btn__secondary {
        &.contact_block__btn--sml {
            @include respond-above(lg) {
                display: block;
                position: absolute;
                right: 20px;
                bottom: 20px;
            }
        }
    }

    .contact_block {
        @include flexbox();
        width: 100%;
        margin-bottom: 30px;

        @include respond-above(lg) {
            margin-bottom: 0;
            width: 100%;

            &:first-of-type {
                border-bottom: 3px solid $black;
                padding-bottom: 30px;
            }

            &:last-of-type {
                padding-top: 30px;
                a {
                    text-decoration: underline;
                }
            }
        }

        .contact_block__graphic {
            @include flexbox();

            img {
                @include align-self(flex-start);
                width: 35px;
                margin-right: 22px;

                @include respond-above(lg) {
                    width: 55px;
                }

                @include respond-below(sm) {
                    display: none;
                }
            }
        }

        .contact_block__content {
            @include flexbox();
            @include flex-direction(column);
            width: 100%;

            .contact_block__title {
                a {
                    color: $black;
                }
            }

            .contact_block__text {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 10px;
            }

            .contact_block__btn {
                @include respond-above(lg) {
                    display: none;
                }
            }
        }
    }
}

//edits on the first version of the aside menu contact to create temporary v2
.aside_menu__contact_v2 {
    padding: 30px 20px 40px 20px;

    @include respond-below(lg) {
        padding-top: 110px;
    }
    @include respond-above(lg) {
        right: 70px;
        max-width: 850px;
        padding: 40px 100px 50px 60px;
    }
    @include respond-above(xl) {
        right: 80px;
    }

    .contact_block {
        &:first-of-type {
            border: none;
            padding: 0;
        }
        &__buttons {
            @include respond-below(lg) {
                display: flex;
                flex-direction: column;
            }
            a {
                margin-bottom: 15px;
                @include respond-above(lg) {
                    margin-bottom: 0;
                }
            }
        }
    }
    p {
        margin-bottom: 30px;
    }
    @include respond-below(lg) {
        .contact_block__btn--sml {
            visibility: hidden;
        }
    }
}

.aside_menu__login {
    background-color: $blue-light;

    @include respond-above(lg) {
        right: 0;
        opacity: 0;
        transform: translateX(0px) translateY(-500px);
        transition: opacity 0.2s $exponential;
    }

    .login_block {
        @include flexbox();
        @include flex-direction(column);
        width: 100%;

        .login_block__title {
            a {
                color: $black;
            }
        }

        .login_block__text {
            @include respond-above(lg) {
                font-size: 19px;
            }
        }

        .login_block__buttons {
            @include flexbox();
            @include flex-direction(column);
            @include flex-wrap(wrap);

            @include respond-above(lg) {
                @include flex-direction(row);
                @include flex-wrap(no-wrap);
            }

            .btn {
                margin: 0 10px 30px 0;
                width: fit-content;

                @include respond-above(lg) {
                    margin: 0 30px 0px 0;
                    @include flex-wrap(no-wrap);
                }
            }
            &.small_screen {
                .btn {
                    @include respond-below(lg) {
                        margin: 0 10px 15px 0;
                    }
                }
            }
        }
    }
}

.search--form--wrapper {
    @include flexbox;
    @include flex-direction(column);
    z-index: 9;
    display: none;
    position: fixed;
    background-color: $grey-2;
    top: 128px;
    padding: 20px 15px 0px 15px;
    height: 100%;
    width: 100%;

    @include respond-above(lg) {
        top: 80px;
        height: auto;
        padding-top: 70px;
        padding-bottom: 60px;
        border-radius: 0 0 15px 15px;
        @include shadow($deep_med);
    }
    p {
        margin-bottom: 15px;
        @include respond-above(lg) {
            margin-bottom: 28px;
            margin-bottom: 0;
        }
    }

    &.show {
        display: block;
        transform: translateX(0px);
        transition: transform 0.2s $exponential;

        @include respond-above(lg) {
            opacity: 1 !important;
            transform: translateY(0px) !important;
            transition: opacity 0.2s $exponential;
        }
    }

    form {
        input[type="search"] {
            width: 100%;
            padding: 10px 25px;
            border: none;
            border-radius: 4px 0px 0px 4px;
            @include poppins(regular);
            &::placeholder {
                overflow: visible;
            }
        }

        button[type="submit"],
        button[type="reset"] {
            border-radius: 0px 4px 4px 0;
        }
    }
    .search-tags {
        ul {
            overflow: hidden;
            max-height: 112px;
            @media (min-height: 630px) {
                max-height: 160px;
            }
            @media (min-height: 800px) {
                max-height: 330px;
            }
            @media (min-height: 900px) {
                max-height: 330px;
            }
        }
    }
}

.language--menu {
    &__group {
        position: relative;

        @include respond-above(sm) {
            display: none;
        }

        .language--menu {
            .language-menu__item {
                &.language--menu__default--open {
                    transform: translateY(60px);
                        opacity: 0;
                        visibility: hidden;
                }
            }
        }

        .language-menu__item {
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 15px;
            color: $black;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            opacity: 1;
        }
    }

    &__default {
        top: 0;
        display: none;
        position: absolute;
        @include flex-direction(column);
        justify-content: center;
        align-items: flex-start;
        gap: 32px;
        color: $black;
        background-color: $white; 
        z-index: 40;
        padding: 16px 25px;
        border-radius: 15px;
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
	    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);

        &.show {
            display: flex;
        }

        .language-menu__item {
            display: flex;
            align-items: center;
            gap: 14px;
            color: $black;
            font-size: 15px;

            &:first-of-type {
                &::before {
                    content: "";
                    left: 0;
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background: url(/assets/img/icons/flag_dutch.svg) center center no-repeat;
                    background-size: 32px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
            }

            &:last-of-type {
                &::before {
                    content: "";
                    left: 0;
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background: url(/assets/img/icons/flag_english.svg) center center no-repeat;
                    background-size: 32px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
            }
            
    
            .language-item__text {
                color: $black;
                text-align: right;
                font-family: Poppins, sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
}

.language--menu--wrapper {
    @include flexbox;
    @include flex-direction(column);
    z-index: 99;
    display: none;
    position: fixed;
    background-color: $grey-1;
    top: 84px;
    padding: 20px 30px 70px 30px;
    width: 100%;

    @include respond-above(lg) {
        max-width: 163px;
        top: 80px;
        right: 210px;
        height: auto;
        padding: 0;
        border-radius: 0 0 15px 15px;
        @include shadow($deep_med);
    }
    @include respond-above(xl) {
        right: 240px;
    }

    &.show {
        display: flex;
        transform: translateX(0px);
        transition: transform 0.2s $exponential;

        @include respond-above(lg) {
            opacity: 1 !important;
            transform: translateY(0px) !important;
            transition: opacity 0.2s $exponential;
        }
        h3 {
            display: inline-block;
            padding-bottom: 2.5rem;
            @include respond-above(lg) {
                display: none;
            }
        }
    }

    .language-item {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        clear: both;
        font-weight: 400;
        font-size: 13px;
        color: #212529;
        text-align: right;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        @include respond-below(lg) {
            text-align: left;
            margin-bottom: 2.5rem;
            width: auto;
        }

        &:hover {
            text-decoration: none;
        }

        &:first-of-type {
            &::after {
                background: url(/assets/img/icons/flag_dutch.svg) center center no-repeat;
            }
        }
        &:last-of-type {
            &::after {
                background: url(/assets/img/icons/flag_english.svg) center center no-repeat;
            }
        }

        @include respond-above(lg) {
            &:first-of-type {
                padding: 20px 30px 8px 30px;
                &::after {
                    content: "";
                    right: 0;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url(/assets/img/icons/flag_dutch.svg) center center no-repeat;
                    background-size: 20px;
                    margin-left: 10px;
                    vertical-align: middle;
                }
            }

            &:last-of-type {
                padding: 8px 29px 20px 30px;
                &::after {
                    content: "";
                    right: 0;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url(/assets/img/icons/flag_english.svg) center center no-repeat;
                    background-size: 20px;
                    margin-left: 10px;
                    vertical-align: middle;
                }
            }
        }
    }
}

:root {
    --header-top: 56px;
}

html[lang="en"] {
    & .main_nav {
        top: var(--header-top);
    }
    @include respond-above(lg) {
        & .navbar .aside_menu {
            top: var(--header-top);
        }
    }
    @include respond-below(lg) {
        .fontsize-widget,
        .readspeaker-widget {
            top: var(--header-top);
        }
    }
}