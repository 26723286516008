// Primary colors
$blue: #09485f;
$blue-darker: #006cbb;
$blue-dark-30: rgba(0, 108, 187, 0.30);
$blue-middle: #0098d9;
$blue-light: #b3effe;
$blue-light-two: #d9f7fe;
$blue-lightest: #d9f7fe;
$mint: #b2ffe9;
$softgreen: #d9fff4;
$yellow: #ffcf8b;
$navaro-white: #ffe1b7;
$pastel-red: #ffa2a1;
$error-red: #d90000;
$i-want-a-new-css-file: #123456;

// Shades (white -> black)
$white: #ffffff;
$grey-1: #fcfafa;
$grey-2: #f4eeee;
$grey-3: #c6c0c0;
$gray: #d6d6d6;
$gray-darker: #999595;
$offblack: #444444;
$black: #000000;

// syntatic sugar
$gray-1: $grey-1;
$gray-2: $grey-2;
$gray-3: $grey-3;

// Debug
$pink: #ffb5f5;

// Gradients color
.gradient {
	background: rgb(178, 255, 233);
	background: radial-gradient(circle at top right, #b2ffe9 0%, #b3effe 50%, #b3effe 100%);

	&_two {
		background: rgb(178, 255, 233);
		background: radial-gradient(circle at top right, #b3effe 0%, #b2ffe9 50%, #b2ffe9 100%);
	}
	&_linear {
		background: rgb(178, 255, 233);
		background: linear-gradient(270deg, #b2ffe9 0%, #b3effe 50%, #b3effe 100%);
	}
&_fade-out {
    background: linear-gradient(180deg, #D9FFF4 0%,#D9FFF4 40%, rgba(217, 255, 244, 0) 100%);
}
}

@mixin gradient {
	background: rgb(178, 255, 233);
	background: radial-gradient(circle at top right, #b2ffe9 0%, #b3effe 50%, #b3effe 100%);
}

@mixin gradient_two {
	background: rgb(178, 255, 233);
	background: radial-gradient(circle at top right, #b3effe 0%, #b2ffe9 50%, #b2ffe9 100%);
}

@mixin gradient_three {
	background: #d9fff4;
	background: radial-gradient(circle at top right, #b3effe -10%, #d9fff4 15%, #d9fff4 100%);
}

@mixin gradient_four {
	background: #d9fff4;
	background: radial-gradient(circle at top right, #b2ffe9 -5%, #d9f7fe 15%, #d9f7fe 100%);
}

@mixin gradient_five {
	background: #ffcf8b;
	background: radial-gradient(circle, #ffa1a1 0%, #ffcf8b 50%, #ffcf8b 100%);
}

@mixin gradient_linear {
	background: rgb(178, 255, 233);
	background: linear-gradient(#b2ffe9 0%, #b3effe 50%, #b3effe 100%);
}
@mixin gradient_radial {
	background: rgb(179, 239, 254);
	background: radial-gradient(100% 100% at 100% 0%, #b2ffe9 0%, #b3effe 50%, #b3effe 100%);
}

.bg {
	&__grey--1 {
		background-color: $grey-1;
	}

	&__grey--2 {
		background-color: $grey-2;
	}

	&__grey--3 {
		background-color: $grey-3;
	}

	&__mint {
		background-color: $mint;
	}
	&__soft-green {
		background-color: $softgreen;
	}

	&__blue--light--two {
		background-color: $blue-light-two;
	}
	&__blue--light {
		background-color: $blue-light;
	}
	&__blue--darker {
		background-color: $blue-darker;
	}

	&__white {
		background-color: $white;
	}

	&__mint {
		background-color: $mint;
	}
	&__gradient_orange-pink {
		background: #ffcf8b;
		background: radial-gradient(circle at top right, #ffa1a1 0, #ffcf8b, #ffcf8b 100%);
	}
	&__gradient_radial {
		@include gradient_radial;
	}
}
