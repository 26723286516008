.hero_video_cta {
    @include flexbox;
    @include flex-direction(column);
    min-height: 415px;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 100px;

    &--mint {
        background-color: $mint;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 60px;
        padding-bottom: 100px;
        position: relative;
        @include respond-above(lg) {
            padding-bottom: 20px;
        }

        .hero_video_cta__container {
            position: relative;

            @include respond-above(lg) {
                padding-top: 90px;
                padding-bottom: 90px;
            }

            .mvb_hero__path {
                display: block !important;
                max-width: 307px;
                position: absolute;
                left: 2%;
                right: 2%;
                margin: 0 auto -230px auto;
                z-index: 4;
                bottom: 0;

                svg {
                    display: block;
                    width: 100%;
                }

                @include respond-above(md) {
                    left: 5%;
                    right: auto;
                    max-width: 350px;
                }
                @include respond-above(lg) {
                    margin: 0 auto -180px auto;
                }

                @media (min-width: 1135px) {
                    margin: 0 auto -120px auto;
                }
                @include respond-above(xl) {
                    max-width: 420px;
                    margin: 0 auto -130px auto;
                    left: 25%;
                }
            }

            .hero_video_cta__text {
                @media (min-width: 1360px) {
                    padding-right: 0px !important;
                    transform: translateX(100px);
                    padding-left: 3%;
                }

                .hero_video_cta__description {
                    @include respond-above(xl) {
                        font-size: 25px;
                        line-height: 40px;
                    }
                }

                .btn__secondary {
                    padding-left: 0;
                }
            }

            .hero_video_cta__video {
                @media (min-width: 1360px) {
                    transform: translateX(100px);
                }
            }
        }
    }

    .hero_video_cta__container {
        @include respond-above(md) {
            @include justify-content(center);
            @include align-items(center);
        }

        &--medium {
            @include respond-above(lg) {
                max-width: 940px;
                padding-left: 0px !important;
                padding-right: 0px !important;
            }

            @include respond-above(xl) {
                .hero_video_cta__video {
                    padding-right: 2% !important;
                }
            }
        }

        .row {
            & > .hero_video_cta__text:first-child {
                @include respond-above(lg) {
                    margin-top: 70px;
                    margin-bottom: 30px;
                }
            }
        }

        .mvb_hero__path {
            display: none;
        }

        .hero_video_cta__text {
            @include flexbox;
            @include flex-direction(column);
            @include respond-above(md) {
                @include justify-content(center);
            }

            .hero_video_cta__heading {
                margin-bottom: 20px;
            }

            .hero_video_cta__description {
                @include respond-above(md) {
                    margin-right: 30px;
                }
            }

            .btn__secondary {
                padding-left: 0;
            }
        }

        .hero_video_cta__video {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);

            @include respond-above(xl) {
                padding-right: 5%;
            }

            video {
                object-fit: cover;
                height: auto !important;
                max-height: 310px;

                @include respond-above(md) {
                    max-width: 100%;
                }

                @include respond-above(xl) {
                    float: right;
                    margin-bottom: 0;
                    max-width: 100%;
                    width: 100%;
                }
            }

            img {
                display: block;
                width: 100%;
                @include respond-above(xl) {
                    width: auto;
                }
            }
        }

        .hero_video_cta__image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            min-height: 415px;
            image-rendering: -webkit-optimize-contrast;

            @include respond-below(md) {
                min-height: 240px;
                margin-bottom: 34px;
            }

            &.border {
                border: 3px solid #000 !important;
                border-radius: 15px;
                background-size: cover;
            }
        }
    }
}
