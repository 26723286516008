//
//	MIXINS & FUNCTIONS
//	A few simple Sass helpers.
//

// @font-face mixin
// Order of the includes matters, and it is: normal, bold, italic, bold+italic.
// Forked from Bourbon. https://github.com/thoughtbot/bourbon/


@mixin font-face($font-family, $file-path) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.woff2") format('woff2'),
    url("#{$file-path}.woff") format('woff'),
    url("#{$file-path}.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@mixin font-face-no-ttf($font-family, $file-path) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.woff2") format('woff2'),
    url("#{$file-path}.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

// A map of font-weights.
$poppins-families: (
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 800,
);

@mixin poppins($poppins-weight) {
  // If the family exists in the map.
  @if map-has-key($poppins-families, $poppins-weight) {
    // Get the family value.
    $poppins-weight-value: map-get($poppins-families, $poppins-weight);

    // Write css:
    font-family: $heading-font-family;
    font-weight: $poppins-weight-value;
    font-style: normal;


    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid poppins-family: #{$poppins-weight}.';
  }
}

//shadows 
@mixin shadow($depth) {
  box-shadow:$depth;
}

