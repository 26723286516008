// 404 page
main.container-error {
	@include gradient_two;
	max-width: 940px;
	border-radius: $default-radius;
	padding: 30px;
	position: relative;
	margin-bottom: 200px;
	margin-top: 90px;

	@include respond-above(lg) {
		padding: 60px;
	}

	.image--wrapper {
		@include respond-below(md) {
			margin-bottom: 40px;
		}

		img.responsive {
			width: 100%;
			@include respond-below(md) {
				width: 230px;
			}
		}
	}

	.hidden {
		display: none !important;
	}

	.content--wrapper {
		@include respond-below(md) {
			@include flex-direction(column-reverse);
		}
	}

	// some-iss style as `_footer.scss` > .footer__cta
	.btn_cta {
		@include flexbox();
		@include flex-direction(column);
		width: 100%;
		@include respond-above(lg) {
			padding: 0;
		}

		transform: translateY(40px);

		.btn_cta_wrapper {
			background-color: $grey-2;
			border-radius: 15px;
			padding: 40px;
			width: 100%;
			margin-bottom: -110px;

			@include respond-above(lg) {
				padding: 40px 60px;
			}

			.btn_cta_headline {
				margin-bottom: 20px;
				@include respond-above(lg) {
					margin-bottom: 38px 0;
				}
			}

			.btn_cta_buttons {
				@include flexbox();
				@include flex-direction(column);
				@media (min-width: 990px) {
					@include flex-direction(row);
				}

				.btn {
					margin-bottom: 20px;
					@include respond-above(md) {
						margin-right: 20px;
					}
				}
			}
		}
	}
}
