span.scroll-indicator-chevron{
	display: block;
	width: 28px;
	height: 28px;
	position: absolute;
	border-radius: 100px;
	z-index: 10;
	right: 40px;
	&::after{
		content: "";
		display: block;
		margin: auto;
		left: 0;
		right: 0;
		margin-top: 5px;
		border-left: 1px solid #000;
		border-top: 1px solid #000;
		height: 12px;
		width: 12px;
		transform: rotate(225deg);
	}
}
// _block_content_with_nav.twig
.container-content-with-nav {
	margin-bottom: 120px;

	@include respond-below(sm) {
		padding-right: 0;
		padding-left: 0;
	}

	aside {
		position: relative;
		padding-top: 40px;
		padding-bottom: 40px;

		nav.container-content-with-nav__sticky-nav {
			@include gradient_two;
			position: sticky;
			top: 81px;
			transition: 700ms;

			border-radius: $default-radius;

			@include respond-below(sm) {
				margin-right: $default-padding;
				margin-left: $default-padding;
			}
			
			@include respond-below(lg) {
				padding-bottom: 80px;
			}

			@include respond-above(sm) {
				border-radius: 0;
				border-top-left-radius: $default-radius;
				border-bottom-left-radius: $default-radius;
			}

			padding: 20px;
			padding-top: 30px;
			padding-bottom: 40px;
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				gap: 5px;
				
				li {
					width: 100%;
				}
				a {
					margin: 0;
					padding: 0;
				}
			}

			a.active {
				font-weight: 500;
			}
		}
	}
	section {
		&.container-content-with-nav__content--gray, 
		&.container-content-with-nav__content--white {
			background-color: $gray-1;
			border-radius: $default-radius;

			article {
				padding: $default-padding;

				@include respond-above(sm) {
					padding: 60px;
					padding-top: 0;
				}

				h5 {
					padding-bottom: 30px;
				}

				// override the default <a>, but make sure to reset the text-decoration
				a {
					color: $black;
					text-decoration: underline;
					&.btn__primary {
						text-decoration: none !important;
					}
				}
				
				// line at the TOP of the article (test)
				&::before {
					content: "";
					border-bottom: 3px solid $black;
					width: 100%;
					display: block;
					margin-bottom: 60px;
				}

				// for a piechart block, only the first item should shhow the line,
				// so if it 'is-not-first-item-of-pie-chart-block' hide the line
				&.is-not-first-item-of-pie-chart-block {
					&::before {
						border: none;
						margin-bottom: -40px;
					}
				}

				// first article less padding AND
				&:first-child {
					padding-top: 40px;
					&::before {
						content: "";
						border-bottom: 0 solid $black;
						margin-bottom: 0;
					}
				}
				&:last-child {
					padding-bottom: 60px;
				}

				// some extra margin on the top, to compensate for the missing padding from the line
				+ article.gradient {
					padding-top: 60px;
					margin-bottom: 60px;
				}

				&.gradient {
					padding-bottom: 60px;
					&::before {
						content: "";
						border-bottom: 0 solid $black;
						margin-bottom: 0;
					}
					// hide (top) line when an article.gradient is followed by a article
					+ article {
						&::before {
							content: "";
							border-bottom: 0 solid $black;
							margin-bottom: 0;
						}
					}
				}

				.btn {
					margin-top: 30px;
				}
				ul.no-bullets {
					list-style: none;
					margin: 0;
					padding: 0;
					.btn {
						margin-top: 0;
					}
					a {
						margin: 0;
						padding: 0;
					}
				}
				figure {
					img {
						width: 100%;
						// max-width: 100%; // don't want to stretch image?
						height: auto;
					}
					figcaption {
						color: $black;
						font-family: $heading-font-family;
						font-size: 12px;
						letter-spacing: 0;
						line-height: 23px;
						padding-top: 10px;
					}
				}
			}
		}
		&.container-content-with-nav__content--white {
			background-color: $white;
		}
	}
}
