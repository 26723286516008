.two_col_link {
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(space-between);
	width: 100%;
	margin-top: 3rem;

	.two_col_link__wrapper {
		@include flexbox();
		@include flex-direction(column);
		width: 100%;
		@include gradient;
		border-radius: $default-radius;
		padding-top: 90px;

		&--gradient_two {
			@include gradient_two;
		}

		&--padding_btm {
			padding-bottom: 410px;
			@include respond-above(md) {
				padding-bottom: 250px;
			}
		}

		margin-bottom: 120px;

		@include respond-above(md) {
			padding-bottom: 90px;
		}

		.two_col_link__container {
			@include flexbox();
			@include flex-direction(column);
			max-width: 968px;
			width: 100%;
			margin: 0 auto;

			@include respond-above(md) {
				@include flex-direction(row);
				@include flex-wrap(nowrap);
				@include justify-content(space-between);
				width: 100%;

				&.flex-wrap {
					@include flex-wrap(wrap);
				}
			}

			.two_col_link__header {
				@include flexbox();
				@include flex-direction(column);
				margin-bottom: 70px;
				width: 100%;

				@include respond-above(md) {
					padding-right: 40px;
					margin-bottom: 0;
					width: 46%;
				}

				.two_col_link__title {
					margin-bottom: 25px;
					@include respond-above(md) {
						font-size: 30px;
						line-height: 38px;
					}
				}

				.two_col_link__description {
					margin-bottom: 32px;
				}

				.two_col_link__first-subtitle {
					margin-bottom: 0;
					font-size: 18px;
					margin-top: 40px;
				}

				.btn__secondary {
					padding-left: 0;
				}
			}

			.two_col_link__links {
				@include flexbox();
				@include flex-direction(column);
				margin: 0 0 60px 0;
				padding: 0;
				width: 100%;

				@include respond-above(md) {
					margin: 0 0 0px 0;
					width: 50%;
				}

				.two_col_link__second-subtitle {
					margin-bottom: 27px;
				}
			}
		}
	}

	& + .image_text_cta {
		margin-top: -400px;
		@include respond-above(md) {
			margin-top: -200px;
		}
	}
}

.two_col_link__link-list {
	@include flexbox();
	@include flex-direction(column);
	list-style-type: none;
	margin: 0;
	padding: 0;

	.two_col_link__list-item {
		@include flexbox();
		@include flex-direction(row);
		position: relative;

		.two_col_link__link {
			@include flexbox();
			@include flex-direction(row);
			@include flex-direction(flex-start);
			@include flex-wrap(no-wrap);
			@include align-items(center);
			background-color: $white;
			border-radius: $default-radius;
			padding: 16px 20px 16px 10px;
			margin-bottom: 20px;
			width: 100%;
			position: relative;
			text-decoration: none;
			transition: transform 0.3s $exponential;

			@include respond-above(md) {
				padding: 10px 25px 10px 20px;
			}

			.two_col_link__link-img {
				display: block;
				margin-right: 20px;
				width: 30px;
				height: 30px;
			}

			.two_col_link__text {
				@include flexbox();
				@include flex-direction(column);
				width: 100%;

				.two_col_link__link-title,
				.two_col_link__link-description {
					color: $black;
				}
				.two_col_link__link-title {
					margin: 0 0 5px 0;
					@include respond-above(sm) {
						font-size: 15px;
						margin: 0 0 2px 0;
					}
				}

				.two_col_link__link-description {
					font-size: 13px;
					line-height: 16px;
					display: block;
					width: 100%;
				}
			}

			&:after {
				content: "";
				display: inline-block;
				height: 20px;
				width: 30px;
				position: absolute;
				bottom: 8px;
				right: 10px;
				vertical-align: middle;
				background: url("/assets/img/icons/arrow_sprite.svg") center right no-repeat;
				background-size: 50px;
				background-position-x: -20px;
				transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
				margin-left: 10px;
			}

			&:hover {
				transform: translateY(-2px);
				&:after {
					background-position-x: 10px;
				}
			}
		}
	}
}
