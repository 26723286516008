div.back-button-header {
	margin-top: 20px;
	margin-bottom: 10px;
	display: none !important;

	@include respond-above(sm) {
		display: block !important;
	}
	
	.btn__secondary--back-link {
		margin-left: -12px;
		border: 0;
	}

	&#wwp-detail-page-back-button {
		display: block !important;

		@include respond-above(md) {
			max-width: 450px;
		}
		
		@include respond-above(sm) {
			max-width: 720px;
		}

		@include respond-above(lg) {
			max-width: 960px;
		}

		&:not(.container-md) {
			@include respond-above(xl) {
				max-width: 1140px;
			}
		}

	}
}

.contentPages {
	div.back-button-header {
		margin-bottom: -34px;
	}
}