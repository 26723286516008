.container-table {
    padding-bottom: 40px;
}

table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    th,
    td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }
    thead {
        th {
            color: $black;
            background-color: $blue-light;
            border-color: $blue-light;
            @include poppins(bold);
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
        }
    }
    tbody {
        tr {
            // default color of tr
            background-color: $grey-1;
            td {
                font-weight: 100;
                strong {
                    font-weight: 800;
                }
            }
        }
    }
}

// color
table tbody tr:nth-of-type(2n + 1) {
    background-color: $white;
}
