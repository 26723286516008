.card-articles {
    .card-article {
        list-style: none;
        color: $black;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 4px;
        }

        //Strange behaviour from 990 - 991
        //Have not found other way to fix this issue than using @media
        @media (min-width: 992px) {
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }

        @include respond-above(lg) {
            display: flex;
            align-items: stretch;

            padding-left: 1px;
            padding-right: 1px;

            transform: translateY(0px);
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: translateY(-10px);
            }
        }

        &__link {
            text-decoration: none;
            color: inherit;
        }
        &__header {
            padding-top: 15px;
        }

        &__image {
            background-color: $blue-light-two;
            overflow: hidden;
            flex-shrink: 0;
            background-size: cover;
            height: auto;
            width: 120px;

            //Strange behaviour from 990 - 991
            //Have not found other way to fix this issue than using @media
            @media (min-width: 992px) {
                height: 150px;
                width: 100%;
            }
        }
    }
}
