//makes use of latest news elements
.container_publications {
	//adds margin to previous editions section
	.latest--news--publications--wrapper {
		margin-top: 120px;
		margin-bottom: 40px;
	}
	//removes high margin on bottom of title block
	.latest_news--previous_editions {
		margin-bottom: 30px;
	}

	.image_text {
		.image_text__wrapper {
			background: $grey-1;
		}
	}
	.image_text_cta__wrapper {
		.image_text_cta__container {
			background-color: $mint;
		}
	}
}
