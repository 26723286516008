.mvbDetail {
    @include gradient;
}
#mvb-detail {
    //Back button
    .back-button-header {
        margin-bottom: 20px;
        @include respond-below(md) {
            display: block !important;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }
    //Header
    .article_header {
        &__wrapper {
            @include respond-below(md) {
                border-radius: $default-radius;
            }
        }
        &__asside {
            margin-bottom: -22%;

            @include respond-above(md) {
                @include flex-direction(row);
                margin-bottom: -30%;
            }
        }
    }

    .article_header__intro {
        padding-left: 0;
    }

    .article_img {
        padding: 0;
        overflow: hidden;
        img {
            border-radius: 15px;
            @include respond-below(md) {
                border-radius: 7px;
                margin-bottom: 10px;
            }
        }
    }

    //Content
    .mvb_article {
        margin-top: 240px;
        @include respond-below(md) {
            margin-top: 44%;
        }
        &__container {
            max-width: 970px;
            padding: 0;
            margin-bottom: 85px;
        }

        .row {
            margin: 0;
            padding: 0;
        }

        @include respond-below(md) {
            .col-12 {
                padding: 0;
            }
        }

        .mvb_article_content {
            background-color: $white;
            border-radius: $default-radius 0 0 0;
            padding: 40px 40px 50px 40px;
            @include respond-below(md) {
                border-radius: $default-radius $default-radius 0 0;
                padding: 40px 40px 20px 40px;
            }

            &__lead {
                p {
                    margin-bottom: 60px;
                    @include respond-below(md) {
                        font-size: 22px;
                    }
                }
            }
            &__body {
                h5 {
                    margin-bottom: 10px;
                    @include respond-below(md) {
                        font-size: 21px;
                        line-height: 30px;
                    }
                }
                p {
                    line-height: 36px;
                    margin-bottom: 40px;
                    & + ul {
                        margin-top: -24px;
                    }
                }

                //The button on the side of the content
                .side-extra-info {
                    @include respond-below(md) {
                        display: none;
                    }

                    position: relative;
                    &__link {
                        cursor: pointer;
                        color: inherit;
                        text-decoration: inherit;
                        position: absolute;
                        right: 0;
                        top: 30px;
                        margin-right: -200px;
                        border-radius: 0 $default-radius $default-radius 0;
                        background-color: $blue-light-two;
                        z-index: 5;
                        @include respond-below(lg) {
                            margin-right: -185px;
                        }
                        &:hover {
                            .side-extra-info__arrow {
                                &:after {
                                    background-position-x: -0px;
                                }
                            }
                        }
                    }
                    &__details {
                        padding: 20px;
                        p,
                        h6 {
                            // max-width: 120px;
                            width: 7.5rem;
                            margin: 0;
                            line-height: 18px;
                            @include respond-below(lg) {
                                max-width: 105px;
                            }
                        }
                        h6 {
                            margin-bottom: 20px;
                        }
                        &__category {
                            padding-bottom: 10px;
                        }
                    }
                    &__arrow {
                        text-align: right;
                        margin: 15px;
                        &:after {
                            content: "";
                            right: 20px;
                            bottom: 10px;
                            position: absolute;
                            display: inline-block;
                            height: 20px;
                            width: 20px;
                            vertical-align: middle;
                            background-image: url(/assets/img/icons/arrow_sprite.svg);
                            background-position: center right;
                            background-repeat: no-repeat;
                            background-size: 45px;
                            transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
                            margin-left: 20px;
                        }
                    }
                }
            }
            &__quote {
                @include gradient;
                max-width: 540px;
                padding: 40px 60px 40px 60px;
                border-radius: $default-radius;
                @include respond-below(md) {
                    padding: 30px;
                }
            }
            &__image {
                margin-bottom: 40px;
                img {
                    border-radius: $default-radius;
                    max-width: 100%;
                    padding-bottom: 10px;
                }
            }
        }
        .mvb_article__nav {
            &__title {
                background-color: $grey-2;
                padding: 40px;
                margin: 0;
                padding-bottom: 75px;
                border-radius: 0 0 $default-radius $default-radius;
                @include respond-below(md) {
                    h5 {
                        width: 75%;
                    }
                }
            }
            &__buttons {
                text-align: center;
                transform: translateY(-50%);
                @include respond-above(sm) {
                    a {
                        margin: 0 15px 0px 15px;
                    }
                }
                @include respond-below(md) {
                    .btn__primary--lg {
                        scale: 0.85;
                        a {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        //Side label author
        .side-author {
            margin-left: -30px;
            padding-left: 0;
            overflow: hidden;
            background: $grey-2;
            border-radius: 0 $default-radius $default-radius 0;
            cursor: pointer;
            @include respond-below(md) {
                margin-left: 0;
                border-radius: $default-radius $default-radius 0 0;
            }

            &__link {
                color: inherit;
                text-decoration: inherit;
                @include respond-below(md) {
                    display: flex;
                }
            }

            &__image {
                background-size: cover;
                background-position-x: center;
                background-position-y: 0%;
                background-repeat: no-repeat;
                height: 120px;

                @include respond-below(md) {
                    display: flex;
                    flex: 0 0 35%;
                    height: auto;
                    width: 100%;
                }
            }

            &__info {
                padding: 15px 20px 27px 20px;

                @include respond-below(md) {
                    padding: 1.2rem 1.2rem 1.2rem 2rem;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                p {
                    margin: 0;
                    &:first-of-type {
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }
}
