.swiper{
  .swiper-image-container{
    border-radius: 5px;
    overflow: hidden;
  }
  .swiper-image{
    aspect-ratio: 3979/4857 auto; 
    background-position: center center; 
    background-size: contain; 
    background-repeat: no-repeat;
  }
  .swiper-gradient{
    background: linear-gradient(270deg, #B3EFFE 41.88%, rgba(179, 239, 254, 0.00) 100%);
    height: 100%;
    width: 40px;
    @include respond-above(md) {
      width: 120px;
    }
    position: absolute;
    right: 0;
    z-index: 10;
    pointer-events: none;
  }
    //unstyling links
    a {
      color: inherit;
      text-decoration: inherit;
    }
}