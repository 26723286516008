.helpdesk-audience-phone {
    @include respond-above(lg) {
        display: flex;
    }

    .helpdesk-audience-phone__container {
        &_info {
            display: flex;
            flex-direction: column;
            padding: 40px 15px;

            @include respond-above(sm) {
                padding: 50px 60px 60px;
                width: 100%;
                border-radius: 15px 15px 0 0;
            }

            @include respond-above(lg) {
                border-radius: 15px 0 0 15px;
            }

            h4 {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 0;
            }
        }

        &_details {
            display: flex;
            flex-direction: column;
            padding: 40px 15px;
            @include respond-above(sm) {
                padding: 50px 40px 0 40px;
                width: 100%;
                border-radius: 0 0 15px 15px;
            }

            @include respond-above(lg) {
                border-radius: 0 15px 15px 0;
            }

            h5 {
                margin-bottom: 5px;
                line-height: 30px;
            }
        }
        .detail {
            margin-bottom: 40px;
            
            a[href^="#"] {
                color: $black;
                text-decoration: underline;
            }
            img {
                margin-top: 7px;
                width: 15px;
                height: 15px;
            }
            &__info {
                margin-left: 20px;
            }
        }
    }
}
