//article

.btn {
	white-space: nowrap;
	width: fit-content;
	@include align-self(flex-start);

	&.btn__primary,
	&.btn__secondary,
	&.btn__tertiary,
	&.btn__language {
		color: $black;
		letter-spacing: 0;
		display: inline-block;
		cursor: pointer;
		position: relative;
		background-color: $white;
		transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
	}

	&.btn__primary {
		@include poppins(medium);
		font-weight: 500;
		border-radius: 15px;
		box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
		border: none;
		font-size: 14px;
		line-height: 23px;
		padding: 20px 0 20px 23px;

		&:after {
			content: "";
			right: 0;
			display: inline-block;
			width: 0;
			height: 20px;
			opacity: 0;
			background: url(/assets/img/icons/arrow.svg) left center no-repeat;
			margin-left: 25px;
			vertical-align: middle;
			transition: all 0.4s cubic-bezier(1, 0, 0, 1);
			background-size: 30px;
			transform: translateX(-20px);
		}

		@include respond-above(md) {
			&:hover,
			&:focus {
				background: $white;

				&:after {
					opacity: 1;
					width: 45px;
					margin-left: 20px;
					transform: translateX(0px);
				}
			}
		}

		&--lg {
			padding: 20px 0 20px 25px;
			font-size: 1.25rem;
			line-height: 1.5;
			border-radius: 15px;

			&:after {
				width: 0;
				background-size: 35px;
				transform: translateX(-20px);
				margin-left: 25px;
			}

			@include respond-above(md) {
				&:hover,
				&:focus {
					&:after {
						margin-left: 20px;
						width: 50px;
					}
				}
			}
		}

		&[data-freeform-action="back"],
		&.arrow-left {
			transform: scaleX(-1);
			span {
				display: inline-block; // Otherwise the text remains flipped
				transform: scaleX(-1);
			}
		}

		&--med {
			&:after {
				width: 0;
				background-size: 30px;
				transform: translateX(-20px);
			}
			@include respond-above(md) {
				&:hover,
				&:focus {
					&:after {
						width: 45px;
						margin-left: 20px;
					}
				}
			}
		}

		&--sml {
			font-size: 14px;
			padding: 15px 0 15px 23px;
			&:after {
				width: 0;
				background-size: 25px;
				transform: translateX(-20px);
			}
			@include respond-above(md) {
				&:hover,
				&:focus {
					&:after {
						width: 35px;
						margin-left: 15px;
					}
				}
			}
		}
		&--sml--icon {
			&::before {
					content: "";
					right: 0;
					display: inline-block;
					width: 18px;
					height: 18px;
					background: url(/assets/img/icons/lightbulb-icon.svg) center center no-repeat;
					background-size: 18px;
					margin-right: 14px;
					margin-bottom: 2px;
					vertical-align: middle;
			}
		}
		
	}
	&.btn__tertiary {
		@include poppins(regular);
		border: 1px solid black;
		background-color: transparent;
		border-radius: 8px;
		&:hover,
		&:focus {
			background-color: white;
		}
	}
	&.btn__secondary {
		@include poppins(regular);
		font-size: 13px;
		line-height: 24px;
		background-color: rgba(0, 0, 0, 0);

		@keyframes arrow_sprite {
			from {
				background-position-x: -20px;
			}
			to {
				background-position-x: 10px;
			}
		}

		&:after {
			content: "";
			display: inline-block;
			height: 20px;
			width: 30px;
			vertical-align: middle;
			background: url("/assets/img/icons/arrow_sprite.svg") center right no-repeat;
			background-size: 50px 15px;
			background-position-x: -21px;
			transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
			margin-left: 10px;
		}

		&:hover {
			text-decoration: underline;
			&:after {
				background-position-x: 10px;
			}
		}

		&--back-link {
			color: $blue-darker;

			&:after {
				display: none;
			}

			&:before {
				content: "";
				display: inline-block;
				height: 20px;
				width: 20px;
				vertical-align: middle;
				background: url("/assets/img/icons/arrow_back.svg") center left no-repeat;
				background-size: 40%;
			}

			&:hover {
				color: $blue-darker;
			}
		}
	}
	&.btn__language {
		@include poppins(medium);
		font-weight: 500;
		border-radius: 15px;
		box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
		border: none;
		font-size: 14px;
		line-height: 23px;
		padding: 20px 20px 20px 23px;

		&:after {
			content: "";
			right: 0;
			display: inline-block;
			width: 20px;
			height: 20px;
			background: url(/assets/img/icons/flag_dutch.svg) center center no-repeat;
			background-size: 20px;
			margin-left: 10px;
			vertical-align: middle;
		}
		&--sml {
			font-size: 14px;
		}
	}

	&.btn__social {
		width: 48px;
		height: 48px;
		background-color: $gray-2;
		border-radius: 100px;
		display: flex;
		align-items: center;

		&--facebook {
			&::after {
				content: "";
				display: inline-block;
				width: 20px;
				height: 20px;
				background: url(/assets/img/icons/facebook.svg) center center no-repeat;
				margin: auto;
			}
		}
		&--instagram {
			&::after {
				content: "";
				display: inline-block;
				width: 20px;
				height: 20px;
				background: url(/assets/img/icons/instagram.svg) center center no-repeat;
				margin: auto;
			}
		}
		&:hover {
			box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0);
		}
	}

	&.btn__chevron {
		padding: 0;
		width: 48px;
		height: 48px;
		background: url("/assets/img/icons/chevron-blue.svg") center center no-repeat;
		transition: all 0.2s;
		transform: rotate(180deg);
		&.btn__chevron__collapsed {
			transform: rotate(0deg);
		}
	}

	&.btn__feedback {
		width: 60px;
		height: 60px;
		background-color: $white;
		border-radius: 100px;
		box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
		display: flex;
		align-items: center;

		&--positive {
			&::after {
				content: "";
				display: inline-block;
				width: 24px;
				height: 24px;
				background: url(/assets/img/icons/thumb.svg) center center no-repeat;
				margin: auto;
				background-size: cover;
			}
		}
		&--negative {
			&::after {
				content: "";
				display: inline-block;
				width: 24px;
				height: 24px;
				background: url(/assets/img/icons/thumb.svg) center center no-repeat;
				transform: scaleX(-1) scaleY(-1);
				margin: auto;
				background-size: cover;
			}
		}

		&:hover {
			box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0);
		}
		&--small {
			box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0);
			width: 40px;
			height: 40px;

			&.btn__feedback--negative,
			&.btn__feedback--positive {
				&::after {
					width: 16px;
					height: 16px;
				}
			}

			&:hover {
				box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
			}

			&.btn__feedback--checkmark {
				&::after {
					content: "";
					display: inline-block;
					width: 24px;
					height: 24px;
					background: url(/assets/img/icons/check-icon.svg) center center no-repeat;
					margin: auto;
					background-size: contain;
				}
				&:hover {
					box-shadow: none;
					cursor: auto;
				}
			}
		}
	}
}

// File button
.btn-file {
	color: $black;
	background-color: $blue-lightest;
	border-radius: 15px;
	height: 100%;
	width: 100%;
	margin-bottom: 20px;
	align-items: center;
	display: flex;
	&:hover {
		color: $black;
		text-decoration: none;
	}

	&::before {
		content: "";
		background-image: url(/assets/img/icons/information.svg);
		height: 30px;
		width: 30px;
		display: inline-block;
		background-repeat: no-repeat;
		margin-left: 20px;
	}

	.h7 {
		display: inline-block;
		margin: 20px 0px 20px 20px;
		max-width: 75%;
		padding-right: 10px;
		p {
			margin: 0;
			font-size: 12px;
			line-height: 20px;
		}
		&:hover {
			color: $black;
		}
	}

	&::after {
		content: "";
		background-image: url(/assets/img/icons/arrow_sml.svg);
		height: 12px;
		width: 24px;
		display: inline-block;
		background-repeat: no-repeat;
		align-self: flex-end;
		margin-bottom: 20px;

		@include respond-below(sm) {
			visibility: hidden;
			display: none;
		}
	}
	&:hover::after {
		transition-timing-function: ease-out;
		transition-duration: 0.2s;
		transform: translateX(10px);
	}
}

a.link {
	color: $blue-darker;
	cursor: pointer;
}

.btn__file {
	background-color: $white;
	border-radius: 15px;
	width: 100%;
	word-wrap: break-word;
	white-space: normal;
	display: flex;
	position: relative;

	&--grey {
		background-color: $grey-2;
	}

	&__img {
		margin-left: 8px;
		margin-right: 20px;
	}

	&__text {
		text-align: left;
		color: $black;
		padding-top: 5px;
		padding-bottom: 4px;
		p {
			margin: 0;
		}
	}
	&__title {
		margin-bottom: 0;
	}
	&:after {
		content: "";
		display: inline-block;
		height: 20px;
		width: 30px;
		position: absolute;
		bottom: 8px;
		right: 10px;
		vertical-align: middle;
		background: url("/assets/img/icons/arrow_sprite.svg") center right no-repeat;
		background-size: 50px;
		background-position-x: -20px;
		transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
		margin-left: 10px;
	}
	&:hover {
		transform: translateY(-2px);
		&:after {
			background-position-x: 10px;
		}
	}
}