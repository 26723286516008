main#news {
    header.container {
        margin: 22px auto;
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        max-width: 970px;

        @include respond-below(md) {
            @include flex-direction(column-reverse);
        }
        @include respond-above(md) {
            min-height: 310px;
        }

        .image--wrapper {
            width: 295px;
            height: 310px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include respond-below(md) {
                margin-bottom: 34px;
            }
        }
    }
}