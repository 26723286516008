.helpdesk-complaints {
    margin-bottom: 80px;

    @include respond-below(lg) {
        flex-direction: column;
        justify-content: center;
    }

    @include respond-above(lg) {
        display: flex;
    }

    .helpdesk-complaints__container {

        &_complaints {
            padding: 40px 15px 40px 15px;
            @include respond-above(sm) {
                border-radius: 15px;
            }
            @include respond-above(md) {
                padding: 35px 40px 20px 60px;
            }
        }

        .helpdesk-complaints_text {
            @include flexbox();
            @include flex-direction(column);
            margin: auto;
            padding-bottom: 20px;
            @include respond-above(md) {
                padding-right: 100px;
            }
            h4 {
                padding-bottom: 4px;
            }
            &__p {
                margin-bottom: 0;
            }
        }
        .helpdesk-complaints_link {
            display: flex;
        }
    }
}
