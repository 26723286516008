.pension_results {
    background: url(/assets/img/letop_image.png), $blue-light-two;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 15px 15px 0 0;
    height: 335px;
    @include respond-above(md) {
        border-radius: 15px 0 0 15px;
        height: 100%;
    }
}

.tip__cta {
    @include respond-above(lg) {
        padding-left: 300px;
    }
    &__wrapper {
        border-radius: 15px 15px 0 0;
        @include respond-above(md) {
            border-radius: 15px;
            height: 100%;
        }
        .tip__image {
            background: url(/assets/img/Illustration__8_2x.png), $softgreen;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 15px 15px 0 0;
            height: 290px;
            @include respond-above(md) {
                border-radius: 0 15px 15px 0;
                height: 100%;
            }
        }
    }
}

.klaar__cta {
    @include respond-above(lg) {
        padding-left: 30px;
    }
    &__wrapper {
        border-radius: 15px 15px 0 0;
        @include respond-above(md) {
            border-radius: 15px;
            height: 100%;
        }
        .klaar__image {
            background: url(/assets/img/letop_image.png), $blue-light-two;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 15px 15px 0 0;
            height: 290px;
            @include respond-above(md) {
                border-radius: 15px 0px 0px 15px;
                height: 100%;
            }
        }
        .send-form {
            .result-email-button {
                align-self: flex-start;

                &.btn.btn__primary.btn__primary--lg {
                    font-size: 18px;

                    @include respond-below(sm) {
                        font-size: 14px;
                        padding: 15px 0px 15px 23px;
                    }
                }
            }
        }
    }
}