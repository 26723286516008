.info_button {
    position: relative;
    display: inline-flex;

    &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
            position: absolute;
            top: 60%;
            right: 0%;
            margin-top: 14px;
            padding: 10px 20px;
            width: 280px;
            border-radius: 15px;
            box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);
            pointer-events: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            z-index: 10;
        }
        @include respond-above(md) {
            &:hover {
                span {
                    top: 100%;
                    opacity: 1;
                }
            }
        }
    }
}
