.hero_text_only {
    @include flexbox;
    @include flex-direction(column);
    width:100%;
    margin-top:180px;
    margin-bottom:90px;
    .hero_video_cta__heading {
        margin-bottom:20px;
    }
    .hero_video_cta__subheading {
        margin-bottom:30px;
    }
}