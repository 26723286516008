.container-header-faq {
	margin-top: 60px;
	margin-bottom: 120px;

	h1 {
		@include respond-below(sm) {
			font-size: 28px;
			line-height: 40px;
		}
		margin-bottom: 20px;
	}
	p {
		@include respond-above(md) {
			margin-right: 100px;
		}
	}

	.image_text__wrapper {
		padding-top: 60px;
		padding-bottom: 60px;

		.image_text_cta {
			margin-bottom: 40px;
			@include respond-above(md) {
				margin-bottom: 60px;
			}

			&__container {
				border-radius: 15px;
				overflow: hidden;
				margin-bottom: 0;
			}
			&__image {
				@include respond-below(md) {
					margin-bottom: 0px !important;
				}
			}
			&__text {
				padding: 40px !important;
				@include respond-above(lg) {
					padding: 40px 0px 40px 40px !important;
				}
				.btn__secondary {
					text-align: left;
					white-space: break-spaces;
				}
				&__questions {
					a {
						display: block;
					}
					a:last-child {
						margin-bottom: 40px;
					}
				}
			}

			&__image {
				background-size: contain !important;
				background-position: center center;
				min-height: auto !important;
			}
		}
	}

	.header-faq_image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		@include respond-below(lg) {
			min-height: 240px;
			margin-bottom: 34px;
		}
	}
	section:first-of-type {
		margin-bottom: 60px;
	}
}
