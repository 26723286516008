.container-search {
    margin-bottom: 100px;
    @include respond-above(md) {
        margin-bottom: 80px;
    }
    &__content {
        padding: 40px 30px 50px 40px;
        border-radius: $default-radius 0 0 $default-radius;
        @include respond-above(md) {
            padding: 40px 60px;
            border-radius: $default-radius;
        }

        h3 {
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 20px;
        }
        form {
            input[type="search"] {
                width: 100%;
                @include respond-above(md) {
                    max-width: 70%;
                }
                padding: 10px 25px;
                border: none;
                border-radius: 4px;
                margin-bottom: 30px;
                @include poppins(regular);
            }
            input[type="submit"] {
                padding: 14px 20px;
            }
        }
    }
}
