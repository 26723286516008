.image_counter {
	margin-top: -20px;
	padding-right: 0;
	padding-left: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0;
	position: relative;
	z-index: 3;
	transform: translateX(0%) translateY(-50%);

	@include respond-above(sm) {
		margin-right: 0;
	}

	@include respond-above(md) {
		margin: 0 auto;
		margin-right: 0;
	}

	@include respond-above(lg) {
		margin: 0 auto;
	}

	&__wrapper {
		padding: 0;
		margin: 0;
		margin-bottom: -150px;

		@include respond-above(md) {
			padding: 0 30px;
			margin: auto;
			margin-bottom: -60px;
		}

		@include respond-above(lg) {
			padding: 0 60px 0 0;
			margin: 0;
			margin-bottom: -60px;
		}
	}
	&__container {
		@include flexbox();
		@include flex-direction(column);
		border-radius: 15px;
		padding: 0;
		height: 460px;
		margin: 0 auto;

		&--blue-light {
			background-color: $blue-light;
		}
		@include respond-above(md) {
			@include flex-direction(row);
			border-radius: 15px;
			height: 360px;
		}

		a {
			color: $black;
		}
	}

	&__text {
		@include flexbox();
		@include flex-direction(column);

		padding: 30px 30px 0px 30px;
		text-align: center;
		@include respond-above(lg) {
			padding: 40px 0px 130px 80px;
		}

		h4 {
			margin-bottom: 0;
			&.semibold {
				margin-bottom: 15px;
			}
		}

		.dekkingsgraad_number {
			h2 {
				font-size: 72px;
				line-height: 90px;
				@include respond-above(md) {
					line-height: 108px;
				}
				.animation-counter {
					font-size: 72px;
					line-height: 90px;
					@include respond-above(md) {
						line-height: 108px;
					}
				}
			}
		}
	}
	&__image {
		height: 100%;
		width: 100%;
		background-image: url("/assets/img/dekkingsgraad/BPFD_ILLUSTRATIONS_A_alt_2_Illustration__5_-_emptier.png");
		background-size: cover;
		background-position-x: center;
		background-position-y: 0%;
		background-repeat: no-repeat;

		@include respond-above(sm) {
			background-size: contain;
			background-position-y: 50%;
		}

		@include respond-above(md) {
			background-size: 100%;
			background-position-y: 100%;
		}
	}
}
