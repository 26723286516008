.container-two-colum-content_mint-white {
    padding-left: 30px;
    padding-right: 30px;
    @include respond-above(md) {
        padding: auto;
    }
    margin-bottom: 80px;
    @include respond-above(md) {
        margin-bottom: 80px;
    }
    .container-two-colum-content_mint-white__content {
        display: flex;
        flex-direction: column;
        padding: 40px 30px 32px 30px;
        @include respond-above(md) {
            padding: 40px 40px 32px 40px;
        }
        h4 {
            margin-bottom: 22px;
        }
        a {
            padding-left: 0;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 0;
            white-space: inherit;
            text-align: left;
            border: none;
        }
        &:first-child {
            border-radius: $default-radius $default-radius 0 0;
            @include respond-above(md) {
                border-radius: $default-radius 0 0 $default-radius;
            }
        }
        &:last-child {
            p {
                margin-bottom: 8px;
            }
            h4 {
                margin-bottom: 15px;
            }
            border-radius: 0 0 $default-radius $default-radius;
            @include respond-above(md) {
                border-radius: 0 $default-radius $default-radius 0;
            }
        }
    }
}
