.article_header {
	.article_header__wrapper {
		@include gradient_three;

		@include respond-above(sm) {
			border-radius: $default-radius;
		}

		.article_header__container {
			@include flexbox();
			@include flex-direction(column);
			max-width: 940px;
			margin: 0 auto;
			width: 100%;

			@include respond-above(xl) {
				padding: 52px 0px 0px 0px !important;
			}

			.article_header__intro {
				@include flexbox();
				@include flex-direction(column);
				width: 100%;

				@include respond-above(md) {
					align-self: flex-end;
					padding-left: 167px;
				}

				.line {
					display: block;
					width: 100%;
					max-width: 220px;
					border-top: 1px dashed #09485f;
					opacity: 0.2;
					padding: 0 0 20px 0;
					margin: 20px 0 0 0;

					@include respond-above(md) {
						max-width: 230px;
					}
				}

				.article_header__title {
					margin-bottom: 20px;
				}
				.article_header__sub-title {
					margin-bottom: 40px;

					@include respond-above(xl) {
						font-size: 30px;
						line-height: 38px;
						margin-bottom: 55px;
					}
				}
			}

			.article_header__aside {
				@include flexbox();
				@include flex-direction(column);
				width: 100%;
				margin-bottom: -40%;

				@include respond-above(md) {
					@include flex-direction(row);
					margin-bottom: -22%;
				}

				.article_author {
					@include flexbox();
					@include flex-direction(column);
					@include justify-content(flex-start);
					margin-bottom: 30px;
					position: relative;
					padding-left: 80px;

					@include respond-above(md) {
						@include justify-content(space-between);
						@include align-items(center);
						text-align: center;
						background-color: $grey-2;
						border-radius: 15px;
						padding: 30px 15px;
						max-width: 140px;
						align-self: flex-start;
					}

					.article_author__image {
						@include flexbox();
						@include flex-direction(column);
						position: absolute;
						left: 0;
						@include respond-above(md) {
							position: relative;
						}

						img {
							display: block;
							width: 100%;
							max-width: 60px;
							height: auto;

							@include respond-above(md) {
								max-width: 110px;
							}
						}
					}

					.author_title,
					.author {
						font-size: 15px;
						line-height: 18px;
					}

					.author {
						margin: 5px 0;
						max-width: 95%;
						@include respond-above(md) {
							margin-bottom: 5px;
						}
					}

					.author_title {
						line-height: normal;
						@include respond-above(md) {
							margin-top: 15px;
						}

						p {
							margin-bottom: 0;
							line-height: normal;
						}
					}
					display: none;
				}

				.article_img {
					@include flexbox();
					@include flex-direction(column);
					width: 100%;

					@include respond-above(md) {
						padding-left: 3%;
					}

					img {
						display: block;
						height: auto;
						width: 100%;
						border-radius: 7px;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}
