.infographic {
    padding-bottom: 100px;
    @include respond-above(md) {
        padding-top: 25px;
    }
    .infographic__container {
        .infographic__container__content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            text-align: center;
            margin-bottom: 20px;

            @include respond-above(md) {
                padding: 10px 0;
                margin-bottom: 0;
            }

            .infographic__container__content__icon {
                width: 30px;
                @include respond-above(md) {
                    width: 40px;
                }
            }
            h4 {
                margin-bottom: 0;
                font-weight: 300;
                width: auto;
            }
            .infographic__container__content__infoicon {
                cursor: pointer;
            }
        }
        .infographic__container__line {
            border-right: 1px dashed black;
            width: 0;
            height: 40px;
            margin: 20px auto;
            @include respond-above(md) {
                height: 60px;
                margin: 20px auto;
            }
        }
        .infographic__container__line__bar {
            margin: 40px 0;
            .vertical-strip {
                border-right: 1px dashed black;
                width: 0;
                height: 40px;
                margin: 0 auto;
            }
            .rounded-box {
                width: 190px;
                height: 50px;
                border-right: 1px dashed black;
                border-left: 1px dashed black;
                border-top: 1px dashed black;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                margin: 0 auto;
                @include respond-above(md) {
                    width: 619px;
                }
            }
        }
        .infographic__container__satisfaction {
            display: flex;
            gap: 20px;
            margin-bottom: 48px;
            text-align: center;
            h4 {
                margin-bottom: 0;
                font-weight: 300;
            }

            @include respond-above(md) {
                justify-content: space-between;
                margin-bottom: 0;
            }
            
            .infographic__container__satisfaction_satisfied, .infographic__container__satisfaction_unsatisfied {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50%;
                gap: 10px;
                @include respond-above(md) {
                    flex-direction: row;
                    width: auto;
                }
                .infographic__container__satisfaction_satisfied__infoicon, .infographic__container__satisfaction_unsatisfied__infoicon {
                    cursor: pointer;
                }
            }
        }
        .infographic__container__curved {
            display: flex;
            flex-direction: column;
            width: 96px;
            margin: 0 80px 40px auto;  
            padding: 20px 0;
            @include respond-above(md) {
                width: 309px;
                padding: 40px 0;
                margin: 0 35px 0 auto;
            }
            @include respond-above(lg) {
                margin: 0 155px 0 auto;
            }
            @include respond-above(xl) {
                margin: 0 245px 0 auto;
            }
            .top-box, .bottom-box {
                height: 37px;
            }
            .top-box {
                align-self: flex-end;
                width: 76px;
                border-right: 1px dashed black;
                border-bottom: 1px dashed black;
                border-bottom-right-radius: 20px;
                @include respond-above(md) {
                    width: 289px;
                }
            }
            .bottom-box {
                border-top-left-radius: 20px;
                border-left: 1px dashed black;
            }
        }
    }
    .infographic__container__end {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        text-align: center;
        margin-bottom: 20px;

        h4 {
            margin-bottom: 0;
            width: auto;
            font-weight: 300;
        }

        .infographic__container__end__icon {
            width: 30px;
            @include respond-above(md) {
                width: 40px;
            }
        }

        .infographic__container__end__infoicon {
            cursor: pointer;
        }
    }

    .modal {
        .modal-dialog {
            background-color: white;
            margin: 30px;
            padding: 60px 30px;
            border-radius: 15px;
            @include respond-above(md) {
                margin: auto;
                top: 50%;
                transform: translateY(-50%);
                padding: 60px;
            }
            .modal-content {
                text-align: left;

                img {
                    &.modal-close {
                        top: -40px;
                        right: 8px;
                        @include respond-above(md) {
                            top: -30px;
                            right: -5px;
                        }
                    }
                }
                h4 {
                    margin-bottom: 20px;
                    font-weight: 600;   
                }
                p {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}