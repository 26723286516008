// https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9
// /*

$spaceamounts: (
	0,
	10,
	12,
	15,
	20,
	25,
	30,
	35,
	40,
	45,
	50,
	60,
	70,
	75,
	80,
	90,
	100,
	120,
	130,
	140,
	150,
	160,
	170,
	180,
	190,
	200,
	210,
	220,
	230,
	240,
	250,
	280,
	320,
	360
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right, all); // Leave this variable alone

@each $space in $spaceamounts {
	@each $side in $sides {
		@if $side == "all" {
			.m-#{$space} {
				margin: #{$space}px;
			}

			.p-#{$space} {
				padding: #{$space}px;
			}
		} @else {
			.m#{str-slice($side, 0, 1)}-#{$space} {
				margin-#{$side}: #{$space}px;
			}

			.p#{str-slice($side, 0, 1)}-#{$space} {
				padding-#{$side}: #{$space}px;
			}
		}
	}
}

@each $space in $spaceamounts {
	.m-#{$space} {
		margin: #{$space}px !important;
	}
	.mt-#{$space} {
		margin-top: #{$space}px;
	}
	.mr-#{$space} {
		margin-right: #{$space}px;
	}
	.mb-#{$space} {
		margin-bottom: #{$space}px;
	}
	.ml-#{$space} {
		margin-left: #{$space}px;
	}
	.mx-#{$space} {
		margin-right: #{$space}px;
		margin-left: #{$space}px;
	}
	.my-#{$space} {
		margin-top: #{$space}px;
		margin-bottom: #{$space}px;
	}
	.p-#{$space} {
		padding: #{$space}px !important;
	}
	.pt-#{$space} {
		padding-top: #{$space}px;
	}
	.pr-#{$space} {
		padding-right: #{$space}px;
	}
	.pb-#{$space} {
		padding-bottom: #{$space}px;
	}
	.pl-#{$space} {
		padding-left: #{$space}px;
	}
	.px-#{$space} {
		padding-right: #{$space}px;
		padding-left: #{$space}px;
	}
	.py-#{$space} {
		padding-top: #{$space}px;
		padding-bottom: #{$space}px;
	}
}

@each $br, $value in $breakpoints {
	@include respond-above($br) {
		@each $space in $spaceamounts {
			.m-#{$br}-#{$space} {
				margin: #{$space}px !important;
			}
			.mt-#{$br}-#{$space} {
				margin-top: #{$space}px;
			}
			.mr-#{$br}-#{$space} {
				margin-right: #{$space}px;
			}
			.mb-#{$br}-#{$space} {
				margin-bottom: #{$space}px;
			}
			.ml-#{$br}-#{$space} {
				margin-left: #{$space}px;
			}
			.mx-#{$br}-#{$space} {
				margin-right: #{$space}px;
				margin-left: #{$space}px;
			}
			.my-#{$br}-#{$space} {
				margin-top: #{$space}px;
				margin-bottom: #{$space}px;
			}

			.p-#{$br}-#{$space} {
				padding: #{$space}px !important;
			}
			.pt-#{$br}-#{$space} {
				padding-top: #{$space}px;
			}
			.pr-#{$br}-#{$space} {
				padding-right: #{$space}px;
			}
			.pb-#{$br}-#{$space} {
				padding-bottom: #{$space}px;
			}
			.pl-#{$br}-#{$space} {
				padding-left: #{$space}px;
			}
			.px-#{$br}-#{$space} {
				padding-right: #{$space}px;
				padding-left: #{$space}px;
			}
			.py-#{$br}-#{$space} {
				padding-top: #{$space}px;
				padding-bottom: #{$space}px;
			}
		}
	}
}
