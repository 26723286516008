.four_btn_cta {
	@include flexbox();
	@include flex-direction(column);
	position: relative;
	z-index: 2;

	.four_btn_cta__wrapper {
		.four_btn_cta__container {
			border-radius: $default-radius;
			background-color: $blue-light;
			padding: 30px;
			max-width: 968px;
			margin: 0 auto;

			@include respond-above(lg) {
				padding: 30px 55px;
			}

			.four_btn_cta__buttons {
				@include flexbox();
				@include flex-direction(column);

				@include respond-above(lg) {
					@include flex-direction(row);
					@include flex-wrap(nowrap);
				}

				.btn {
					margin: 0 10px 30px 0;

					@include respond-above(lg) {
						margin: 0 23px 10px 0;
					}
				}
			}

			.btn__secondary {
				padding-left: 0;
			}
		}
	}

	& + .image_text {
		position: relative;
		margin-top: -250px;
		z-index: 1;
		@include respond-above(md) {
			margin-top: -110px;
		}

		.image_text__wrapper {
			padding-top: 350px;
			@include respond-above(md) {
				padding-top: 200px;
			}
		}
	}
}
