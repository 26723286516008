.mvbSubOverview {
    @include gradient;
}

#mvb-sub-overview {
    .hero_video_cta {
        max-width: 1056px;
        padding-top: 70px;
        padding-bottom: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        @include respond-below(lg) {
            padding-top: 60px;
            padding-bottom: 20px;
        }

        &__description {
            @include respond-above(md) {
                font-size: 25px;
                line-height: 40px;
            }
        }
        &__container {
            padding-bottom: 70px;
        }
        &--mint {
            background-color: transparent;
        }

        &__image,
        &__video {
            transform: none;
            video {
                padding-bottom: 10px;
                @include respond-below(lg) {
                    padding-bottom: 20px;
                }
            }
            @include respond-below(lg) {
                order: 2;
                margin-bottom: 0;
            }
            &__title {
                @include respond-below(lg) {
                    margin: 0;
                }
            }
        }

        &__text {
            transform: none;
            @include respond-below(lg) {
                order: 1;
                padding-bottom: 20px;
            }
        }
    }
    h1 {
        font-family: "Poppins", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 600;
        font-style: normal;
    }

    .mvb_content {
        @include flexbox();
        @include flex-direction(column);
        width: 100%;

        .container-publications {
            background: radial-gradient(circle at top right, #b3effe 0%, #b2ffe9 50%, #b2ffe9 100%);
        }
        .image_text_cta {
            .image_text_cta__image {
                background-color: $softgreen;
            }
        }
    }
}
