.container__chart-pie {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    canvas {
        z-index: 1;
    }
}

.chart-row {
    justify-content: center;
}
.chart-col {
    margin-bottom: 40px;
}
.pie-inner {
    @include poppins(regular);
    font-size: 44px;
    @include respond-below(lg) {
        font-size: 34px;
    }
    @include respond-below(md) {
        font-size: 20px;
    }
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-weight: 300;
    @media only screen and (max-width: 340px) {
        font-size: 26px;
    }
    @media only screen and (max-width: 766px) {
        font-size: 35px;
    }
}
