//class that can be added to a div that contains a background image
@include respond-below(md) {
    .image-align-top-mobile {
        background-position: top !important;
    }

    .image-align-bottom-mobile {
        background-position: bottom !important;
    }

    .image-align-center-mobile {
        background-position: center !important;
    }

    .image-align-left-mobile {
        background-position: left !important;
    }

    .image-align-right-mobile {
        background-position: right !important;
    }
}

@include respond-above(md) {
    .image-align-top {
        background-position: top !important;
    }

    .image-align-bottom {
        background-position: bottom !important;
    }

    .image-align-center {
        background-position: center !important;
    }

    .image-align-left {
        background-position: left !important;
    }

    .image-align-right {
        background-position: right !important;
    }
}
.img-placeholder{
    background-color: $gray;
}
