.swatch {
	@include flex;
	@include flex-direction(column);
	width: 100%;
	padding: 10px;
}

.swatch__color {
	display: block;
	width: 50%;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
		border-radius: 100%;
		margin-bottom: 20px;
	}

	&--blue {
		&:after {
			background-color: $blue;
		}
	}
	&--blue-darker {
		&:after {
			background-color: $blue-darker;
		}
	}
	&--blue-middle {
		&:after {
			background-color: $blue-middle;
		}
	}
	&--blue-light {
		&:after {
			background-color: $blue-light;
		}
	}

	&--mint {
		&:after {
			background-color: $mint;
		}
	}
	&--yellow {
		&:after {
			background-color: $yellow;
		}
	}
	&--pastel-red {
		&:after {
			background-color: $pastel-red;
		}
	}
	&--error-red {
		&:after {
			background-color: $error-red;
		}
	}

	&--white {
		&:after {
			background-color: $white;
		}
	}
	&--grey-1 {
		&:after {
			background-color: $grey-1;
		}
	}
	&--grey-2 {
		&:after {
			background-color: $grey-2;
		}
	}
	&--grey-3 {
		&:after {
			background-color: $grey-3;
		}
	}

	&--gradient {
		&:after {
			@include gradient;
		}
	}
}

.swatch__text {
	margin: 0 0 0 0;
}

.gray_block {
	background-color: #d8d8d8;
}
