.faq-filter {
    .search--form--wrapper2{
        border-radius: 5px;
    }
    form {
        input {
            width: 100%;
            padding: 10px 25px;
            border: none;
            border-radius: 4px 0px 0px 4px;
            background-color: $white;
            @include poppins(regular);
        }
        .search-button,
        .reset-button {
            pointer-events: all;
            padding-right: 20px;
            padding-left: 20px;
            border-radius: 0px 4px 4px 0;
        }
    }

    .accordion{
        position: static !important;
        height: auto !important;
        padding-top: 20px;
        padding-bottom: 120px;
    }
    .subheading{
        margin-bottom: 0;
    }
    
    .card{
        width: 100%;
        position: static !important; /* Prevent absolute positioning */
        transform: none !important; /* Reset any transformations */
        top: auto !important; /* Reset top positioning */
        left: auto !important; /* Reset left positioning */
        transition-property: none !important;
        transition-duration: none !important;
        transition-delay: none !important;
    }
}

