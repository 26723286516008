.info_button_modal, .letop_modal {
    .modal-dialog {
        margin: 0.5rem 45px;
        @include respond-above(md) {
            margin: 0.5rem auto;
            max-width: 734px;
        }
        @include respond-above(lg) {
            max-width: 780px;
        }
        .modal-content {
            .img {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                border-radius: 15px 15px 0 0;
                height: 218px;
                position: relative;
                z-index: 1;
                @include respond-above(md) {
                    border-radius: 0;
                    height: 100%;
                }
            }
            .copy {
                padding: 30px;
                border-radius: 0 0 15px 15px;
                box-shadow: 0 5px 25px rgba(0, 0, 0, 0.50);
                @include respond-above(md) {
                    border-radius: 0;
                    padding: 70px 95px 70px 40px;
                    box-shadow: none;
                }

                img {
                    &.modal-close {
                        right: 30px;
                        @include respond-above(md) {
                            top: 40px;
                            right:55px;
                        }
                    }
                }

                h3 {
                    padding-right: 50px;
                    margin-bottom: 20px;
                    font-weight: 300;
                }
                p {
                    padding-right: 20px;
                    font-size: 16px;
                    line-height: 26px;
                    font-family: $freighttext;
                    @include respond-above(md) {
                        font-size: 19px;
                        line-height: 30px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            @include respond-above(md) {
                border-radius: 15px;
                overflow: hidden;
                box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.50)
            }
        }
    }
}

.info_button_modal {
    .modal-dialog {
        .modal-content {
            .img {
                background-image: url("/assets/img/image.png");
                background-color: $gray-1;
            }
            .copy {
                background-color: $grey-2;
            }
        }
    }
}

.letop_modal {
    .modal-dialog {
        .modal-content {
            .img {
                background-image: url("/assets/img/letop_image.png");
                background-color: $navaro-white;
            }
            .copy {
                background-color: $yellow;
            }
        }
    }
}