.card-category-wrapper {
	color: inherit;

	&:after {
		content: "";
		right: 20px;
		bottom: 40px;
		position: absolute;
		display: inline-block;
		height: 20px;
		width: 20px;
		vertical-align: middle;
		background-image: url(/assets/img/icons/arrow_sprite.svg);
		background-position: center right;
		background-repeat: no-repeat;
		background-size: 45px;
		transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
		margin-left: 20px;

		@include respond-above(md) {
			width: 30px;
			bottom: 10px;
			background-size: 55px;
			background-position-x: -24px;
			margin-left: 10px;
		}
	}

	&:hover {
		text-decoration: none;
		color: inherit;

		&:after {
			background-position-x: -0px;
			
			@include respond-above(lg) {
				background-position-x: 5px;
			}
		}
	}

	.card-category {
		border: 2px solid $black;
		border-radius: $default-radius;
		overflow: hidden;
		background-color: $grey-1;
		margin-bottom: 30px;
		@include respond-above(md) {
			margin-bottom: 0;
		}
		&__body {
			padding: 20px;

			h5 {
				margin-bottom: 8px;
				word-wrap: break-word;
				line-height: 28px;
			}
			p {
				margin-bottom: 30px;
			}
			a {
				padding-left: 0;
			}
		}
	}
}
