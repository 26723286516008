.mvb {
    @include gradient_radial;
    .left_image_text {
        &__container {
            .left_image_text__wrapper {
                .left_image_text__image {
                    @include respond-below(md) {
                        background-position: top center;
                    }

                    min-height: 200px;

                    @include respond-above(sm) {
                        min-height: 280px;
                    }

                    @include respond-above(md) {
                        min-height: 250px;
                        max-width: 220px;
                    }
                }
            }
        }
    }

    .mvb_content {
        overflow-x: hidden;
    }

    #panel1 {
        p:last-child {
            margin-bottom: 0;
        }
        img {
            @include respond-above(md) {
                transform: translateY(20%);
            }
            @include respond-above(lg) {
                transform: translateX(20%), translateY(20%);
            }
        }
    }
    #panel3 {
        img {
            @include respond-above(md) {
                max-width: 80%;
                transform: translateY(-60%);
                position: absolute;
            }
        }
    }
    #panel4 {
        @include respond-above(md) {
            .side-link {
                margin-top: 46px;
            }
        }
    }
    #panel5 {
        img {
            @include respond-above(md) {
                transform: translateY(30%);
            }
        }
    }

    .mvb-links {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        li {
            padding-top: 5px;
            padding-bottom: 5px;
            @include respond-above(md) {
                padding-top: 0;
                padding-bottom: 0;
            }
            a {
                padding: 4px;
                padding-left: 0;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }

    .grid_block {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(equal-spacing);
        @include flex-wrap(wrap);
        margin: 0;
        padding: 0;
        list-style-type: none;
        border: 2px solid $black;
        border-radius: $default-radius;
        overflow: hidden;
        @include respond-above(md) {
            border: 3px solid $black;
        }

        li {
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(center);
            @include align-items(center);
            width: 50%;
            padding-top: 5px;
            padding-bottom: 5px;
            @include respond-above(md) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &.red {
                background-color: #a21942;
            }
            &.mustard {
                background-color: #cc8c2b;
            }
            &.olive {
                background-color: #49763d;
            }
            &.navy {
                background-color: #005489;
            }

            img {
                display: block;
                width: 100%;
                max-width: 105px;
                @include respond-above(md) {
                    max-width: 185px;
                }
            }
        }
    }

    .block-border {
        background-color: $softgreen;
        border-radius: 15px;
        padding: 30px;

        @include respond-above(md) {
            padding: 60px;
        }
    }
    .side-link-container {
        padding-right: 0;
        @include respond-above(md) {
            padding-right: 15px;
        }
    }
    footer {
        header {
            display: none;
        }
    }
}

a#scroll--mvb--content {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    height: 50px;
    width: 80px;
    right: 20px;
    border-radius: 15px 15px 0 0;
    background-color: #f4eeeea3;
    box-shadow: 0 0 20px $grey-3;
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(10px);

    @include respond-below(lg) {
        display: none !important;
    }

    &:hover {
        transform: translateY(0);
    }

    &:focus {
        box-shadow: 0 0 20px $grey-3;
    }

    &::after {
        content: "";
        display: block;
        position: relative;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -7px;
        border-left: solid 2px black;
        border-top: solid 2px black;
        height: 20px;
        width: 20px;
        transform: rotate(225deg);
    }
}

.mvb_content {
    &__header {
        max-width: 620px;
        text-align: center;
        display: flex;
        margin: auto;
        margin-bottom: 90px;
        @include respond-below(lg) {
            margin-bottom: 60px;
        }

        h2 {
            padding-bottom: 32px;
            border-bottom: 1px dashed $blue;
            margin-bottom: 0;
        }
    }
    @include flexbox();
    @include flex-direction(column);
    width: 100%;
}

// section.info_block.info_block--stat div.info_block__wrapper div.info_block__container div.stat_block

#mvb {
    h1 {
        font-family: "Poppins", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 600;
        font-style: normal;
    }

    .hero_video_cta {
        padding-bottom: 30px;
        &__container {
            padding-bottom: 0;
            padding-top: 40px;
            @include respond-below(md) {
                padding-top: 0;
            }
        }
        &--mint {
            background-color: transparent;
        }
    }
    .hero_video_cta__description {
        margin-bottom: 12px;
        @include respond-below(lg) {
            margin-bottom: 10px;
        }
    }
    .hero_video_cta__text {
        .btn {
            margin-bottom: 0;
        }
    }
    .mvb_link_block {
        margin-bottom: 120px;
        @include respond-below(lg) {
            margin-bottom: 60px;
        }
    }
    .text_block {
        padding-top: 0;
    }
}
