// _latest_news.scss
.latest_news {
	margin-bottom: 120px;

	.latest_news__container {
		@include flexbox();
		@include flex-direction(column);
		@include align-items(center);
		justify-content: center;

		.latest_news__header {
			@include flexbox();
			@include flex-direction(column);
			margin: 0 auto;
			padding: 0 15px;

			@include respond-above(sm) {
				max-width: 620px;
				margin-bottom: 36px;
			}

			.latest_news__title {
				font-size: 48px;
				line-height: 72px;
				padding-bottom: 32px;
				margin-bottom: 32px;
				border-bottom: 1px dashed $gray;
				@include respond-above(lg) {
					font-size:60px;
					padding-bottom:14px;
					margin-bottom: 21px;
				}
			}

			p {
				text-align: center;
				margin-bottom: 32px;

				@include respond-above(md) {
					padding: 0 30px;
				}
			}
		}

		.latest_news__title {
			text-align: center;
		}

		.latest_news__articles {
			@include flexbox();
			@include flex-direction(column);
			width: 100%;
			max-width: 970px;
			margin: 0 auto;

			.latest_news__list {
				margin: 0;
				padding: 0;
				list-style-type: none;

				@include respond-above(lg) {
					@include flex-direction(row);
				}

				.latest_news__year {
					margin-top: 60px;
					margin-bottom: 30px;

					@include respond-above(sm) {
						margin-top: 90px;
					}
				}
			}
		}

		.news_item {
			@include flexbox();
			@include flex-direction(column);
			margin: 0;
			cursor: pointer;
			margin-bottom: 20px;
			transform: translateY(0px);
			transition: transform 0.3s $exponential;

			@include respond-above(sm) {
				&:nth-child(3n + 3) {
					margin-bottom: 60px;
				}
			}

			&:hover {
				transform: translateY(-4px);
				.news_item__link {
					.news_item__header {
						&:after {
							background-position-x: -0px;
							@include respond-above(lg) {
								background-position-x: 10px;
							}
						}
					}
					
				}
			}

			.news_item__link {
				@include flexbox();
				@include flex-direction(row);
				width: 100%;
				text-decoration: none;

				@include respond-above(lg) {
					@include flex-direction(column);
				}

				.news_item__header {
					background-color: $blue-light-two;
					border-radius: 15px 0 0 15px;
					padding: 10px 25px;
					width: 70%;
					position: relative;

					.art__body--date {
						font-size: 16px;
					}

					h5 {
						font-size: 18px;
						line-height: 24px;
						font-weight: 400;

						@include respond-above(sm) {
							font-size: 21px;
							line-height: 30px;
							font-weight: 400;
						}
					}

					@include respond-above(lg) {
						border-radius: 15px 15px 0 0;
						width: 100%;
						min-height: 275px;
					}
					@include respond-above(lg) {
						border-radius: 15px 15px 0 0;
						width: 100%;
						min-height: 215px;
					}

					&:after {
						content: "";
						right: 20px;
						bottom: 10px;
						position: absolute;
						display: inline-block;
						height: 20px;
						width: 20px;
						vertical-align: middle;
						background-image: url(/assets/img/icons/arrow_sprite.svg);
						background-position: center right;
						background-repeat: no-repeat;
						background-size: 45px;
						transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
						margin-left: 20px;

						@include respond-above(md) {
							width: 40px;
							background-size: 70px;
							background-position-x: -30px;
							margin-left: 10px;
						}
					}

					h5 {
						color: $black;
						text-decoration: none;
					}
				}

				.news_item__image {
					width: 30%;
					min-height: 217px;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
					background-color: $grey-1;
					border-radius: 0 15px 15px 0;
					transition: background-size 0.3s $exponential;
					@include respond-above(sm) {
						height: 180px;
						min-width: 120px;
						min-height: 180px;
					}

					@include respond-above(lg) {
						border-radius: 0px 0px 15px 15px;
						width: 100%;
					}
				}
			}
		}
	}

	.btn {
		@include align-self(center);
		margin-top: 30px;
		@include respond-above(sm) {
			margin-top: 0;
		}
	}
}
