//footer
footer.footer {
	position: relative;

	.footer__cta {
		max-width: 940px;
		@include flexbox();
		@include flex-direction(column);
		width: 100%;
		@include respond-above(lg) {
			padding: 0;
		}

		.footer__cta_wrapper {
			background-color: $grey-2;
			border-radius: 15px;
			width: 100%;
			margin-bottom: -220px;
			z-index: 1;

			.footer__cta_headline {
				margin: 20px 0;

				@include respond-above(lg) {
					margin-left: 0;
					margin-right: 0;
					margin-top: 40px;
					margin-bottom: 26px;
				}
			}

			.footer__cta_buttons {
				@include flexbox();
				@include flex-direction(column);
				@media (min-width: 990px) {
					@include flex-direction(row);
				}

				.btn {
					margin-bottom: 20px;
					margin-right: 20px;

					@include respond-above(md) {
						margin-right: 20px;
					}
				}
			}
		}
	}

	.footer__links {
		background: linear-gradient(270deg, $blue-middle 0%, $blue-darker 100%);
		border-radius: 15px 15px 0 0;
		padding-top: 300px;
		padding-bottom: 100px;

		.footer__links_wrapper {
			max-width: 940px;
			@include flexbox();
			@include flex-direction(column);
			@include align-items(center);
			width: 100%;

			@include respond-above(lg) {
				@include flex-direction(row);
				padding: 0;
			}

			.footer__links_list {
				@include flexbox();
				@include flex-direction(column);
				margin: 0 0 50px 0;
				width: 100%;

				@include respond-above(lg) {
					@include flex-direction(row);
					margin-bottom: 0;
				}

				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					@include flexbox();
					@include flex-direction(column);
					@include flex-wrap(wrap);
					@include justify-content(space-between);
					@include align-items(center);

					@include respond-above(sm) {
						@include flex-direction(row);
					}

					@include respond-above(lg) {
						flex-grow: 1;
						li {
							&:first-of-type {
								a {
									padding-left: 0;
								}
							}
							&:last-of-type {
								a {
									padding-right: 0;
								}
							}
						}
					}

					.footer__link:first-child a {
						&::after {
							content: none;
						}

						&:hover {
							text-decoration: none;
						}
					}

					.footer__link:not(:last-child) {
						a {
							margin-bottom: 15px;
							@include respond-above(sm) {
								margin-bottom: 0;
							}
						}
					}

					.footer__link a {
						color: $white;
						background-color: transparent;

						&::after {
							background: url("/assets/img/icons/arrow_sprite_white.svg") center right no-repeat;
							color: $white;
							content: "";
							display: inline-block;
							height: 20px;
							width: 30px;
							vertical-align: middle;
							background-size: 49px;
							background-position-x: -20px;
							transition: background-position cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
							margin-left: 10px;
						}

						&:hover {
							text-decoration: underline;
							&:after {
								background-position-x: 10px;
							}
						}
					}
				}
			}

			.footer__links_copyright {
				color: $white;
				font-size: 13px;
				line-height: 23px;
				text-align: center;
				max-width: 270px;
				margin: 0 auto;

				@include respond-above(lg) {
					text-align: right;
					max-width: 100%;
					width: 100%;
					margin-top: 5px;
				}
			}
		}
	}
	.footer__image {
		background: url(/assets/img/Illustration__8_2x.png), $gray-1;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		border-radius: 15px 15px 0 0;
		height: 290px;
		@include respond-above(md) {
			border-radius: 0 15px 15px 0;
			height: 100%;
		}
	}
}
